import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React, { useState } from 'react';

import ILCPExternal from './ILCPExternal/ILCPExternal';
import ILCPInternal from './ILCPInternal/ILCPInternal';

import './ILCPSection.scss';

function getActiveItems(hostname) {
  if (hostname.match(/bapco./)) {
    return ['external'];
  }
  if (hostname.match(/bluetide./)) {
    return [];
  }
  if (hostname.match(/buckeye./)) {
    return [];
  }
  if (hostname.match(/colpipe./)) {
    return ['external'];
  }
  if (hostname.match(/ctl./)) {
    return [];
  }
  if (hostname.match(/cvr./)) {
    return ['external'];
  }
  if (hostname.match(/demo./)) {
    return ['external'];
  }
  if (hostname.match(/eurofins./)) {
    return [];
  }
  if (hostname.match(/(ser|ies|propet|lupton)./)) {
    return ['external'];
  }
  if (hostname.match(/ggpetro./)) {
    return [];
  }
  if (hostname.match(/hep./) || hostname.match(/hfsm./)) {
    return [];
  }
  if (hostname.match(/hunt./)) {
    return [];
  }
  if (hostname.match(/mmp./)) {
    return ['external', 'internal'];
  }
  if (hostname.match(/dev./)) {
    return ['external', 'internal'];
  }
  if (hostname.match(/fuelsqc.com/)) {
    return [];
  }
  if (hostname.match(/uat./)) {
    return ['external', 'internal'];
  }
  if (hostname.match(/bplubricants./)) {
    return [];
  }
  return ['external', 'internal'];
}

const ILCPSection = () => {
  const activeItems = getActiveItems(window.location.hostname);
  const [value, setValue] = useState(activeItems[0] ?? '');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const components = {
    external: ILCPExternal(),
    internal: ILCPInternal()
  };

  const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start'
      }}
    >
      <label className="title">ILCP</label>

      <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" centered>
        {activeItems.map((item) => (
          <Tab key={`tab-${item}`} label={capitalize(item)} value={item} />
        ))}
      </Tabs>
      {components[value]}
    </div>
  );
};

export default ILCPSection;
