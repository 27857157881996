import React, { useContext, useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { formatInTimeZone } from 'date-fns-tz';
import { uniq } from 'lodash';
import Alert from 'react-bootstrap/Alert';
import ToggleButton from 'react-bootstrap/ToggleButton';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import createTrend from 'trendline';
import moment from 'moment-timezone';
import { Tab, Tabs } from 'react-bootstrap';
import { getFunctions, httpsCallable } from 'firebase/functions';
import DatePicker from 'react-datepicker';
import Tooltip from '@mui/material/Tooltip';
import { getActiveItems, getConfiguration } from '../util/config';

import {
  defaultColumns,
  defaultColumnsExtend,
  defaultColumnsUsExtend,
  defaultColumnsEdit,
  defaultColumnsReport,
  oocFlagColumns,
  summaryStatisticsColumnsPeriodic,
  wFlagColumns,
  yesNoOptions,
  defaultExportDataToCSVColumns
} from '../components/Columns';
import './chart.scss';
import firebaseApp, { currentLogo, getCurrentFeatureFlags } from '../util/firebase';
import { requireAuth, useAuth } from '../util/auth';
import {
  isAdmin,
  arrayAverage,
  calculateHistogram,
  randomDarkColor,
  textSort,
  checkReadOnly,
  isContributor,
  checkCreate,
  renderButtonWithPermissions,
  setDefaultControlStrategies,
  generateControlStrategyData,
  renderLoadingButton,
  firebaseClean,
  checkHasPermission,
  getSigDigitsUtil,
  getTimeZone,
  sanitizePropertyData
} from '../util/util';
import {
  createAssessment,
  createConfiguration,
  createSampleSchedule,
  updateConfiguration,
  updateData,
  updateSummary,
  updateSampleSchedule,
  deleteSampleSchedule,
  useEntitiesByFullKey,
  useEntitiesByShortKey,
  useFindAssessmentsByShortKey,
  useFindChartGroupConfigurationByShortKey,
  useFindControlStrategyConfigurationByFullKey,
  useFindDataByMultipleParameters,
  useGetIndexValues,
  useGetMethods,
  useGetPersonnel,
  useGetResults,
  useGetSampleSchedules,
  useGetSamples,
  useGetSetupGroups,
  useGetSummaries,
  readCombineZScoreParameter,
  createCombineZScoreParameter,
  updateCombineZScoreParameter,
  useGetOrderOfParameters,
  useGetCompanyType,
  resetEverFlags
} from '../util/db';
import Section from '../components/Section';
import SQCLineChart from '../components/SQCLineChart';
import SQCMovingRangeChart from '../components/SQCMovingRangeChart';
import SQCScatterPlot from '../components/SQCScatterPlot';
import SQCHistogram from '../components/SQCHistogram';
import SQCAnalystChart from '../components/SQCAnalystChart';
import SQCInstrumentsChart from '../components/SQCInstrumentsChart';
import { useHistory, useRouter } from '../util/router';
import PrimaryButton from '../components/PrimaryButton/PrimaryButton';
import DataEntrySection from '../components/DataEntrySection/DataEntrySection';
import AssessmentsSection from '../components/AssessmentsSection/AssessmentsSection';
import ReportsSection from '../components/ReportsSection/ReportsSection';
import ChartContext from '../components/DataEntrySection/DataEntryProvider';

import 'react-datepicker/dist/react-datepicker.css';
import CustomTable from '../components/Table/CustomTable';
import PrintOverviewDataTable from '../components/Table/PrintOverviewDataTable';
import { randomId } from '@mui/x-data-grid-generator';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { GridActionsCellItem } from '@mui/x-data-grid';
import AppContext from '../components/AppContext';
import { Button as MuiButton, ButtonGroup, Checkbox, TextField } from '@mui/material';
import {
  SCREEN,
  RULES,
  UPDATE_TYPE,
  archiveRegex,
  FORMAT_KEY_TIME,
  FORMAT_DATE_YYYYMMDD
} from '../util/Constant';
import ModalConfirm from '../components/Common/modal/ModalConfirm';
import ModalError from '../components/Common/modal/ModalError';
import ChartAssessment from '../components/Modal/ChartAssessment';
import Loading from '../components/Common/Loading';
import ReportIssue from '../components/ReportIssueSection/ReportIssue';

const functions = getFunctions(firebaseApp);
const addDataFunction = httpsCallable(functions, 'addData');
const deleteAllDataFunction = httpsCallable(functions, 'deleteAllData');
const runReBuildIndexFunctions = httpsCallable(functions, 'reBuildIndex');
const runDataFunction = httpsCallable(functions, 'runData');
const editDataFunction = httpsCallable(functions, 'editData');
const archiveDataFunction = httpsCallable(functions, 'archiveData');
const periodicAssessmentArchiveDataFunction = httpsCallable(
  functions,
  'periodicAssessmentArchiveData'
);
const runArchiveDataFunction = httpsCallable(functions, 'runArchiveData');
const updateTenNinetyDatesFunction = httpsCallable(functions, 'updateTenNinetyDates');
const runCombineZScore = httpsCallable(functions, 'combineZScore');
const runUnArchiveFunctions = httpsCallable(functions, 'unArchiveFunctions');
const activeItems = getActiveItems(window.location.hostname);

const pageSizeDefault = 10;

const radioDataType = {
  ACTIVE: 'Active',
  ARCHIVE: 'Archive'
};

const defaultChartGroupConfigurations = {
  assignedSamples: ['None', 'None', 'None'],
  autoCreateSampleRecheck: 'No',
  displayMethodVersion: 'No',
  fullKey: '',
  initialAssessment: '20',
  ongoingAssessment: '20',
  ongoingAssessmentDays: '0',
  sampleType: 'SQC',
  shortKey: '',
  type: 'Chart Group',
  ewmaLambda: '0.4',
  tAlpha: '0.05',
  fAlpha: '0.05'
};

function ChartPage() {
  const auth = useAuth();
  const router = useRouter();
  const [periodicPage, setPeriodicPage] = useState('1');
  const [currentShortKey, setCurrentShortKey] = useState(null);
  const { currentFullKey, setCurrentFullKey } = useContext(AppContext);
  const { data: items } = useEntitiesByFullKey(currentFullKey);
  const { data: shortKeyItems } = useEntitiesByShortKey(currentShortKey);
  let { data: assessments } = useFindAssessmentsByShortKey(currentShortKey);
  const [resultsDataTableData, setResultsDataTableData] = useState([]);
  const { data: summaries } = useGetSummaries();
  const { data: orderOfParameters } = useGetOrderOfParameters();
  const [currentResultsData, setCurrentResultsData] = useState([]);
  const [currentPersonnelNames, setCurrentPersonnelNames] = useState([]);
  const [currentPersonnelDots, setCurrentPersonnelDots] = useState([]);
  const [currentInstrumentNames, setCurrentInstrumentNames] = useState([]);
  const [currentInstrumentDots, setCurrentInstrumentDots] = useState([]);
  const [activeTab, setActiveTab] = useState('Overview');
  let { data: results } = useGetResults();
  const { data: personnel } = useGetPersonnel();
  const [dataEntrySectionData] = useState([]);
  const [allLocations, setAllLocations] = useState([]);
  const [allMethods] = useState([]);
  const [allSampleNames] = useState([]);
  const [allInstruments] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [currentSummaries, setCurrentSummaries] = useState([]);
  const [currentSummariesShortKey, setCurrentSummariesShortKey] = useState([]);
  const [currentSummary, setCurrentSummary] = useState(null);
  const [currentSetupDataOne, setCurrentSetupDataOne] = useState(0);
  const [currentSetupDataZero, setCurrentSetupDataZero] = useState(0);
  const [currentPersonnel, setCurrentPersonnel] = useState([]);
  const [permission, setPermission] = useState('');
  const [currentSetupGroups, setCurrentSetupGroups] = useState([]);
  const { data: setupGroups } = useGetSetupGroups();
  const [startDate] = useState(new Date());
  const [currentLocation, setCurrentLocation] = useState(null);
  const [currentLocations, setCurrentLocations] = useState([]);
  const [showPeriodicModal, setShowPeriodicModal] = useState(false);
  const [currentMethod, setCurrentMethod] = useState(null);
  const [currentMethods, setCurrentMethods] = useState([]);
  const [currentSampleNames, setCurrentSampleNames] = useState([]);
  const [currentInstruments, setCurrentInstruments] = useState([]);
  const [currentParametersDynamic, setCurrentParametersDynamic] = useState([]);
  const [currentIndexes, setCurrentIndexes] = useState([]);
  const [currentActiveIndexes, setCurrentActiveIndexes] = useState([]);
  const [currentArchiveIndexes, setCurrentArchiveIndexes] = useState([]);
  const [currentSampleName, setCurrentSampleName] = useState(null);
  const [currentInstrument, setCurrentInstrument] = useState(null);
  const [currentParameter, setCurrentParameter] = useState(null);
  const [instrumentBeforeSampleName, setInstrumentBeforeSampleName] = useState(false);
  const [currentParameterComparison, setCurrentParameterComparison] = useState(currentParameter);
  const [currentMethodComparison, setCurrentMethodComparison] = useState(currentMethod);
  const [currentSampleNameComparison, setCurrentSampleNameComparison] = useState(currentSampleName);
  // eslint-disable-next-line no-unused-vars
  const [chartHitCount, setChartHitCount] = useState(0);
  const [chartHits, setChartHits] = useState([]);
  const [chartData, setChartData] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [instrumentChartData, setInstrumentChartData] = useState([]);
  const [chartScalars, setChartScalars] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showScheduleModal, setShowScheduleModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [currentParameters, setCurrentParameters] = useState([]);
  const [filteredChartHits, setFilteredChartHits] = useState(false);
  const [filteredShortKeyData, setFilteredShortKeyData] = useState([]);
  const [timezones] = useState(moment.tz.names());
  const [currentNValue, setCurrentNValue] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [chartAssessed, setChartAssessed] = useState(false);
  const [currentAssessments, setCurrentAssessments] = useState([]);
  const [chartStage, setChartStage] = useState('');
  const [chartButtonText, setChartButtonText] = useState('');
  const [modalAssessment, setModalAssessment] = useState({});
  const [rowsToUpdate, setRowsToUpdate] = useState([]);
  const [localSearchFinished, setLocalSearchFinished] = useState(false);
  const [chartDataComplete, setChartDataComplete] = useState(true);
  const { data: methods } = useGetMethods();
  const { data: instrumentComparisonData } = useFindDataByMultipleParameters(
    currentMethodComparison,
    currentSampleNameComparison,
    currentParameterComparison
  );
  // eslint-disable-next-line no-unused-vars
  const [currentMethodsDropdown, setCurrentMethodsDropdown] = useState([
    { value: '', label: 'Select method' }
  ]);
  // eslint-disable-next-line no-unused-vars
  const [methodsUpdated, setMethodsUpdated] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [currentParametersDropdown, setCurrentParametersDropdown] = useState([]);
  const [summaryStatisticsAssessmentData, setSummaryStatisticsAssessmentData] = useState([]);
  const [tenNinetyStartDate, setTenNinetyStartDate] = useState(
    currentSummary ? new Date(currentSummary.v_1090_dateMin) : ''
  );
  const [tenNinetyEndDate, setTenNinetyEndDate] = useState(
    currentSummary ? new Date(currentSummary.v_1090_dateMax) : ''
  );

  const [currentSamples, setCurrentSamples] = useState([{ value: 'None', label: 'None' }]);
  const { data: samples } = useGetSamples();
  const [indexes, setIndexes] = useState([]);
  const { data: chartGroupConfigurations } =
    useFindChartGroupConfigurationByShortKey(currentShortKey);
  const { data: controlStrategyConfigurations } =
    useFindControlStrategyConfigurationByFullKey(currentFullKey);
  const { data: sampleSchedules } = useGetSampleSchedules();
  const { data: companyTypes } = useGetCompanyType();
  const [currentChartGroupConfigurations, setCurrentChartGroupConfigurations] = useState(
    defaultChartGroupConfigurations
  );
  const [currentPersonnelDropdown, setCurrentPersonnelDropdown] = useState([]);
  const [unit, setUnit] = useState('');
  const [permissionData, setPermissionData] = useState({});

  const [isSelectCombinedZScore, setIsSelectCombinedZScore] = useState(false);
  const [showCombinedZScoreModal, setShowCombinedZScoreModal] = useState(false);
  const [combinedZScoreData, setCombinedZScoreData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingRunData, setLoadingRunData] = useState(false);
  const [loadingSaveData, setLoadingSaveData] = useState(false);
  const [loadingReIndexData, setLoadingReIndexData] = useState(false);
  const [configCombineZScore, setConfigCombineZScore] = useState(null);
  const [show, setShow] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorData, setErrorData] = useState({});
  const [chartLoading, setChartLoading] = useState(false);
  const history = useHistory();
  const [radioArchiveValue, setRadioArchiveValue] = useState('Active');
  const [archiveData, setArchiveData] = useState([]);
  const [controlStatusData, setControlStatusData] = useState([]);
  const defaultCombinedParameterName = 'Combined Z-score';
  const defaultControlStrategyConfigurations = {
    andersonDarling: 'Off',
    archive: 'No',
    chartSigDigits: 3,
    controlLimitExceedance: 'Off',
    controlLimitViolation: 'Out of Control',
    controlStatus: 'Yes',
    controlStrategy: 'EWMA',
    duiChart: 'Yes',
    ewmaTrend: 'Out of Control',
    fiveConsecutive: 'Off',
    fivePointViolation: 'Out of Control',
    fullKey: '',
    lowerControlLimit: 'N/A',
    lowerControlLimitApproach: 'Default',
    lowerToleranceLimit: 'N/A',
    lowerToleranceLimitApproach: 'Default',
    mean: 'N/A',
    n: '',
    standardDeviation: 'N/A',
    nineConsecutive: 'Out of Control',
    pretreatData: 'No',
    repeatability: 'Default',
    reproducibility: 'Default',
    sevenConsecutive: 'Off',
    shortKey: '',
    singlePointViolation: 'Warning',
    suppressNormality: 'No',
    suppressTPI: 'No',
    tenNinetyMaterial: 'N/A',
    toleranceLimitExceedance: 'Off',
    tpiOnePointTwo: 'Off',
    twoOfThree: 'Off',
    type: 'Control Strategy',
    upperControlLimit: 'N/A',
    upperControlLimitApproach: 'Default',
    upperToleranceLimit: 'N/A',
    upperToleranceLimitApproach: 'Default',
    warningLimitViolation: 'Off',
    ewmaLambda: '0.4',
    tAlpha: '0.05',
    fAlpha: '0.05'
  };

  const [currentInstrumentComparisonData, setCurrentInstrumentComparisonData] = useState([]);
  const [defaultSampleId, setDefaultSampleId] = useState(' ');

  const mapInstrumentDataToChart = (updatedInstrumentData) => {
    let instrumentNames = [];
    const instrumentData = [];
    // in data forEach loop
    updatedInstrumentData.forEach((data) => {
      instrumentNames.push(data.instrument);
      instrumentData.push(data);
    });
    instrumentNames = uniq(instrumentNames);
    const instrumentsDots = [];
    const updatedInstrumentNames = [];
    instrumentNames.forEach((name) => {
      const randomColor = randomDarkColor();
      const objectToAdd = {
        name,
        color: randomColor
      };
      instrumentsDots.push(objectToAdd);
      const objectToReturn = {
        name,
        checked: true,
        color: randomColor
      };
      updatedInstrumentNames.push(objectToReturn);
    });
    setCurrentInstrumentNames(updatedInstrumentNames);
    setCurrentInstrumentDots(instrumentsDots);
    setInstrumentChartData(instrumentData);
  };

  useEffect(() => {
    if (Array.isArray(instrumentComparisonData) && instrumentComparisonData.length > 0) {
      mapInstrumentDataToChart(instrumentComparisonData);
      const instrumentSortByDate = instrumentComparisonData.sort(function (A, B) {
        const d1 = new Date(A.dateTime).getTime();
        const d2 = new Date(B.dateTime).getTime();
        if (d1 < d2) {
          return -1;
        }
        if (d1 > d2) {
          return 1;
        }
        return 0;
      });
      setCurrentInstrumentComparisonData(instrumentSortByDate);
    }
  }, [instrumentComparisonData]);

  const [currentControlStrategyConfigurations, setCurrentControlStrategyConfigurations] = useState(
    defaultControlStrategyConfigurations
  );
  const [featureFlags] = useState(getCurrentFeatureFlags);
  const [timeFormat, setTimeFormat] = useState('h:mm A');
  const [dateTimeFormat, setDateTimeFormat] = useState('MM/DD/YYYY hh:mm A');
  const [tz] = useState(getTimeZone(featureFlags));

  const [archiveStatus, setArchiveStatus] = useState('');

  useEffect(() => {
    if (radioArchiveValue === 'Active' && activeTab && archiveStatus !== 'No') {
      setArchiveStatus('No');
    }
  }, [radioArchiveValue, activeTab]);

  useEffect(() => {
    const config = getConfiguration(window.location.hostname);
    if (isNonUs || config.timeIn24HourFormat) {
      setDateTimeFormat('MM/DD/YYYY HH:mm');
      setTimeFormat('HH:mm');
    } else {
      setDateTimeFormat('MM/DD/YYYY hh:mm A');
      setTimeFormat('h:mm A');
    }
  }, [companyTypes]);

  const renderChartLabelValue = (dataSet, property) => {
    const propertyExists = dataSet.some((data) => Object.hasOwn(data, property));
    let labelToReturn = ``;
    if (propertyExists) {
      const currentProperty = dataSet[dataSet.length - 1][property];
      if (typeof currentProperty === 'number') {
        labelToReturn = `${renderSigDigits(dataSet[dataSet.length - 1][property])}`;
      }
    }
    return labelToReturn;
  };

  const handleTenNinetyDateChange = (type, value) => {
    if (type === 'start') {
      setTenNinetyStartDate(value);
    } else {
      setTenNinetyEndDate(value);
    }
  };

  const defaultChartLines = [
    {
      yAxis: 'resultOmits',
      name: 'Data',
      color: '#32CD32',
      ewma: true,
      runRules: true,
      controlLimits: true,
      toleranceLimits: true
    },
    {
      yAxis: 'ewmaOmits',
      name: 'Trend',
      color: '#B0E0E6',
      ewma: true,
      runRules: false,
      controlLimits: false,
      toleranceLimits: false
    },
    {
      yAxis: 'mean',
      name: 'Average',
      reference: chartData.length > 20,
      label: ``,
      color: '#FFBF00',
      ewma: true,
      runRules: true,
      controlLimits: true,
      toleranceLimits: true
    },
    {
      yAxis: 'sd15p',
      name: 'EWMA UCL',
      label: ``,
      color: '#9932CC',
      reference: true,
      ewma: true,
      runRules: false,
      controlLimits: false,
      toleranceLimits: false
    },
    {
      yAxis: 'sd15n',
      name: 'EWMA LCL',
      label: ``,
      color: '#9932CC',
      reference: true,
      ewma: true,
      runRules: false,
      controlLimits: false,
      toleranceLimits: false
    },
    {
      yAxis: 'sd3n',
      name: 'LCL',
      label: ``,
      color: '#FBCEB1',
      reference: true,
      ewma: true,
      runRules: true,
      controlLimits: false,
      toleranceLimits: false
    },
    {
      yAxis: 'sd3p',
      name: 'UCL',
      label: ``,
      color: '#FBCEB1',
      reference: true,
      ewma: true,
      runRules: true,
      controlLimits: false,
      toleranceLimits: false
    },
    {
      yAxis: 'userDefinedLTL',
      name: 'User Defined LTL',
      label: ``,
      color: 'red',
      reference: true,
      ewma: false,
      runRules: false,
      controlLimits: false,
      toleranceLimits: true
    },
    {
      yAxis: 'userDefinedUTL',
      name: 'User Defined UTL',
      label: ``,
      color: 'red',
      reference: true,
      ewma: false,
      runRules: false,
      controlLimits: false,
      toleranceLimits: true
    },
    {
      yAxis: 'userDefinedLCL',
      name: 'User Defined LCL',
      label: ``,
      color: 'red',
      reference: true,
      ewma: false,
      runRules: false,
      controlLimits: true,
      toleranceLimits: false
    },
    {
      yAxis: 'userDefinedUCL',
      name: 'User Defined UCL',
      label: ``,
      color: 'red',
      reference: true,
      ewma: false,
      runRules: false,
      controlLimits: true,
      toleranceLimits: false
    }
  ];

  const defaultChartLinesNonUs = [
    {
      yAxis: 'resultOmits',
      name: 'Data',
      color: '#32CD32',
      ewma: true,
      runRules: true,
      controlLimits: true,
      toleranceLimits: true
    },
    {
      yAxis: 'ewmaOmits',
      name: 'Trend',
      color: '#B0E0E6',
      ewma: true,
      runRules: false,
      controlLimits: false,
      toleranceLimits: false
    },
    {
      yAxis: 'mean',
      name: 'Average',
      reference: chartData.length > 20,
      label: '',
      color: '#FFBF00',
      ewma: true,
      runRules: true,
      controlLimits: true,
      toleranceLimits: true
    },
    {
      yAxis: 'sd15p',
      name: 'EWMA UCL',
      label: '',
      color: '#9932CC',
      reference: true,
      ewma: true,
      runRules: true,
      controlLimits: true,
      toleranceLimits: true
    },
    {
      yAxis: 'sd15n',
      name: 'EWMA LCL',
      label: '',
      color: '#9932CC',
      reference: true,
      ewma: true,
      runRules: true,
      controlLimits: true,
      toleranceLimits: true
    },
    {
      yAxis: 'sd3n',
      name: 'LCL',
      label: '',
      color: '#FBCEB1',
      reference: true,
      ewma: true,
      runRules: true,
      controlLimits: false,
      toleranceLimits: false
    },
    {
      yAxis: 'sd3p',
      name: 'UCL',
      label: '',
      color: '#FBCEB1',
      reference: true,
      ewma: true,
      runRules: true,
      controlLimits: false,
      toleranceLimits: false
    },
    {
      yAxis: 'userDefinedLTL',
      name: 'User Defined LTL',
      label: ``,
      color: 'red',
      reference: true,
      ewma: false,
      runRules: false,
      controlLimits: false,
      toleranceLimits: true
    },
    {
      yAxis: 'userDefinedUTL',
      name: 'User Defined UTL',
      label: ``,
      color: 'red',
      reference: true,
      ewma: false,
      runRules: false,
      controlLimits: false,
      toleranceLimits: true
    },
    {
      yAxis: 'userDefinedLCL',
      name: 'User Defined LCL',
      label: ``,
      color: 'red',
      reference: true,
      ewma: false,
      runRules: false,
      controlLimits: true,
      toleranceLimits: false
    },
    {
      yAxis: 'userDefinedUCL',
      name: 'User Defined UCL',
      label: ``,
      color: 'red',
      reference: true,
      ewma: false,
      runRules: false,
      controlLimits: true,
      toleranceLimits: false
    }
  ];
  const isNonUs = companyTypes && companyTypes[0] && companyTypes[0]['nonUs'];
  const isEverFlags = companyTypes && companyTypes[0] && companyTypes[0]['everFlags'];
  const [chartLines, setChartLines] = useState(
    isNonUs ? defaultChartLinesNonUs : defaultChartLines
  );

  const renderSigDigits = (stringValue) => {
    return getSigDigitsUtil(stringValue, currentControlStrategyConfigurations.chartSigDigits);
  };

  const summaryStatisticsDataPeriodic = [
    {
      statistic: 'Sample Size (n)',
      setupData: currentSetupDataOne,
      recentData: currentSetupDataZero,
      pooledData: currentSetupDataOne + currentSetupDataZero,
      evaluation: ''
    },
    {
      statistic: 'Average',
      setupData: currentSummary ? renderSigDigits(sanitizePropertyData(currentSummary.avg_sd)) : '',
      recentData: currentSummary
        ? renderSigDigits(sanitizePropertyData(currentSummary.avg_nd))
        : '',
      pooledData: currentSummary
        ? renderSigDigits(sanitizePropertyData(currentSummary.avg_or))
        : '',
      evaluation: ''
    },
    {
      statistic: 'Standard Deviation, RMS',
      setupData: currentSummary
        ? renderSigDigits(sanitizePropertyData(currentSummary.sd_rms_sd))
        : '',
      recentData: currentSummary
        ? renderSigDigits(sanitizePropertyData(currentSummary.sd_rms_nd))
        : '',
      pooledData: currentSummary
        ? renderSigDigits(sanitizePropertyData(currentSummary.sd_rms_or))
        : '',
      evaluation: ''
    },
    {
      statistic: 'Standard Deviation, MR',
      setupData: currentSummary
        ? renderSigDigits(sanitizePropertyData(currentSummary.sd_mr_sd))
        : '',
      recentData: currentSummary
        ? renderSigDigits(sanitizePropertyData(currentSummary.sd_mr_nd))
        : '',
      pooledData: currentSummary
        ? renderSigDigits(sanitizePropertyData(currentSummary.sd_mr_or))
        : '',
      evaluation: ''
    },
    {
      statistic: 'GESD outlier (count)',
      setupData: currentSummary
        ? renderSigDigits(sanitizePropertyData(currentSummary.outliers_gesd))
        : '',
      recentData: currentSummary
        ? renderSigDigits(sanitizePropertyData(currentSummary.outliers_gend))
        : '',
      pooledData: currentSummary
        ? renderSigDigits(sanitizePropertyData(currentSummary.outliers_geor))
        : '',
      evaluation: ''
    },
    {
      statistic: 'Runs Omitted',
      setupData: currentSummary
        ? renderSigDigits(sanitizePropertyData(currentSummary.omits_sd))
        : '',
      recentData: currentSummary
        ? renderSigDigits(sanitizePropertyData(currentSummary.omits_nd))
        : '',
      pooledData: currentSummary
        ? renderSigDigits(sanitizePropertyData(currentSummary.omits_all))
        : '',
      evaluation: ''
    },
    {
      statistic: 'Repeatability (r)',
      setupData: currentSummary
        ? renderSigDigits(sanitizePropertyData(currentSummary.repeat_sd))
        : '',
      recentData: currentSummary
        ? renderSigDigits(sanitizePropertyData(currentSummary.repeat_nd))
        : '',
      pooledData: currentSummary
        ? renderSigDigits(sanitizePropertyData(currentSummary.repeat_or))
        : '',
      evaluation: ''
    },
    {
      statistic: 'Reproducibility (R)',
      setupData: currentSummary
        ? renderSigDigits(sanitizePropertyData(currentSummary.repro_sd))
        : '',
      recentData: currentSummary
        ? renderSigDigits(sanitizePropertyData(currentSummary.repro_nd))
        : '',
      pooledData: currentSummary
        ? renderSigDigits(sanitizePropertyData(currentSummary.repro_or))
        : '',
      evaluation: ''
    },
    {
      statistic: 'Site Precision',
      setupData: currentSummary ? renderSigDigits(sanitizePropertyData(currentSummary.sp_sd)) : '',
      recentData: currentSummary ? renderSigDigits(sanitizePropertyData(currentSummary.sp_nd)) : '',
      pooledData: currentSummary ? renderSigDigits(sanitizePropertyData(currentSummary.sp_or)) : '',
      evaluation: ''
    },
    {
      statistic: 'Precision Ratio',
      setupData: currentSummary ? renderSigDigits(sanitizePropertyData(currentSummary.pr_sd)) : '',
      recentData: currentSummary ? renderSigDigits(sanitizePropertyData(currentSummary.pr_nd)) : '',
      pooledData: currentSummary ? renderSigDigits(sanitizePropertyData(currentSummary.pr_or)) : '',
      evaluation: ''
    },
    {
      statistic: 'TPI',
      setupData: currentSummary ? renderSigDigits(sanitizePropertyData(currentSummary.tpi_sd)) : '',
      recentData: currentSummary
        ? renderSigDigits(sanitizePropertyData(currentSummary.tpi_nd))
        : '',
      pooledData: currentSummary
        ? renderSigDigits(sanitizePropertyData(currentSummary.tpi_or))
        : '',
      evaluation: currentSummary ? sanitizePropertyData(currentSummary.tpi_eval_or) : ''
    },
    {
      statistic: 'Anderson Darling (RMS)',
      setupData: currentSummary
        ? renderSigDigits(sanitizePropertyData(currentSummary.ad_rms_sd))
        : '',
      recentData: currentSummary
        ? renderSigDigits(sanitizePropertyData(currentSummary.ad_rms_nd))
        : '',
      pooledData: currentSummary
        ? renderSigDigits(sanitizePropertyData(currentSummary.ad_rms_or))
        : '',
      evaluation: currentSummary ? sanitizePropertyData(currentSummary.ad_eval_or) : ''
    },
    {
      statistic: 'Chi-squared (RMS)',
      setupData: currentSummary ? renderSigDigits(sanitizePropertyData(currentSummary.chi_sd)) : '',
      recentData: currentSummary
        ? renderSigDigits(sanitizePropertyData(currentSummary.chi_nd))
        : '',
      pooledData: currentSummary
        ? renderSigDigits(sanitizePropertyData(currentSummary.chi_or))
        : '',
      evaluation: currentSummary ? sanitizePropertyData(currentSummary.chi_eval_or) : ''
    },
    {
      statistic: 'Chi-squared (critical)',
      setupData: currentSummary
        ? renderSigDigits(sanitizePropertyData(currentSummary.chi_critical_sd))
        : '',
      recentData: currentSummary
        ? renderSigDigits(sanitizePropertyData(currentSummary.chi_critical_nd))
        : '',
      pooledData: currentSummary
        ? renderSigDigits(sanitizePropertyData(currentSummary.chi_critical_or))
        : '',
      evaluation: currentSummary ? sanitizePropertyData(currentSummary.chi_eval_or) : ''
    },
    {
      statistic: 't-test, critical value',
      setupData: '',
      recentData: '',
      pooledData: currentSummary
        ? renderSigDigits(sanitizePropertyData(currentSummary.t_critical))
        : '',
      evaluation: currentSummary ? sanitizePropertyData(currentSummary.t_eval) : ''
    },
    {
      statistic: 't-test, test statistic',
      setupData: '',
      recentData: '',
      pooledData: currentSummary
        ? renderSigDigits(sanitizePropertyData(currentSummary.t_statistic))
        : '',
      evaluation: currentSummary ? sanitizePropertyData(currentSummary.t_eval) : ''
    },
    {
      statistic: 'F-test, critical value',
      setupData: '',
      recentData: '',
      pooledData: currentSummary
        ? renderSigDigits(sanitizePropertyData(currentSummary.f_statistic))
        : '',
      evaluation: currentSummary ? sanitizePropertyData(currentSummary.f_eval) : ''
    },
    {
      statistic: 'Variance ratio (F-test)',
      setupData: '',
      recentData: '',
      pooledData: currentSummary ? renderSigDigits(sanitizePropertyData(currentSummary.f_vr)) : '',
      evaluation: currentSummary ? sanitizePropertyData(currentSummary.f_eval) : ''
    },
    {
      statistic: 'UCL',
      setupData: currentSummary ? renderSigDigits(sanitizePropertyData(currentSummary.ucl_sd)) : '',
      recentData: currentSummary
        ? renderSigDigits(sanitizePropertyData(currentSummary.ucl_nd))
        : '',
      pooledData: currentSummary
        ? renderSigDigits(sanitizePropertyData(currentSummary.ucl_or))
        : '',
      evaluation: ''
    },
    {
      statistic: 'LCL',
      setupData: currentSummary ? renderSigDigits(sanitizePropertyData(currentSummary.lcl_sd)) : '',
      recentData: currentSummary
        ? renderSigDigits(sanitizePropertyData(currentSummary.lcl_nd))
        : '',
      pooledData: currentSummary
        ? renderSigDigits(sanitizePropertyData(currentSummary.lcl_or))
        : '',
      evaluation: ''
    },
    {
      statistic: 'UWL',
      setupData: currentSummary ? renderSigDigits(sanitizePropertyData(currentSummary.uwl_sd)) : '',
      recentData: currentSummary
        ? renderSigDigits(sanitizePropertyData(currentSummary.uwl_nd))
        : '',
      pooledData: currentSummary
        ? renderSigDigits(sanitizePropertyData(currentSummary.uwl_or))
        : '',
      evaluation: ''
    },
    {
      statistic: 'LWL',
      setupData: currentSummary ? renderSigDigits(sanitizePropertyData(currentSummary.lwl_sd)) : '',
      recentData: currentSummary
        ? renderSigDigits(sanitizePropertyData(currentSummary.lwl_nd))
        : '',
      pooledData: currentSummary
        ? renderSigDigits(sanitizePropertyData(currentSummary.lwl_or))
        : '',
      evaluation: ''
    },
    {
      statistic: 'MR UCL',
      setupData: currentSummary
        ? renderSigDigits(sanitizePropertyData(currentSummary.mrucl_sd))
        : '',
      recentData: currentSummary
        ? renderSigDigits(sanitizePropertyData(currentSummary.mrucl_nd))
        : '',
      pooledData: currentSummary
        ? renderSigDigits(sanitizePropertyData(currentSummary.mrucl_or))
        : '',
      evaluation: ''
    }
  ];

  const [currentModalAvailableTimes, setCurrentModalAvailableTimes] = useState([]);
  const [currentModalSchedule, setCurrentModalSchedule] = useState({});
  const [currentSampleSchedules, setCurrentSampleSchedules] = useState([]);
  const [scheduleModalType, setScheduleModalType] = useState('');
  const [sampleScheduleTimes] = useState([
    { time: '12:00 AM', label: '12:00 AM' },
    { time: '12:30 AM', label: '12:30 AM' },
    { time: '1:00 AM', label: '1:00 AM' },
    { time: '1:30 AM', label: '1:30 AM' },
    { time: '2:00 AM', label: '2:00 AM' },
    { time: '2:30 AM', label: '2:30 AM' },
    { time: '3:00 AM', label: '3:00 AM' },
    { time: '3:30 AM', label: '3:30 AM' },
    { time: '4:00 AM', label: '4:00 AM' },
    { time: '4:30 AM', label: '4:30 AM' },
    { time: '5:00 AM', label: '5:00 AM' },
    { time: '5:30 AM', label: '5:30 AM' },
    { time: '6:00 AM', label: '6:00 AM' },
    { time: '6:30 AM', label: '6:30 AM' },
    { time: '7:00 AM', label: '7:00 AM' },
    { time: '7:30 AM', label: '7:30 AM' },
    { time: '8:00 AM', label: '8:00 AM' },
    { time: '8:30 AM', label: '8:30 AM' },
    { time: '9:00 AM', label: '9:00 AM' },
    { time: '9:30 AM', label: '9:30 AM' },
    { time: '10:00 AM', label: '10:00 AM' },
    { time: '10:30 AM', label: '10:30 AM' },
    { time: '11:00 AM', label: '11:00 AM' },
    { time: '11:30 AM', label: '11:30 AM' },
    { time: '12:00 PM', label: '12:00 PM' },
    { time: '12:30 PM', label: '12:30 PM' },
    { time: '1:00 PM', label: '1:00 PM' },
    { time: '1:30 PM', label: '1:30 PM' },
    { time: '2:00 PM', label: '2:00 PM' },
    { time: '2:30 PM', label: '2:30 PM' },
    { time: '3:00 PM', label: '3:00 PM' },
    { time: '3:30 PM', label: '3:30 PM' },
    { time: '4:00 PM', label: '4:00 PM' },
    { time: '4:30 PM', label: '4:30 PM' },
    { time: '5:00 PM', label: '5:00 PM' },
    { time: '5:30 PM', label: '5:30 PM' },
    { time: '6:00 PM', label: '6:00 PM' },
    { time: '6:30 PM', label: '6:30 PM' },
    { time: '7:00 PM', label: '7:00 PM' },
    { time: '7:30 PM', label: '7:30 PM' },
    { time: '8:00 PM', label: '8:00 PM' },
    { time: '8:30 PM', label: '8:30 PM' },
    { time: '9:00 PM', label: '9:00 PM' },
    { time: '9:30 PM', label: '9:30 PM' },
    { time: '10:00 PM', label: '10:00 PM' },
    { time: '10:30 PM', label: '10:30 PM' },
    { time: '11:00 PM', label: '11:00 PM' },
    { time: '11:30 PM', label: '11:30 PM' }
  ]);

  const aggregateFlags = (summaryIn) => {
    const summary = summaryIn;
    summary.flags = '';
    summary.chartStatus = 'In control';
    if (summary.ooc_or && summary.warnings_or) {
      if (summary.ooc_or.length > 0 && summary.warnings_or.length === 0) {
        summary.flags = `OOC: ${summary.ooc_or}`;
        summary.chartStatus = 'OOC';
      } else if (summary.warnings_or.length > 0 && summary.ooc_or.length === 0) {
        summary.flags = `Warning: ${summary.warnings_or}`;
        summary.chartStatus = 'Warning';
      } else if (summary.warnings_or.length > 0 && summary.ooc_or.length > 0) {
        summary.flags = `OOC: ${summary.ooc_or}, Warning: ${summary.warnings_or}`;
        summary.chartStatus = 'OOC';
      }
    }
    return summary;
  };

  useEffect(() => {
    if (Array.isArray(sampleSchedules)) {
      const updatedSampleSchedules = sampleSchedules
        .filter((schedule) => schedule.shortKey === currentShortKey)
        .map((schedule) => {
          const { seconds, nanoseconds } = schedule.startingDate;
          const milliseconds = seconds * 1000 + nanoseconds / 1000000;
          const startingDateDisplayed = moment(new Date(milliseconds)).format('MM/DD/YYYY');
          return {
            ...schedule,
            startingDateDisplayed
          };
        });
      setCurrentSampleSchedules(updatedSampleSchedules);
    }
  }, [sampleSchedules, currentShortKey]);

  useEffect(() => {
    if (Array.isArray(summaries) && summaries.length > 0) {
      summaries.forEach((summaryIn) => {
        const summary = summaryIn;
        summary.recent_sample = parseFloat(summary.recent_sample).toFixed(5);
        summary.tpi_or = parseFloat(summary.tpi_or).toFixed(5);
        summary.sp_or = parseFloat(summary.sp_or).toFixed(5);
        summary.avg_or = parseFloat(summary.avg_or).toFixed(5);
        summary.sd_rms_or = parseFloat(summary.sd_rms_or).toFixed(5);
        summary.sd_mr_or = parseFloat(summary.sd_mr_or).toFixed(5);
        summary.repeat_or = parseFloat(summary.repeat_or).toFixed(5);
        summary.repro_or = parseFloat(summary.repro_or).toFixed(5);
        summary.pr_or = parseFloat(summary.pr_or).toFixed(5);
        summary.ad_mr_or = parseFloat(summary.ad_mr_or).toFixed(5);
        aggregateFlags(summary);
        const fullKey = summary.id;
        summary.location = fullKey.substr(0, fullKey.indexOf('|'));
        const secondStr = fullKey.substr(fullKey.indexOf('|') + 1, fullKey.length - 1);
        summary.method = secondStr.substr(0, secondStr.indexOf('|'));
        const thirdStr = secondStr.substr(secondStr.indexOf('|') + 1, secondStr.length - 1);
        summary.sampleName = thirdStr.substr(0, thirdStr.indexOf('|'));
        const fourthStr = thirdStr.substr(thirdStr.indexOf('|') + 1, thirdStr.length - 1);
        summary.instrument = fourthStr.substr(0, fourthStr.indexOf('|'));
        const fifthStr = fourthStr.substr(fourthStr.indexOf('|') + 1, fourthStr.length - 1);
        summary.parameter = fifthStr.substr(0, fifthStr.length);
      });
      setCurrentSummaries(summaries);
    }
  }, [summaries]);

  const archiveDataPoints = () => {
    const updatedModalAssessment = { ...modalAssessment };
    if (updatedModalAssessment.archive === 'Yes') {
      updatedModalAssessment.archive = 'No';
    } else {
      updatedModalAssessment.archive = 'Yes';
    }
    setModalAssessment(updatedModalAssessment);
  };

  const poolData = () => {
    items.forEach((dataPointIn) => {
      const dataPoint = dataPointIn;
      dataPoint.flags = '';
      dataPoint.locked = true;
      dataPoint.setupData = 1;
      updateData(dataPoint.id, dataPoint);
    });
  };

  const unArchiveDataPoints = () => {
    items.forEach((dataPointIn) => {
      const dataPoint = dataPointIn;
      dataPoint.flags = '';
      dataPoint.archive = false;
      updateData(dataPoint.id, dataPoint);
    });
  };

  const handleAssessmentChange = (e, propertyName) => {
    const updatedModalAssessment = { ...modalAssessment };
    updatedModalAssessment[propertyName] = e.target.value;
    setModalAssessment(updatedModalAssessment);
  };

  const renderPeriodicPage = (page) => {
    switch (page) {
      case '1':
        return (
          <>
            <Row className="report-header">
              <h4>
                Summary Statistics: {currentParameter}, {unit}
              </h4>
            </Row>
            <Row className="statistics-table">
              <CustomTable
                numberOfRows={50}
                hideFooter={true}
                data={summaryStatisticsDataPeriodic}
                header={summaryStatisticsColumnsPeriodic}
              />
            </Row>
            <div>
              <table>
                <tr />
              </table>
            </div>
            <Row className="report-header">
              <h4>Assessment: {currentParameter}</h4>
            </Row>
            <Row>
              <Col lg={9}>
                <h6 className="ia-table" style={{ marginLeft: '50px' }}>
                  1. Visual Assessment for suspicious results (including GESD outliers)
                </h6>
              </Col>
              <Col>
                <ToggleButton
                  key="radioSR-pass"
                  id="radioSR-pass"
                  type="radio"
                  variant="outline-primary"
                  name="radio"
                  style={
                    modalAssessment.suspiciousResults === 'Pass'
                      ? { backgroundColor: 'navy', color: 'white' }
                      : {}
                  }
                  value="Pass"
                  checked={modalAssessment.suspiciousResults === 'Pass'}
                  onChange={(e) => handleAssessmentChange(e, 'suspiciousResults')}
                >
                  Pass
                </ToggleButton>
                <ToggleButton
                  key="radioSR-fail"
                  id="radioSR-fail"
                  type="radio"
                  variant="outline-primary"
                  name="radio"
                  style={
                    modalAssessment.suspiciousResults === 'Fail'
                      ? { backgroundColor: 'navy', color: 'white' }
                      : {}
                  }
                  value="Fail"
                  checked={modalAssessment.suspiciousResults === 'Fail'}
                  onChange={(e) => handleAssessmentChange(e, 'suspiciousResults')}
                >
                  Fail
                </ToggleButton>
              </Col>
            </Row>
            <Row>
              <Col lg={9}>
                <h6 className="ia-table" style={{ marginLeft: '50px' }}>
                  2. Visual Assessment for unusual patterns (trending, clustering, cycles)
                </h6>
              </Col>
              <Col>
                <ToggleButton
                  key="radioUP-pass"
                  id="radioUP-pass"
                  type="radio"
                  variant="outline-primary"
                  name="radio"
                  style={
                    modalAssessment.unusualPatterns === 'Pass'
                      ? { backgroundColor: 'navy', color: 'white' }
                      : {}
                  }
                  value="Pass"
                  checked={modalAssessment.unusualPatterns === 'Pass'}
                  onChange={(e) => handleAssessmentChange(e, 'unusualPatterns')}
                >
                  Pass
                </ToggleButton>
                <ToggleButton
                  key="radioUP-fail"
                  id="radioUP-fail"
                  type="radio"
                  variant="outline-primary"
                  name="radio"
                  style={
                    modalAssessment.unusualPatterns === 'Fail'
                      ? { backgroundColor: 'navy', color: 'white' }
                      : {}
                  }
                  value="Fail"
                  checked={modalAssessment.unusualPatterns === 'Fail'}
                  onChange={(e) => handleAssessmentChange(e, 'unusualPatterns')}
                >
                  Fail
                </ToggleButton>
              </Col>
            </Row>
            <Row>
              <Col lg={9}>
                <h6 className="ia-table" style={{ marginLeft: '50px' }}>
                  3. Evaluation of TPI to assess site precision vs Reproducibility
                </h6>
              </Col>
              <Col>
                <ToggleButton
                  key="radioTE-pass"
                  id="radioTE-pass"
                  type="radio"
                  variant="outline-primary"
                  name="radio"
                  style={
                    modalAssessment.tpiEvaluation === 'Pass'
                      ? { backgroundColor: 'navy', color: 'white' }
                      : {}
                  }
                  value="Pass"
                  checked={modalAssessment.tpiEvaluation === 'Pass'}
                  onChange={(e) => handleAssessmentChange(e, 'tpiEvaluation')}
                >
                  Pass
                </ToggleButton>
                <ToggleButton
                  key="radioTE-fail"
                  id="radioTE-fail"
                  type="radio"
                  variant="outline-primary"
                  name="radio"
                  style={
                    modalAssessment.tpiEvaluation === 'Fail'
                      ? { backgroundColor: 'navy', color: 'white' }
                      : {}
                  }
                  value="Fail"
                  checked={modalAssessment.tpiEvaluation === 'Fail'}
                  onChange={(e) => handleAssessmentChange(e, 'tpiEvaluation')}
                >
                  Fail
                </ToggleButton>
              </Col>
            </Row>
            <Row>
              <Col lg={9}>
                <h6 className="ia-table" style={{ marginLeft: '50px' }}>
                  4. Performance of Chi Square test to assess site precision vs Reproducibility
                </h6>
              </Col>
              <Col>
                <ToggleButton
                  key="radioCST-pass"
                  id="radioCST-pass"
                  type="radio"
                  variant="outline-primary"
                  name="radio"
                  style={
                    modalAssessment.chiSquareTest === 'Pass'
                      ? { backgroundColor: 'navy', color: 'white' }
                      : {}
                  }
                  value="Pass"
                  checked={modalAssessment.chiSquareTest === 'Pass'}
                  onChange={(e) => handleAssessmentChange(e, 'chiSquareTest')}
                >
                  Pass
                </ToggleButton>
                <ToggleButton
                  key="radioCST-fail"
                  id="radioCST-fail"
                  type="radio"
                  variant="outline-primary"
                  name="radio"
                  style={
                    modalAssessment.chiSquareTest === 'Fail'
                      ? { backgroundColor: 'navy', color: 'white' }
                      : {}
                  }
                  value="Fail"
                  checked={modalAssessment.chiSquareTest === 'Fail'}
                  onChange={(e) => handleAssessmentChange(e, 'chiSquareTest')}
                >
                  Fail
                </ToggleButton>
              </Col>
            </Row>
            <Row>
              <Col lg={9}>
                <h6 className="ia-table" style={{ marginLeft: '50px' }}>
                  5. Assessment of normal probability plot (for linearity) and histogram
                </h6>
              </Col>
              <Col>
                <ToggleButton
                  key="radioNPP-pass"
                  id="radioNPP-pass"
                  type="radio"
                  variant="outline-primary"
                  name="radio"
                  style={
                    modalAssessment.normalProbabilityPlot === 'Pass'
                      ? { backgroundColor: 'navy', color: 'white' }
                      : {}
                  }
                  value="Pass"
                  checked={modalAssessment.normalProbabilityPlot === 'Pass'}
                  onChange={(e) => handleAssessmentChange(e, 'normalProbabilityPlot')}
                >
                  Pass
                </ToggleButton>
                <ToggleButton
                  key="radioNPP-fail"
                  id="radioNPP-fail"
                  type="radio"
                  variant="outline-primary"
                  name="radio"
                  style={
                    modalAssessment.normalProbabilityPlot === 'Fail'
                      ? { backgroundColor: 'navy', color: 'white' }
                      : {}
                  }
                  value="Fail"
                  checked={modalAssessment.normalProbabilityPlot === 'Fail'}
                  onChange={(e) => handleAssessmentChange(e, 'normalProbabilityPlot')}
                >
                  Fail
                </ToggleButton>
              </Col>
            </Row>
            <Row>
              <Col lg={9}>
                <h6 className="ia-table" style={{ marginLeft: '50px' }}>
                  6. Evaluation of Anderson-Darling test statistic for normality
                </h6>
              </Col>
              <Col>
                <ToggleButton
                  key="radioAD-pass"
                  id="radioAD-pass"
                  type="radio"
                  variant="outline-primary"
                  name="radio"
                  style={
                    modalAssessment.andersonDarling === 'Pass'
                      ? { backgroundColor: 'navy', color: 'white' }
                      : {}
                  }
                  value="Pass"
                  checked={modalAssessment.andersonDarling === 'Pass'}
                  onChange={(e) => handleAssessmentChange(e, 'andersonDarling')}
                >
                  Pass
                </ToggleButton>
                <ToggleButton
                  key="radioAD-fail"
                  id="radioAD-fail"
                  type="radio"
                  variant="outline-primary"
                  name="radio"
                  style={
                    modalAssessment.andersonDarling === 'Fail'
                      ? { backgroundColor: 'navy', color: 'white' }
                      : {}
                  }
                  value="Fail"
                  checked={modalAssessment.andersonDarling === 'Fail'}
                  onChange={(e) => handleAssessmentChange(e, 'andersonDarling')}
                >
                  Fail
                </ToggleButton>
              </Col>
            </Row>
            <Row>
              <Col lg={9}>
                <h6 className="ia-table" style={{ marginLeft: '50px' }}>
                  7. Performance of t-Test to evaluate accuracy / bias
                </h6>
              </Col>
              <Col>
                <ToggleButton
                  key="radiotT-pass"
                  id="radiotT-pass"
                  type="radio"
                  variant="outline-primary"
                  name="radio"
                  style={
                    modalAssessment.tTest === 'Pass'
                      ? { backgroundColor: 'navy', color: 'white' }
                      : {}
                  }
                  value="Pass"
                  checked={modalAssessment.tTest === 'Pass'}
                  onChange={(e) => handleAssessmentChange(e, 'tTest')}
                >
                  Pass
                </ToggleButton>
                <ToggleButton
                  key="radiotT-fail"
                  id="radiotT-fail"
                  type="radio"
                  variant="outline-primary"
                  name="radio"
                  style={
                    modalAssessment.tTest === 'Fail'
                      ? { backgroundColor: 'navy', color: 'white' }
                      : {}
                  }
                  value="Fail"
                  checked={modalAssessment.tTest === 'Fail'}
                  onChange={(e) => handleAssessmentChange(e, 'tTest')}
                >
                  Fail
                </ToggleButton>
              </Col>
            </Row>
            <Row>
              <Col lg={9}>
                <h6 className="ia-table" style={{ marginLeft: '50px' }}>
                  8. Performance of F-Test to evaluate changes in variance
                </h6>
              </Col>
              <Col>
                <ToggleButton
                  key="radiofT-pass"
                  id="radiofT-pass"
                  type="radio"
                  variant="outline-primary"
                  name="radio"
                  style={
                    modalAssessment.fTest === 'Pass'
                      ? { backgroundColor: 'navy', color: 'white' }
                      : {}
                  }
                  value="Pass"
                  checked={modalAssessment.fTest === 'Pass'}
                  onChange={(e) => handleAssessmentChange(e, 'fTest')}
                >
                  Pass
                </ToggleButton>
                <ToggleButton
                  key="radiofT-fail"
                  id="radiofT-fail"
                  type="radio"
                  variant="outline-primary"
                  name="radio"
                  style={
                    modalAssessment.fTest === 'Fail'
                      ? { backgroundColor: 'navy', color: 'white' }
                      : {}
                  }
                  value="Fail"
                  checked={modalAssessment.fTest === 'Fail'}
                  onChange={(e) => handleAssessmentChange(e, 'fTest')}
                >
                  Fail
                </ToggleButton>
              </Col>
            </Row>
            <Row>
              <Col lg={9}>
                <h6 className="ia-table" style={{ marginLeft: '50px' }}>
                  Assessor comments
                </h6>
              </Col>
            </Row>
            <Row>
              <Col lg={9}>
                <Form className="ia-table" style={{ marginLeft: '50px' }}>
                  <Form.Group controlId="exampleForm.ControlTextarea1">
                    <Form.Control
                      className="comments"
                      as="textarea"
                      rows={2}
                      onChange={(e) => handleAssessmentChange(e, 'assessorComments')}
                      aria-label="Basic example"
                      value={modalAssessment.assessorComments}
                    />
                  </Form.Group>
                </Form>
              </Col>
            </Row>
            <Row style={{ marginTop: '15px' }}>
              <Col lg={9}>
                <h6 className="ia-table" style={{ marginLeft: '50px' }}>
                  Assessment outcome
                </h6>
              </Col>
              <Col>
                <ToggleButton
                  key="radioOutcome-pass"
                  id="radioOutcome-pass"
                  type="radio"
                  variant="outline-primary"
                  name="radio"
                  style={
                    modalAssessment.outcome === 'Pass'
                      ? { backgroundColor: 'navy', color: 'white' }
                      : {}
                  }
                  value="Pass"
                  checked={modalAssessment.outcome === 'Pass'}
                  onChange={(e) => handleAssessmentChange(e, 'outcome')}
                >
                  Pass
                </ToggleButton>
                <ToggleButton
                  key="radioOutcome-fail"
                  id="radioOutcome-fail"
                  type="radio"
                  variant="outline-primary"
                  name="radio"
                  style={
                    modalAssessment.outcome === 'Fail'
                      ? { backgroundColor: 'navy', color: 'white' }
                      : {}
                  }
                  value="Fail"
                  checked={modalAssessment.outcome === 'Fail'}
                  onChange={(e) => handleAssessmentChange(e, 'outcome')}
                >
                  Fail
                </ToggleButton>
              </Col>
            </Row>
          </>
        );
      case '2':
        return (
          <>
            <Row className="report-header">
              <h4>Chart: {currentParameter}</h4>
            </Row>
            <div>
              <div className="chart-row-container">
                <SQCLineChart
                  xAxis="updatedIndex"
                  syncId="chained"
                  yMin={chartScalars.cYMin}
                  yMax={chartScalars.cYMax}
                  data={chartData}
                  lines={chartLines}
                />
                <SQCMovingRangeChart
                  xAxis="updatedIndex"
                  key={uuidv4()}
                  data={chartData}
                  syncId="chained2"
                  yMin={0}
                  yMax={chartScalars.mrYMax}
                  lines={[
                    {
                      yAxis: 'mrOmits',
                      name: 'Data',
                      color: '#32CD32'
                    },
                    {
                      yAxis: 'mean',
                      overrideValue: chartScalars.mrMean,
                      name: 'Average',
                      reference: true,
                      label: ``,
                      color: '#FFBF00'
                    },
                    {
                      yAxis: 'sd3p',
                      overrideValue: chartScalars.mrUCL,
                      label: ``,
                      color: '#FBCEB1',
                      reference: true
                    }
                  ]}
                />
              </div>
              <Row>
                <Col>
                  <>
                    {chartScalars.histogramData && (
                      <SQCHistogram data={chartScalars.histogramData} />
                    )}
                    {!chartScalars.histogramData && <div>No Histogram</div>}
                  </>
                </Col>
                <Col>
                  <SQCScatterPlot
                    xAxis="zScoreOmits"
                    key={uuidv4()}
                    data={chartData}
                    syncId="chained2"
                    xMin={chartScalars.qqXMin}
                    xMax={chartScalars.qqXMax}
                    yMin={chartScalars.qqYMin}
                    yMax={chartScalars.qqYMax}
                    collections={[
                      {
                        name: 'Lab Results',
                        color: '#32CD32',
                        data: chartData.map((cd) => {
                          return {
                            x: cd.zScoreOmits,
                            y: cd.resultOmits
                          };
                        })
                      },
                      {
                        name: 'Trend Line',
                        color: '#FFBF00',
                        data: chartScalars.trendData,
                        line: true
                      }
                    ]}
                  />
                </Col>
              </Row>
            </div>
          </>
        );
      case '3':
        return (
          <>
            <Row className="report-header">
              <h4>Data: {currentParameter}</h4>
            </Row>
            <div className="page-three-container">
              <CustomTable
                numberOfRows={pageSizeDefault}
                data={filteredChartHits}
                header={defaultColumnsReport}
                sort={{ sorting: { sortModel: [{ field: 'dateTime', sort: 'asc' }] } }}
              />
            </div>
          </>
        );
      default:
        return <></>;
    }
  };

  const selectPeriodicPage = (e) => {
    setPeriodicPage(e.target.value);
  };

  function sortCountCurrentValues(currentArray) {
    const sortedCountedArray = [];
    currentArray.forEach((element) => {
      const existingIndex = sortedCountedArray.findIndex(
        (scElement) => scElement.name === element.name
      );
      if (existingIndex !== -1) {
        sortedCountedArray[existingIndex].count += element.count;
      } else {
        sortedCountedArray.push({ ...element });
      }
    });
    return sortedCountedArray;
  }

  const artecoCustomParameterSort = (parameters) => {
    const customArtecoSort = (s) => {
      if (s === 'Reserve Alkalinity - Start pH') return 0;
      else if (s === 'Reserve Alkalinity - pH 7_0') return 1;
      else if (s === 'Reserve Alkalinity - pH 5_5') return 2;
      else if (s === 'Reserve Alkalinity - Inflection Point') return 3;
      else return 4;
    };

    const sortedParameters = parameters
      .slice()
      .sort((a, b) => customArtecoSort(a) - customArtecoSort(b));
    return sortedParameters;
  };

  const mapLocationChildren = (
    value,
    updatedMethods,
    updatedSampleNames,
    updatedInstruments,
    updatedParameters
  ) => {
    const currentFilteredLocation = currentLocations.filter((location) => location.name === value);
    if (currentFilteredLocation[0]) {
      currentFilteredLocation[0].method.forEach((method) => {
        updatedMethods.push(method);
        if (!instrumentBeforeSampleName) {
          method.sampleName?.forEach((listedSampleName) => {
            updatedSampleNames.push(listedSampleName);
            listedSampleName.instrument.forEach((instrument) => {
              updatedInstruments.push(instrument);
              instrument.parameter.forEach((parameter) => {
                updatedParameters.push(parameter);
              });
            });
          });
        } else {
          method.instrument?.forEach((listedInstrument) => {
            updatedInstruments.push(listedInstrument);
            listedInstrument.sampleName.forEach((sampleName) => {
              updatedSampleNames.push(sampleName);
              sampleName.parameter.forEach((parameter) => {
                updatedParameters.push(parameter);
              });
            });
          });
        }
      });
    }
  };

  const handleLocationChange = (e, fromUrl = false) => {
    setCurrentLocation(e.target.value);
    const updatedMethods = [];
    const updatedSampleNames = [];
    const updatedInstruments = [];
    const updatedParameters = [];
    mapLocationChildren(
      e.target.value,
      updatedMethods,
      updatedSampleNames,
      updatedInstruments,
      updatedParameters
    );
    setCurrentMethod(null);
    setCurrentSampleName(null);
    setCurrentInstrument(null);
    setCurrentParameter(null);
    setCurrentMethods(sortCountCurrentValues(updatedMethods));
    setCurrentSampleNames(sortCountCurrentValues(updatedSampleNames));
    setCurrentInstruments(sortCountCurrentValues(updatedInstruments));
    setCurrentParametersDynamic(sortCountCurrentValues(updatedParameters));
  };

  const handleMethodChangeComparison = (e) => {
    setCurrentMethodComparison(e.target.value);
  };

  const handleSampleNameChangeComparison = (e) => {
    setCurrentSampleNameComparison(e.target.value);
  };

  const handleParameterChangeComparison = (e) => {
    setCurrentParameterComparison(e.target.value);
  };

  const mapMethodChildren = (value, updatedSampleNames, updatedInstruments, updatedParameters) => {
    const currentFilteredMethod = currentMethods.filter((method) => method.name === value);
    if (!instrumentBeforeSampleName) {
      currentFilteredMethod[0]?.sampleName?.forEach((listedSampleName) => {
        updatedSampleNames.push(listedSampleName);
        listedSampleName.instrument.forEach((instrument) => {
          updatedInstruments.push(instrument);
          instrument.parameter.forEach((parameter) => {
            updatedParameters.push(parameter);
          });
        });
      });
    } else {
      currentFilteredMethod[0]?.instrument?.forEach((listedInstrument) => {
        updatedInstruments.push(listedInstrument);
        listedInstrument.sampleName.forEach((sampleName) => {
          updatedSampleNames.push(sampleName);
          sampleName.parameter.forEach((parameter) => {
            updatedParameters.push(parameter);
          });
        });
      });
    }
  };

  const handleMethodChange = (e, fromUrl = false) => {
    setCurrentMethodComparison(e.target.value);
    setCurrentMethod(e.target.value);
    const updatedSampleNames = [];
    const updatedInstruments = [];
    const updatedParameters = [];
    mapMethodChildren(e.target.value, updatedSampleNames, updatedInstruments, updatedParameters);
    setCurrentSampleName(null);
    setCurrentInstrument(null);
    setCurrentParameter(null);
    setCurrentSampleNames(sortCountCurrentValues(updatedSampleNames));
    setCurrentInstruments(sortCountCurrentValues(updatedInstruments));
    setCurrentParametersDynamic(sortCountCurrentValues(updatedParameters));
  };

  const mapSampleNameChildren = (value, updatedInstruments, updatedParameters) => {
    const currentFilteredSampleName = currentSampleNames.filter(
      (sampleName) => sampleName.name === value
    );
    if (!instrumentBeforeSampleName) {
      currentFilteredSampleName[0]?.instrument?.forEach((instrument) => {
        updatedInstruments.push(instrument);
        instrument.parameter.forEach((parameter) => {
          updatedParameters.push(parameter);
        });
      });
    } else {
      currentFilteredSampleName[0]?.parameter?.forEach((parameter) => {
        updatedParameters.push(parameter);
      });
    }
  };

  const handleSampleNameChange = (e, fromUrl = false) => {
    setCurrentSampleNameComparison(e.target.value);
    setCurrentSampleName(e.target.value);
    const updatedInstruments = [];
    const updatedParameters = [];
    mapSampleNameChildren(e.target.value, updatedInstruments, updatedParameters);
    if (!instrumentBeforeSampleName) {
      setCurrentInstrument(null);
      setCurrentInstruments(sortCountCurrentValues(updatedInstruments));
    }
    setCurrentParameter(null);
    setCurrentParametersDynamic(sortCountCurrentValues(updatedParameters));
  };

  const mapInstrumentChildren = (value, updatedSampleNames, updatedParameters) => {
    const currentFilteredInstrument = currentInstruments.filter(
      (instrument) => instrument.name === value
    );
    if (instrumentBeforeSampleName) {
      currentFilteredInstrument[0]?.sampleName?.forEach((listedSampleName) => {
        updatedSampleNames.push(listedSampleName);
        listedSampleName.parameter.forEach((parameter) => {
          updatedParameters.push(parameter);
        });
      });
    } else {
      currentFilteredInstrument[0]?.parameter?.forEach((parameter) => {
        updatedParameters.push(parameter);
      });
    }
  };

  const handleInstrumentChange = (e, fromUrl = false) => {
    setCurrentInstrument(e.target.value);
    const updatedParameters = [];
    const updatedSampleNames = [];
    mapInstrumentChildren(e.target.value, updatedSampleNames, updatedParameters);
    if (instrumentBeforeSampleName) {
      setCurrentSampleName(null);
      setCurrentSampleNames(sortCountCurrentValues(updatedSampleNames));
    }
    setCurrentParameter(null);
    setCurrentParametersDynamic(sortCountCurrentValues(updatedParameters));
  };

  const handleParameterChange = (e, fromUrl = false) => {
    setCurrentParameterComparison(e.target.value);
    setCurrentParameter(e.target.value);
  };

  const handleCombinedZScoreParameterChange = (event) => {
    const { value } = event.target.selectedOptions[0];
    const exist = combinedZScoreData.find((item) => item.parameter === value);
    if (!exist) {
      setCombinedZScoreData((prevOptions) => [...prevOptions, { parameter: value }]);
    }
  };

  const handleDeleteParameter = (parameterToDelete) => {
    const { parameter } = parameterToDelete;
    setCombinedZScoreData((prevData) => prevData.filter((item) => item.parameter !== parameter));
  };

  const handleResultsTableChange = (e, index, propertyName) => {
    resultsDataTableData[index][propertyName] = e.currentTarget.value;
    if (propertyName === 'dateTime') {
      resultsDataTableData[index]['isEditedDatetime'] = true;
    }
    if (propertyName === 'sampleId') {
      resultsDataTableData[index]['isEditedSampleId'] = true;
    }
  };

  const mapControlStrategyToChartLines = (controlStrategy) => {
    const chartLinesToReturn = isNonUs ? defaultChartLinesNonUs : defaultChartLines;
    let updatedChartLines = [];
    switch (controlStrategy) {
      case 'EWMA':
        updatedChartLines = chartLinesToReturn.filter((line) => line.ewma);
        break;
      case 'Run rules':
        updatedChartLines = chartLinesToReturn.filter((line) => line.runRules);
        break;
      case 'Control limits':
        updatedChartLines = chartLinesToReturn.filter((line) => line.controlLimits);
        break;
      case 'Tolerance limits':
        updatedChartLines = chartLinesToReturn.filter((line) => line.toleranceLimits);
        break;
      default:
        break;
    }
    setChartLines(updatedChartLines);
  };

  useEffect(() => {
    mapControlStrategyToChartLines(currentControlStrategyConfigurations.controlStrategy);
  }, [currentControlStrategyConfigurations.controlStrategy, companyTypes]);

  const updateStateConfigurations = (cellValueIn, cellName) => {
    const cellValue = cellValueIn;
    const chartGroupConfigurationsToUpdate = currentChartGroupConfigurations;
    const controlStrategyConfigurationsToUpdate = currentControlStrategyConfigurations;

    switch (cellValue.variable) {
      // control strategy configurations
      case 'Pre-treat data':
        controlStrategyConfigurationsToUpdate.pretreatData = cellValue.value;
        break;
      case 'Control Strategy':
        cellValue.value = cellName;
        controlStrategyConfigurationsToUpdate.controlStrategy = cellValue.value;
        // reset default data for Control Strategy
        setDefaultControlStrategies(cellValue.value, controlStrategyConfigurationsToUpdate);
        break;
      case 'Dynamically updated I-chart':
        cellValue.value = cellName;
        controlStrategyConfigurationsToUpdate.duiChart = cellValue.value;
        break;
      case 'Chart axis significant digits':
        cellValue.value = parseInt(cellName, 10);
        controlStrategyConfigurationsToUpdate.chartSigDigits = cellValue.value;
        break;
      case 'Lower Tolerance Limit':
        if (cellName === 'Use Lower Tolerance Limit (1090)') {
          cellValue.value = currentSummary?.avg_minus_1090_delta || 'N/A';
          cellValue.approach = cellName;
          controlStrategyConfigurationsToUpdate.lowerToleranceLimitApproach = cellValue.approach;
        } else if (cellName === 'Default') {
          cellValue.approach = cellName;
          controlStrategyConfigurationsToUpdate.lowerToleranceLimitApproach = cellValue.approach;
          cellValue.value = 'N/A';
        } else {
          cellValue.value = cellName;
        }
        controlStrategyConfigurationsToUpdate.lowerToleranceLimit = cellValue.value;
        break;
      case 'Upper Tolerance Limit':
        if (cellName === 'Use Upper Tolerance Limit (1090)') {
          cellValue.value = currentSummary?.avg_plus_1090_delta || 'N/A';
          cellValue.approach = cellName;
          controlStrategyConfigurationsToUpdate.upperToleranceLimitApproach = cellValue.approach;
          controlStrategyConfigurationsToUpdate.upperToleranceLimit = cellValue.value;
        } else if (cellName === 'Default') {
          cellValue.approach = cellName;
          cellValue.value = 'N/A';
          controlStrategyConfigurationsToUpdate.upperToleranceLimitApproach = cellValue.approach;
          controlStrategyConfigurationsToUpdate.upperToleranceLimit = cellValue.value;
        } else {
          cellValue.value = cellName;
          controlStrategyConfigurationsToUpdate.upperToleranceLimit = cellValue.value;
        }
        break;
      case 'Mean':
        cellValue.value = cellName;
        controlStrategyConfigurationsToUpdate.mean = cellValue.value;
        break;
      case 'Standard Deviation':
        cellValue.value = cellName;
        controlStrategyConfigurationsToUpdate.standardDeviation = cellValue.value;
        break;
      case 'n':
        cellValue.value = cellName;
        controlStrategyConfigurationsToUpdate.n = cellValue.value;
        break;
      case 'Reproducibility':
        cellValue.value = cellName;
        controlStrategyConfigurationsToUpdate.reproducibility = cellValue.value;
        break;
      case 'Repeatability':
        cellValue.value = cellName;
        controlStrategyConfigurationsToUpdate.repeatability = cellValue.value;
        break;
      case 'EWMA Lambda':
        cellValue.value = cellName;
        controlStrategyConfigurationsToUpdate.ewmaLambda = cellValue.value;
        break;
      case 't alpha':
        cellValue.value = cellName;
        controlStrategyConfigurationsToUpdate.tAlpha = cellValue.value;
        break;
      case 'f alpha':
        cellValue.value = cellName;
        controlStrategyConfigurationsToUpdate.fAlpha = cellValue.value;
        break;
      case 'Control limit violation (3 sigma)':
        cellValue.value = cellName;
        controlStrategyConfigurationsToUpdate.controlLimitViolation = cellValue.value;
        break;
      case 'Warning limit violation (2 sigma)':
        cellValue.value = cellName;
        controlStrategyConfigurationsToUpdate.warningLimitViolation = cellValue.value;
        break;
      case '2 of 3 points > 2 sigma':
        cellValue.value = cellName;
        controlStrategyConfigurationsToUpdate.twoOfThree = cellValue.value;
        break;
      case '5 consecutive points > 1 sigma':
        cellValue.value = cellName;
        controlStrategyConfigurationsToUpdate.fiveConsecutive = cellValue.value;
        break;
      case '9 consecutive points above/below centerline':
        cellValue.value = cellName;
        controlStrategyConfigurationsToUpdate.nineConsecutive = cellValue.value;
        break;
      case '7 consecutive points increasing / decreasing':
        cellValue.value = cellName;
        controlStrategyConfigurationsToUpdate.sevenConsecutive = cellValue.value;
        break;
      case 'EWMA trend':
        cellValue.value = cellName;
        controlStrategyConfigurationsToUpdate.ewmaTrend = cellValue.value;
        break;
      case 'User defined control limit exceedance':
        cellValue.value = cellName;
        controlStrategyConfigurationsToUpdate.controlLimitExceedance = cellValue.value;
        break;
      case 'Tolerance limit exceedance':
        cellValue.value = cellName;
        controlStrategyConfigurationsToUpdate.toleranceLimitExceedance = cellValue.value;
        break;
      case 'Moving range limit violation (single point)':
        cellValue.value = cellName;
        controlStrategyConfigurationsToUpdate.singlePointViolation = cellValue.value;
        break;
      case 'Moving range limit violation (5 of 20 points)':
        cellValue.value = cellName;
        controlStrategyConfigurationsToUpdate.fivePointViolation = cellValue.value;
        break;
      case 'Anderson Darling exceeds exceeds 1.13':
        cellValue.value = cellName;
        controlStrategyConfigurationsToUpdate.andersonDarling = cellValue.value;
        break;
      case 'TPI exceeds 1.2':
        cellValue.value = cellName;
        controlStrategyConfigurationsToUpdate.tpiOnePointTwo = cellValue.value;
        break;
      case 'Suppress TPI notifications':
        cellValue.value = cellName;
        controlStrategyConfigurationsToUpdate.suppressTPI = cellValue.value;
        break;
      case 'Suppress Normality notifications':
        cellValue.value = cellName;
        controlStrategyConfigurationsToUpdate.suppressNormality = cellValue.value;
        break;
      // chart group configurations
      case 'Initial Assessment, samples (n)':
        cellValue.value = cellName;
        chartGroupConfigurationsToUpdate.initialAssessment = cellValue.value;
        break;
      case 'Ongoing assessment, samples (n)':
        cellValue.value = cellName;
        chartGroupConfigurationsToUpdate.ongoingAssessment = cellValue.value;
        break;
      case 'Ongoing assessment, days':
        cellValue.value = cellName;
        chartGroupConfigurationsToUpdate.ongoingAssessmentDays = cellValue.value;
        break;
      case 'Sample Type':
        cellValue.value = cellName;
        chartGroupConfigurationsToUpdate.sampleType = cellValue.value;
        break;
      case '1090 Material':
        cellValue.value = cellName;
        controlStrategyConfigurationsToUpdate.tenNinetyMaterial = cellValue.value;
        break;
      case 'Display method version':
        cellValue.value = cellName;
        chartGroupConfigurationsToUpdate.displayMethodVersion = cellValue.value;
        break;
      case 'Auto-create sample re-check':
        cellValue.value = cellName;
        chartGroupConfigurationsToUpdate.autoCreateSampleRecheck = cellValue.value;
        break;
      case 'Frequency':
        cellValue.value = cellName;
        chartGroupConfigurationsToUpdate.sampleScheduleFrequency = cellValue.value;
        break;
      case 'Starting on':
        cellValue.value = cellName;
        chartGroupConfigurationsToUpdate.sampleScheduleStartingOn = cellValue.value;
        break;
      case 'Time':
        cellValue.value = cellName;
        chartGroupConfigurationsToUpdate.sampleScheduleTime = cellValue.value;
        break;
      case 'Archive':
        cellValue.value = cellName;
        setArchiveStatus(cellValue.value);
        // controlStrategyConfigurationsToUpdate.archive = cellValue.value;
        break;
      case 'Control Status':
        cellValue.value = cellName;
        controlStrategyConfigurationsToUpdate.controlStatus = cellValue.value;
        break;
      default:
    }
    setCurrentChartGroupConfigurations(chartGroupConfigurationsToUpdate);
    setCurrentControlStrategyConfigurations(controlStrategyConfigurationsToUpdate);
    // reset data for table value control strategy change
    if (cellValue.variable == 'Control Strategy') {
      setControlStrategyData(generateControlStrategyData(controlStrategyConfigurationsToUpdate));
    }
  };

  const buildSummaryStatisticsDataSet = () => {
    const updatedSummaryStatisticsAssessmentData = [];
    // let columns = renderAssessmentTableHeader(resultsDataTableData);
    const average = { statistic: 'Average' };
    const sdRms = { statistic: 'Standard Deviation, RMS' };
    const sdMr = { statistic: 'Standard Deviation, MR' };
    const sampleSize = { statistic: 'Sample Size (n)' };
    const outliers = { statistic: 'Number of outliers' };
    const runsOmitted = { statistic: 'Runs Omitted' };
    const repeatability = { statistic: 'Repeatability (r)' };
    const reproducibility = { statistic: 'Reproducibility (R)' };
    const sitePrecision = { statistic: 'Site Precision' };
    const precisionRatio = { statistic: 'Precision Ratio' };
    const tpi = { statistic: 'TPI' };
    const tpiAssessment = { statistic: 'TPI Assessment' };
    const andersonDarling = { statistic: 'Anderson Darling' };
    const normality = { statistic: 'Normality Assessment' };
    const fTestVarianceRatio = { statistic: 'F-test variance ratio' };
    const fStatistic = { statistic: 'F statistic' };
    const fEval = { statistic: 'F test evaluation' };
    currentSummariesShortKey.forEach((summary) => {
      average[summary.parameter] = renderSigDigits(summary.avg_sd);
      sdRms[summary.parameter] = renderSigDigits(summary.sd_rms_sd);
      sdMr[summary.parameter] = renderSigDigits(summary.sd_mr_sd);
      sampleSize[summary.parameter] = renderSigDigits(summary.n_sd);
      outliers[summary.parameter] = renderSigDigits(summary.outliers_gesd);
      runsOmitted[summary.parameter] = renderSigDigits(summary.omits_sd);
      repeatability[summary.parameter] = renderSigDigits(summary.repeat_sd);
      reproducibility[summary.parameter] = renderSigDigits(summary.repro_sd);
      sitePrecision[summary.parameter] = renderSigDigits(summary.sp_sd);
      precisionRatio[summary.parameter] = renderSigDigits(summary.pr_sd);
      tpi[summary.parameter] = renderSigDigits(summary.tpi_sd);
      tpiAssessment[summary.parameter] = summary.tpi_eval_sd;
      andersonDarling[summary.parameter] = renderSigDigits(summary.ad_mr_sd);
      normality[summary.parameter] = summary.ad_eval_sd;
      fTestVarianceRatio[summary.parameter] = renderSigDigits(summary.f_vr_ud);
      fStatistic[summary.parameter] = renderSigDigits(summary.f_statistic_ud);
      fEval[summary.parameter] = summary.f_eval_ud;
    });
    updatedSummaryStatisticsAssessmentData.push(average);
    updatedSummaryStatisticsAssessmentData.push(sdRms);
    updatedSummaryStatisticsAssessmentData.push(sdMr);
    updatedSummaryStatisticsAssessmentData.push(sampleSize);
    updatedSummaryStatisticsAssessmentData.push(outliers);
    updatedSummaryStatisticsAssessmentData.push(runsOmitted);
    updatedSummaryStatisticsAssessmentData.push(repeatability);
    updatedSummaryStatisticsAssessmentData.push(reproducibility);
    updatedSummaryStatisticsAssessmentData.push(sitePrecision);
    updatedSummaryStatisticsAssessmentData.push(precisionRatio);
    updatedSummaryStatisticsAssessmentData.push(tpi);
    updatedSummaryStatisticsAssessmentData.push(tpiAssessment);
    updatedSummaryStatisticsAssessmentData.push(andersonDarling);
    updatedSummaryStatisticsAssessmentData.push(normality);
    updatedSummaryStatisticsAssessmentData.push(fTestVarianceRatio);
    updatedSummaryStatisticsAssessmentData.push(fStatistic);
    updatedSummaryStatisticsAssessmentData.push(fEval);
    setSummaryStatisticsAssessmentData(updatedSummaryStatisticsAssessmentData);
  };

  useEffect(() => {
    if (currentFullKey && Array.isArray(summaries)) {
      const currentSummaryObject = summaries.find(
        (summaryObject) => summaryObject.id === firebaseClean(currentFullKey)
      );
      setCurrentSummary(currentSummaryObject);
      const currentShortKeyClean = firebaseClean(currentShortKey);
      // query summaries to find summary with ID that matches fullKey
      const summariesShortKey = summaries.filter((summaryObject) =>
        summaryObject.id.includes(currentShortKeyClean)
      );
      setCurrentSummariesShortKey(summariesShortKey);
      if (currentSummariesShortKey.length > 0) {
        buildSummaryStatisticsDataSet();
      }
    }
  }, [
    currentFullKey,
    assessments,
    summaries,
    items,
    currentChartGroupConfigurations.chartSigDigits
  ]);
  useEffect(() => {
    if (Array.isArray(setupGroups) && setupGroups.length > 0) {
      setupGroups.forEach((groupIn) => {
        const group = groupIn;
        const locationsToDisplay = [];
        group.locations.forEach((location) => {
          locationsToDisplay.push(location.name);
        });
        group.locationsDisplayed = locationsToDisplay.toString().replaceAll(',', ', ');
        group.personnelNumber = group.personnel.length;
        group.instrumentsNumber = group.instruments.length;
        group.instrumentsAssigned = [];
        if (Array.isArray(currentInstruments) && currentInstruments.length > 0) {
          group.instruments.forEach((instrumentName) => {
            const existingInstrument = currentInstruments.find(
              (instrument) => instrument.name === instrumentName
            );
            if (existingInstrument) {
              group.instrumentsAssigned.push(existingInstrument);
            }
          });
        }
      });
      setCurrentSetupGroups(setupGroups);
    }
  }, [setupGroups && currentInstruments && currentPersonnel, setupGroups]);

  useEffect(() => {
    const updatedChartData = [...chartData];
    updatedChartData.forEach((dataPointIn) => {
      const dataPoint = dataPointIn;
      dataPoint.userDefinedUTL = currentControlStrategyConfigurations.upperToleranceLimit;
      dataPoint.userDefinedLTL = currentControlStrategyConfigurations.lowerToleranceLimit;
      dataPoint.userDefinedUCL = currentControlStrategyConfigurations.upperControlLimit;
      dataPoint.userDefinedLCL = currentControlStrategyConfigurations.lowerControlLimit;
    });
    setChartData(updatedChartData);
  }, [
    currentControlStrategyConfigurations.lowerToleranceLimit,
    currentControlStrategyConfigurations.upperToleranceLimit,
    currentControlStrategyConfigurations.lowerControlLimit,
    currentControlStrategyConfigurations.upperControlLimit
  ]);

  useEffect(() => {
    setCurrentShortKey(
      [currentLocation, currentMethod, currentSampleName, currentInstrument].join('|')
    );
    setCurrentFullKey(
      [currentLocation, currentMethod, currentSampleName, currentInstrument, currentParameter].join(
        '|'
      )
    );
    if (currentParameter !== null) {
      setLocalSearchFinished(true);
      if (Array.isArray(currentParameters)) {
        const currentDate = moment()
          .tz(tz || 'UTC')
          .format('YYYY/MM/DD');
        const currentTime = moment()
          .tz(tz || 'UTC')
          .format(timeFormat);
        const emailBeforeTheAt = auth.user.email.split('@')[0];
        const currentDateTime = moment(Date.now())
          .tz(tz || 'UTC')
          .format(dateTimeFormat);
        const matchingUser = currentPersonnel.find(
          (person) => person.email.toLowerCase() === auth.user.email.toLowerCase()
        );
        let rowIndex = 0;
        const updatedResultsDataTableData = [];
        let setupData = '0';
        if (currentNValue < 20 && currentNValue > 0) {
          setupData = '1';
        }
        const formatSample = `SQC${currentDate.replace(/\//g, '')}`;
        setDefaultSampleId(formatSample);

        const paramsOrdered =
          orderOfParameters && orderOfParameters[0] && orderOfParameters[0][currentShortKey]
            ? orderOfParameters[0][currentShortKey]
            : {};
        // eslint-disable-next-line no-restricted-syntax
        for (const parameter of currentParameters) {
          const data = {
            key: rowIndex,
            date: currentDate,
            time: currentTime,
            dateTime: currentDateTime,
            isEditedDatetime: false,
            parameter,
            personnel: matchingUser ? matchingUser.name : emailBeforeTheAt,
            sampleId: formatSample,
            isEditedSampleId: false,
            setupData,
            notes: '',
            result: '',
            order: paramsOrdered[parameter] || 0
          };
          if (
            parameter !== defaultCombinedParameterName &&
            ((!archiveRegex.test(parameter) && radioArchiveValue === radioDataType.ACTIVE) ||
              (archiveRegex.test(parameter) && radioArchiveValue === radioDataType.ARCHIVE))
          ) {
            updatedResultsDataTableData.push(data);
            rowIndex += 1;
          }
        }

        setResultsDataTableData(updatedResultsDataTableData.sort((a, b) => a.order - b.order));
      }
    }
  }, [currentLocation, currentMethod, currentSampleName, currentInstrument, currentParameter]);

  useEffect(() => {
    if (currentParameter && Array.isArray(methods) && methods.length > 0) {
      setUnit('');
      methods.forEach((item) => {
        if (item.parameter === currentParameter && item.method === currentMethod) {
          setUnit(item.units);
        }
      });
    }
  }, [currentParameter, methods]);

  const renderTimezoneOptions = () => {
    const optionsToReturn = [];
    timezones.forEach((timezone) => {
      optionsToReturn.push(<option value={timezone}>{timezone}</option>);
    });
    return optionsToReturn;
  };

  const renderTimesOptions = () => {
    const timesOptionsToReturn = [];
    currentModalAvailableTimes.forEach((time) => {
      timesOptionsToReturn.push(<option value={time.time}>{time.label}</option>);
    });
    return timesOptionsToReturn;
  };

  useEffect(() => {
    buildSummaryStatisticsDataSet();
  }, [setCurrentChartGroupConfigurations.chartSigDigits]);

  useEffect(() => {
    if (Array.isArray(results) && results.length > 0) {
      const updatedResults = [];
      results.forEach((updatedResultIn) => {
        const updatedResult = updatedResultIn;
        updatedResult.resultOmits7651 = updatedResult.resultOmits;
        updatedResult.resultOmits301 = updatedResult.resultOmits;
        updatedResult.resultOmits3026 = updatedResult.resultOmits;
        updatedResult.resultOmits330 = updatedResult.resultOmits;
        if (updatedResult.instrument !== '426') {
          delete updatedResult.resultOmits7651;
        }
        if (updatedResult.instrument !== '301') {
          delete updatedResult.resultOmits301;
        }
        if (updatedResult.instrument !== '3026') {
          delete updatedResult.resultOmits3026;
        }
        if (updatedResult.instrument !== '330') {
          delete updatedResult.resultOmits330;
        }
      });
      results = updatedResults;
    }
  }, [results]);

  const configurationData = [
    {
      id: 1,
      variable: 'Pre-treat data',
      value: currentControlStrategyConfigurations.pretreatData
    },
    {
      id: 2,
      variable: 'Control Strategy',
      value: currentControlStrategyConfigurations.controlStrategy
    },
    {
      id: 3,
      variable: 'Dynamically updated I-chart',
      value: currentControlStrategyConfigurations.duiChart
    },
    {
      id: 4,
      variable: 'Chart axis significant digits',
      value: currentControlStrategyConfigurations.chartSigDigits
    }
  ];

  const configurationTableColumns = (editable = true) => [
    {
      field: 'variable',
      headerName: 'Variable',
      editable: false,
      flex: 2,
      sortable: false
    },
    {
      field: 'value',
      headerName: 'Value',
      editable: editable,
      flex: 1,
      sortable: false,
      type: 'singleSelect',
      valueOptions: (params) => {
        const yesNo = ['No', 'Yes'];
        const controlStrategy = ['EWMA', 'Run rules', 'Control limits', 'Tolerance limits'];
        const sigDigitOptions = [2, 3, 4, 5, 6, 7];
        switch (params.row.variable) {
          case 'Pre-treat data':
            return yesNo;
          case 'Control Strategy':
            return controlStrategy;
          case 'Dynamically updated I-chart':
            return yesNo;
          case 'Chart axis significant digits':
            return sigDigitOptions;
          default:
            return [];
        }
      }
    }
  ];
  ``;

  const runData = async () => {
    try {
      setLoadingRunData(true);
      // runData
      if (
        !currentLocation ||
        !currentMethod ||
        !currentInstrument ||
        !currentSampleName ||
        !currentParameter
      ) {
        // eslint-disable-next-line no-alert
        alert('Need to select location, method, sample name, instrument, and parameter');
      } else {
        const runDataObject = {
          location: currentLocation,
          method: currentMethod,
          instrument: currentInstrument,
          sampleName: currentSampleName,
          parameter: currentParameter
        };

        await runDataFunction(runDataObject);
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoadingRunData(false);
    }
  };

  const saveConfigurations = async () => {
    try {
      currentControlStrategyConfigurations.archive = archiveStatus;
      setChartLoading(true);
      updateConfiguration(currentChartGroupConfigurations.id, currentChartGroupConfigurations);
      updateConfiguration(
        currentControlStrategyConfigurations.id,
        currentControlStrategyConfigurations
      );
      const tenNinetyDateRequestObject = {
        startDate: moment(tenNinetyStartDate).tz(tz).format('M/D/YYYY'),
        endDate: moment(tenNinetyEndDate).tz(tz).format('M/D/YYYY')
      };
      if (
        tenNinetyDateRequestObject.startDate !== 'Invalid date' &&
        tenNinetyDateRequestObject.endDate !== 'Invalid date'
      ) {
        await updateTenNinetyDatesFunction(tenNinetyDateRequestObject);
      }

      if (currentControlStrategyConfigurations.archive === 'Yes') {
        const response = await archiveDataFunction({
          shortKey: currentShortKey,
          fullKey: currentFullKey,
          parameter: currentParameter
        });
        const [
          archivedLocation,
          archivedMethod,
          archivedSampleName,
          archivedInstrument,
          archivedParameter
        ] = response.data[0].split('|');
        const runDataArchiveObject = {
          archivedLocation,
          archivedMethod,
          archivedInstrument,
          archivedSampleName,
          archivedParameter
        };
        await runArchiveDataFunction(runDataArchiveObject);
        setChartLoading(false);
        window.location.reload();
      } else {
        // eslint-disable-next-line no-lonely-if
        if (!currentLocation || !currentMethod || !currentInstrument || !currentSampleName) {
          // eslint-disable-next-line no-alert
          alert('Need to select location, method, sample name, and instrument');
        } else {
          const runDataObject = {
            location: currentLocation,
            method: currentMethod,
            instrument: currentInstrument,
            sampleName: currentSampleName,
            parameter: currentParameter
          };
          await runDataFunction(runDataObject);
          setChartLoading(false);
        }
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setChartLoading(false);
    }
  };

  const [hasActiveEdits, setHasActiveEdits] = useState(false);

  const editData = () => {
    if (!currentLocation || !currentMethod || !currentInstrument || !currentSampleName) {
      // eslint-disable-next-line no-alert
      alert('Need to select location, method, sample name, and instrument');
    } else {
      const editedRows = rowsToUpdate;
      // we had a bug, found in BlueTide, where some values in this array
      // were causing the serialization of the object to be sent over the wire to fail with an NaN error
      // so we just send the ones in the table
      let columns;
      if (isNonUs) {
        columns = [
          { field: 'id' }, // need to add id to make sure id goes
          ...defaultColumnsEdit(currentPersonnelDropdown, dateTimeFormat, tz),
          ...defaultColumnsEditUsExtend(true)
        ];
      } else {
        columns = [
          { field: 'id' }, // need to add id to make sure id goes
          ...defaultColumnsEdit(currentPersonnelDropdown, dateTimeFormat, tz),
          ...defaultColumnsEditExtend(true)
        ];
      }
      const columnFields = columns.map((column) => column.field);

      for (let i = 0; i < editedRows.length; i++) {
        for (const key in editedRows[i]) {
          if (!columnFields.includes(key)) {
            delete editedRows[i][key];
          } else if (['---', 'N/A'].includes(editedRows[i][key])) {
            delete editedRows[i][key];
          }
        }
        if (editedRows[i].sampleId) {
          editedRows[i].sampleId = String(editedRows[i].sampleId).trim();
        }
      }

      const editDataObject = {
        location: currentLocation,
        method: currentMethod,
        instrument: currentInstrument,
        rows: editedRows
      };
      // firebase editData function
      editDataFunction(editDataObject).then(() => {
        //after update success clear data
        setRowsToUpdate([]);
        runData();
      });
    }
  };

  function saveEditModal() {
    editData();
    setShowEditModal(false);
  }

  const updateEditingMode = (hasEdits) => {
    // pass whether we are in editing mode to state
    setHasActiveEdits(hasEdits);
  };

  const returnLocationsAssigned = (locations, setupGroupsIn) => {
    const setupGroupsAssigned = [];
    const locationsAssigned = [];
    const locationsToReturn = [];
    setupGroupsIn.forEach((setupGroup) => {
      const matchingUser = setupGroup.personnel.find(
        (person) => person.email?.toLowerCase() === auth.user.email?.toLowerCase()
      );
      if (matchingUser) {
        setupGroupsAssigned.push(setupGroup);
      }
    });
    setupGroupsAssigned.forEach((assignedSetupGroup) => {
      assignedSetupGroup.locations.forEach((location) => {
        locationsAssigned.push(location.value);
      });
    });
    locations.forEach((location) => {
      const foundLocation = locationsAssigned.find(
        (assignedLocation) => assignedLocation === location.name
      );
      if (foundLocation) {
        locationsToReturn.push(location);
      }
    });
    return locationsToReturn;
  };

  const mapDropdownsFromIndex = (amendedLocations) => {
    const updatedLocations = [];
    let updatedMethods = [];
    let updatedSampleNames = [];
    let updatedInstruments = [];
    let updatedParameters = [];
    amendedLocations.forEach((location) => {
      updatedLocations.push(location);
      location.method.forEach((method) => {
        updatedMethods.push(method);
        if (!instrumentBeforeSampleName) {
          method.sampleName?.forEach((listedSampleName) => {
            updatedSampleNames.push(listedSampleName);
            listedSampleName.instrument.forEach((instrument) => {
              updatedInstruments.push(instrument);
              instrument.parameter.forEach((parameter) => {
                updatedParameters.push(parameter);
              });
            });
          });
        } else {
          method.instrument?.forEach((listedInstrument) => {
            updatedInstruments.push(listedInstrument);
            listedInstrument.sampleName.forEach((sampleName) => {
              updatedSampleNames.push(sampleName);
              sampleName.parameter.forEach((parameter) => {
                updatedParameters.push(parameter);
              });
            });
          });
        }
      });
    });
    if (currentLocation) {
      mapLocationChildren(
        currentLocation,
        (updatedMethods = []),
        (updatedSampleNames = []),
        (updatedInstruments = []),
        (updatedParameters = [])
      );
    }
    if (currentMethod) {
      mapMethodChildren(
        currentMethod,
        (updatedSampleNames = []),
        (updatedInstruments = []),
        (updatedParameters = [])
      );
    }
    if (currentSampleName) {
      mapSampleNameChildren(
        currentSampleName,
        !instrumentBeforeSampleName ? (updatedInstruments = []) : [],
        (updatedParameters = [])
      );
    }
    if (currentInstrument) {
      mapInstrumentChildren(
        currentInstrument,
        instrumentBeforeSampleName ? (updatedSampleNames = []) : [],
        (updatedParameters = [])
      );
    }
    setCurrentLocations(updatedLocations);
    setAllLocations(updatedLocations);
    setCurrentMethods(sortCountCurrentValues(updatedMethods));
    setCurrentSampleNames(sortCountCurrentValues(updatedSampleNames));
    setCurrentInstruments(sortCountCurrentValues(updatedInstruments));
    generateValueForParametersDynamic(sortCountCurrentValues(updatedParameters));
  };

  const generateLocationsForUser = (indexesIn, setupGroupsIn) => {
    const amendedLocations = returnLocationsAssigned(indexesIn[0].index, setupGroupsIn);
    mapDropdownsFromIndex(amendedLocations);
  };

  useEffect(() => {
    if (Array.isArray(currentIndexes) && currentIndexes.length > 0) {
      generateLocationsForUser(currentIndexes, currentSetupGroups);
    }
  }, [currentIndexes, currentSetupGroups]);

  useEffect(() => {
    if (Array.isArray(indexes) && indexes.length > 0 && !methodsUpdated) {
      const activeIndexes = indexes.filter((index) => !index.id.includes('Archive'));
      const archiveIndexes = indexes.filter((index) => index.id.includes('Archive'));
      setCurrentIndexes(radioArchiveValue === 'Active' ? activeIndexes : archiveIndexes);
      setCurrentActiveIndexes(activeIndexes);
      setCurrentArchiveIndexes(archiveIndexes);
    }
  }, [indexes]);

  useEffect(() => {
    (async () => {
      const result = await useGetIndexValues();
      setIndexes(result.data);
    })();
  }, []);

  useEffect(() => {
    setInstrumentBeforeSampleName(
      (companyTypes && companyTypes[0] && companyTypes[0]['orderTypeInstrumentBefore']) || false
    );
  }, [companyTypes]);

  useEffect(() => {
    if (Array.isArray(samples) && samples.length > 0) {
      const currentSamplesToUpdate = currentSamples;
      samples.forEach((sample) => {
        const entryToAdd = {
          value: sample.id,
          label: sample.name
        };
        currentSamplesToUpdate.push(entryToAdd);
      });
      setCurrentSamples(currentSamplesToUpdate);
    }
  }, [currentSamples, samples]);

  useEffect(() => {
    const shortKeyComplete =
      currentLocation && currentMethod && currentSampleName && currentInstrument;
    if (
      Array.isArray(chartGroupConfigurations) &&
      chartGroupConfigurations.length > 0 &&
      shortKeyComplete
    ) {
      chartGroupConfigurations.forEach((configuration) => {
        if (configuration.type === 'Chart Group') {
          setCurrentChartGroupConfigurations(configuration);
        }
      });
    } else if (Array.isArray(chartGroupConfigurations) && chartGroupConfigurations.length === 0) {
      defaultChartGroupConfigurations.shortKey = currentShortKey;
      createConfiguration(defaultChartGroupConfigurations);
    }
  }, [chartGroupConfigurations, currentShortKey, currentChartGroupConfigurations]);

  useEffect(() => {
    if (Array.isArray(controlStrategyConfigurations) && controlStrategyConfigurations.length > 0) {
      if (currentSummary) {
        const updatedCurrentControlStrategyConfigurations = controlStrategyConfigurations.sort(
          (a, b) => b.archive?.localeCompare(a.archive)
        )[0];
        setCurrentControlStrategyConfigurations(updatedCurrentControlStrategyConfigurations);
        setControlStrategyData(
          generateControlStrategyData(updatedCurrentControlStrategyConfigurations)
        );
      }
    } else if (
      Array.isArray(controlStrategyConfigurations) &&
      controlStrategyConfigurations.length === 0 &&
      !archiveRegex.test(currentFullKey)
    ) {
      defaultControlStrategyConfigurations.fullKey = currentFullKey;
      createConfiguration(defaultControlStrategyConfigurations);
    }
  }, [controlStrategyConfigurations]);

  useEffect(() => {
    setArchiveData([
      {
        id: randomId(),
        variable: 'Archive',
        value: currentControlStrategyConfigurations.archive
      }
    ]);

    setControlStatusData([
      {
        id: randomId(),
        variable: 'Control Status',
        value: currentControlStrategyConfigurations.controlStatus ?? 'Yes'
      }
    ]);
  }, [currentControlStrategyConfigurations]);

  function generateNValue(fromItems) {
    const myCurrentParameters = uniq(fromItems.map((i) => i.parameter)).sort();
    const newParametersArray = myCurrentParameters.map((param) => {
      let currentSetupDataCount = 0;
      fromItems.filter((i) => {
        if (i.parameter === param && String(i.setupData) === '1') {
          currentSetupDataCount += 1;
        }
        return true;
      });
      return currentSetupDataCount;
    });

    setCurrentNValue(newParametersArray[0]);
  }

  useEffect(() => {
    if (Array.isArray(shortKeyItems) && shortKeyItems.length > 0) {
      if (isNonUs) {
        const sortedParameters = artecoCustomParameterSort(
          uniq(
            shortKeyItems
              .filter((item) => item.parameter !== defaultCombinedParameterName)
              .map((i) => i.parameter)
          ).sort()
        );
        setCurrentParameters(sortedParameters);
      } else {
        setCurrentParameters(
          uniq(
            shortKeyItems
              .filter((item) => item.parameter !== defaultCombinedParameterName)
              .map((i) => i.parameter)
          ).sort()
        );
      }
      generateNValue(shortKeyItems);
      const timeZone = tz || 'UTC';
      // check for ooc and add _r to sample ID
      setFilteredShortKeyData(
        shortKeyItems
          .map((cd, index) => {
            const wFlags = wFlagColumns.filter((f) => !!cd[f]).map((f) => cd[f]);
            const oocFlags = oocFlagColumns.filter((f) => !!cd[f]).map((f) => cd[f]);
            const row = {
              index,
              flags: `${wFlags.length > 0 ? `Warnings: ${wFlags.join(', ')}` : ''}${
                wFlags.length > 0 && oocFlags.length > 0 ? '; ' : ''
              }${oocFlags.length > 0 ? `OOC: ${oocFlags.join(', ')}` : ''}`
            };

            // eslint-disable-next-line no-restricted-syntax
            for (const elt of Object.keys(cd)) {
              if (['date'].includes(elt)) {
                row[elt] = cd[elt];
              } else if (['objectID'].includes(elt)) {
                row[elt] = cd[elt];
              } else if (
                String(cd[elt]).match(/[A-Z]/i) ||
                ['instrument', 'parameter', 'location', 'sampleName', 'method'].includes(elt)
              ) {
                row[elt] = cd[elt];
              } else if (!Number.isNaN(parseFloat(cd[elt]))) {
                row[elt] = Number(cd[elt]); // 3 decimal places max
              } else {
                row[elt] = cd[elt];
              }
            }
            if (row.dateTime) {
              const theDate = new Date(row.dateTime);
              const config = getConfiguration(window.location.hostname);
              row.date = formatInTimeZone(theDate, timeZone, 'M/d/yyyy');
              row.time = formatInTimeZone(
                theDate,
                timeZone,
                config.timeIn24HourFormat ? 'HH:mm' : 'h:mm aaa'
              );
            }
            return row;
          })
          .sort((a, b) => new Date(a.dateTime).getTime() - new Date(b.dateTime).getTime())
      );
    }
  }, [shortKeyItems, companyTypes]);

  useEffect(() => {
    if (Array.isArray(items) && items.length > 0) {
      setChartHits(items);
      setChartHitCount(items?.length || 0);
    } else {
      setChartHits([]);
    }
  }, [items]);

  useEffect(() => {
    if (!currentParameter) {
      setChartHits([]);
      setChartData([]);
      setLocalSearchFinished(false);
      setIsSelectCombinedZScore(false);
    }
  }, [currentParameter]);

  useEffect(() => {
    if (Array.isArray(personnel) && personnel.length > 0) {
      const matchingUser = personnel.find((person) => person.email === auth.user.email);
      if (matchingUser && auth?.permissionData) {
        setPermission(matchingUser.permission);
        setPermissionData(auth.permissionData[matchingUser.permission]);
      }
      setCurrentPersonnel(personnel);
      const personnelDropdown = personnel.map((person) => {
        const personToReturn = {
          id: person.id,
          value: person.name,
          label: person.name
        };

        return personToReturn;
      });
      setCurrentPersonnelDropdown(personnelDropdown);
    }
  }, [personnel]);

  const renderButton = (buttonText, currentFunction, table, rule, hasLoading, isLoading) => {
    if (currentControlStrategyConfigurations.archive !== 'Yes') {
      return renderButtonWithPermissions(
        buttonText,
        currentFunction,
        table,
        rule,
        permissionData,
        hasLoading,
        isLoading
      );
    }
  };

  useEffect(() => {
    if (Array.isArray(assessments)) {
      if (radioArchiveValue === 'Active') {
        assessments = assessments.filter((assessment) => !Object.hasOwn(assessment, 'archive'));
      } else if (radioArchiveValue === 'Archive') {
        assessments = assessments.filter(
          (assessment) => Object.hasOwn(assessment, 'archive') && assessment.archive === true
        );
      }
      for (const assessment of assessments) {
        if (assessment.createdAt) {
          assessment.created = assessment.createdAt.toDate().toDateString();
        }
      }

      // check existing assessments in DB
      // if zero exist, check configuration (initial assessment n value) and then compare current n value to see if initial assessment due
      if (assessments.length === 0) {
        // check configuration value in DB for n value of when initial assessment needs to be completed.
        const initialAssessmentConfigValue = parseInt(
          currentChartGroupConfigurations.initialAssessment,
          10
        );
        if (currentSetupDataOne >= initialAssessmentConfigValue) {
          // initial assessment due
          setChartStage('Needs Initial Assessment');
          setChartButtonText('Perform Initial Assessment');
        } else {
          // chart stage in development
          setChartStage('In Development');
        }
      } else if (assessments.length > 0) {
        // check for initial assessment with outcome of pass
        const periodicAssessmentConfigValue = parseInt(
          currentChartGroupConfigurations.ongoingAssessment,
          10
        );
        const initialAssessmentOutcomePass = assessments.find(
          (assessment) => assessment.type === 'Initial' && assessment.outcome === 'Pass'
        );
        if (initialAssessmentOutcomePass) {
          if (currentSetupDataZero >= periodicAssessmentConfigValue) {
            // first periodic assessment due
            setChartStage('Needs Periodic Assessment');
            setChartButtonText('Perform Periodic Assessment');
            const periodicAssessments = assessments.filter(
              (assessment) =>
                assessment.type === 'Periodic' && assessment.fullKey === currentFullKey
            );
            if (periodicAssessments.length > 0) {
              periodicAssessments.sort((x, y) => x.createdAt - y.createdAt);
              const mostRecentPeriodicAssessment =
                periodicAssessments[periodicAssessments.length - 1];
              if (mostRecentPeriodicAssessment.outcome === 'Pass') {
                if (
                  currentSetupDataOne + currentSetupDataZero - mostRecentPeriodicAssessment.n <
                  periodicAssessmentConfigValue
                ) {
                  // most recent periodic assessment complete and passed, but not ready for next periodic assessment yet;
                  setChartStage('Active');
                  setChartButtonText('View Completed Assessment');
                } else if (
                  currentSetupDataOne + currentSetupDataZero - mostRecentPeriodicAssessment.n >=
                  periodicAssessmentConfigValue
                ) {
                  // periodic assessment due
                  setChartStage('Needs Periodic Assessment');
                  setChartButtonText('Perform Periodic Assessment');
                }
              } else {
                // periodic assessment stil due - no pass on most recent periodic assessment outcome
                setChartStage('Needs Periodic Assessment');
                setChartButtonText('Perform Periodic Assessment');
              }
            } else {
              // periodic assessment due
              setChartStage('Needs Periodic Assessment');
              setChartButtonText('Perform Periodic Assessment');
            }
          } else {
            // initial assessment complete, but not ready for periodic assessment yet;
            setChartStage('Active');
            setChartButtonText('View Completed Assessment');
          }
        } else {
          // initial assessment still due - no pass on initial assessment outcome
          setChartStage('Needs Initial Assessment');
          setChartButtonText('Perform Initial Assessment');
        }
      }
      assessments.sort((x, y) => x.createdAt - y.createdAt);
      setCurrentAssessments(assessments);
      if (assessments.length > 0) {
        setChartAssessed(true);
      } else {
        setChartAssessed(false);
      }
      if (Array.isArray(currentResultsData) && currentResultsData.length > 0) {
        const updatedCurrentResultsData = currentResultsData;
        for (const result of updatedCurrentResultsData) {
          result.setupData = '0';
        }
        setCurrentResultsData(updatedCurrentResultsData);
      }
    }
  }, [assessments, currentChartGroupConfigurations, currentSetupDataOne, currentSetupDataZero]);

  async function saveChartAssessment() {
    setShowModal(false);
    setShowPeriodicModal(false);
    setChartAssessed(true);
    setChartLoading(true);
    const assessmentToSave = { ...modalAssessment };
    if (assessmentToSave.type === 'Initial') {
      assessmentToSave.summaryStatistics = summaryStatisticsAssessmentData;
    } else if (assessmentToSave.type === 'Periodic') {
      assessmentToSave.summaryStatistics = summaryStatisticsDataPeriodic;
    }
    createAssessment(assessmentToSave);
    const updatedCurrentSummary = currentSummary;
    updatedCurrentSummary.assessed = true;
    updateSummary(currentFullKey, updatedCurrentSummary);
    if (assessmentToSave.type === 'Periodic') {
      if (assessmentToSave.pool === 'true') {
        poolData();
      }
      if (assessmentToSave.archive === 'Yes') {
        const response = await periodicAssessmentArchiveDataFunction({
          shortKey: currentShortKey,
          fullKey: currentFullKey,
          inputParameter: currentParameter
        });
        const [location, method, sampleName, instrument, parameter] = response.data[1].split('|');
        const runDataObject = { location, method, instrument, sampleName, parameter };
        const [
          archivedLocation,
          archivedMethod,
          archivedSampleName,
          archivedInstrument,
          archivedParameter
        ] = response.data[0].split('|');
        const runDataArchiveObject = {
          archivedLocation,
          archivedMethod,
          archivedInstrument,
          archivedSampleName,
          archivedParameter
        };
        await runDataFunction(runDataObject);
        await runArchiveDataFunction(runDataArchiveObject).then(() => {
          window.location.reload();
        });
      }
    }
    setChartLoading(false);
  }

  const assignedSamplesData = [
    {
      id: randomId(),
      sample: 'None'
    },
    {
      id: randomId(),
      sample: 'None'
    },
    {
      id: randomId(),
      sample: 'None'
    }
  ];

  const archiveColumns = (editable = true) => [
    {
      field: 'variable',
      headerName: 'Variable',
      sortable: false,
      editable: false,
      flex: 2
    },
    {
      field: 'value',
      headerName: 'Value',
      editable: editable,
      sortable: false,
      flex: 1,
      type: 'singleSelect',
      valueOptions: () => ['No', 'Yes']
    }
  ];

  const controlStatusColumns = (editable = true) => [
    {
      field: 'variable',
      headerName: 'Variable',
      sortable: false,
      editable: false,
      flex: 2
    },
    {
      field: 'value',
      headerName: 'Value',
      editable: editable,
      sortable: false,
      flex: 1,
      type: 'singleSelect',
      valueOptions: ['No', 'Yes']
    }
  ];

  const deleteAllDataColumns = (editable = true) => [
    {
      field: 'variable',
      headerName: 'Variable',
      sortable: false,
      editable: false,
      flex: 2
    },
    {
      field: 'value',
      headerName: 'Value',
      editable: editable,
      sortable: false,
      flex: 1,
      type: 'singleSelect',
      valueOptions: () => ['No', 'Yes']
    }
  ];

  const renderAssessmentTableHeader = (tableData) => {
    const columnHeaders = [
      {
        field: 'statistic',
        headerName: 'Statistic',
        flex: 1,
        sortable: false
      }
    ];
    tableData.forEach((result) => {
      const fieldName =
        radioArchiveValue === radioDataType.ARCHIVE
          ? result.parameter.split('_')[0]
          : result.parameter;
      const columnToPush = {
        field: firebaseClean(fieldName),
        headerName: result.parameter,
        flex: 1,
        sortable: false
      };
      columnHeaders.push(columnToPush);
    });
    return columnHeaders;
  };

  const summaryStatisticsAssessmentColumns = renderAssessmentTableHeader(resultsDataTableData);

  const summaryStatisticsColumns = [
    {
      field: 'statistic',
      headerName: 'Statistic',
      flex: 2,
      sortable: false
    },
    {
      field: 'value',
      headerName: currentParameter || '',
      flex: 1,
      sortable: false
    }
  ];

  const summaryStatisticsPreview = [
    {
      id: randomId(),
      statistic: 'Average',
      value: currentSummary ? renderSigDigits(currentSummary.avg_or) : ''
    },
    {
      id: randomId(),
      statistic: 'Standard Deviation, RMS',
      value: currentSummary ? renderSigDigits(currentSummary.sd_rms_or) : ''
    },
    {
      id: randomId(),
      statistic: 'Standard Deviation, MR',
      value: currentSummary ? renderSigDigits(currentSummary.sd_mr_or) : ''
    },
    {
      id: randomId(),
      statistic: 'Repeatability (r)',
      value: currentSummary ? renderSigDigits(currentSummary.repeat_or) : ''
    },
    {
      id: randomId(),
      statistic: 'Upper Control Limit',
      value: currentSummary ? renderSigDigits(currentSummary.ucl_sd) : ''
    },
    { id: randomId(), statistic: 'n', value: currentSummary ? Number(currentSummary.n_or) : '' }
  ];

  const summaryStatisticsPreviewTwo = [
    {
      id: randomId(),
      statistic: 'Reproducibility (R)',
      value: currentSummary ? renderSigDigits(currentSummary.repro_or) : ''
    },
    {
      id: randomId(),
      statistic: 'Precision Ratio',
      value: currentSummary ? renderSigDigits(currentSummary.pr_or) : ''
    },
    {
      id: randomId(),
      statistic: 'TPI',
      value: currentSummary ? renderSigDigits(currentSummary.tpi_or) : ''
    },
    {
      id: randomId(),
      statistic: 'Anderson Darling',
      value: currentSummary ? renderSigDigits(currentSummary.ad_mr_or) : ''
    },
    {
      id: randomId(),
      statistic: 'Lower Control Limit',
      value: currentSummary ? renderSigDigits(currentSummary.lcl_sd) : ''
    },
    {
      id: randomId(),
      statistic: 'Site Precision',
      value: currentSummary ? renderSigDigits(currentSummary.sd_rms_or * 2.77) : ''
    }
  ];

  const handleDeleteSchedule = (row) => {
    deleteSampleSchedule(row.id);
  };

  const handleEditSchedule = (scheduleIn) => {
    const schedule = scheduleIn;
    if (schedule.startingDate.seconds) {
      schedule.startingDate = new Date(
        schedule.startingDate.seconds * 1000 + schedule.startingDate.nanoseconds / 1000000
      );
    }
    setCurrentModalSchedule(schedule);
    setCurrentModalAvailableTimes([...sampleScheduleTimes]);
    setScheduleModalType('Edit');
    setShowScheduleModal(true);
  };

  const sampleScheduleColumnsNew = (readOnly) => {
    const base = [
      {
        field: 'description',
        headerName: 'Description',
        editable: false,
        flex: 2,
        sortable: false
      },
      {
        field: 'frequency',
        headerName: 'Frequency',
        editable: false,
        sortable: false
      },
      {
        field: 'time',
        headerName: 'Time',
        editable: false,
        sortable: false
      },
      {
        field: 'startingDateDisplayed',
        headerName: 'Starting Date',
        editable: false,
        flex: 2,
        sortable: false
      },
      {
        field: 'timezone',
        headerName: 'Timezone',
        editable: false,
        sortable: false,
        flex: 1
      }
    ];
    if (!readOnly) {
      base.push({
        field: 'edit',
        headerName: '',
        sortable: false,
        width: 40,
        renderCell: (params) => {
          return (
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              color="inherit"
              onClick={() => handleEditSchedule(params.row)}
            />
          );
        }
      });
      base.push({
        field: 'delete',
        headerName: '',
        sortable: false,
        width: 40,
        editable: () => {
          return false;
        },
        renderCell: (params) => {
          return (
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              color="inherit"
              onClick={() => handleDeleteSchedule(params.row)}
            />
          );
        }
      });
    }
    return base;
  };

  const parameterSecondaryDataColumns = (editable = true) => [
    {
      field: 'variable',
      headerName: 'Variable',
      editable: false,
      flex: 2,
      sortable: false
    },
    {
      field: 'value',
      headerName: 'Value',
      editable: editable,
      flex: 3,
      sortable: false
    }
  ];

  const limitDataColumns = (editable = true) => [
    {
      field: 'variable',
      headerName: 'Variable',
      flex: 1,
      editable: false,
      sortable: false,
      headerStyle: () => {
        return { width: '250px' };
      }
    },
    {
      field: 'approach',
      headerName: 'Approach',
      flex: 1,
      editable: editable,
      sortable: false,
      type: 'singleSelect',
      valueOptions: (params) => {
        const utlDropdown = [
          {
            value: 'Use Upper Tolerance Limit (1090)',
            label: 'Use Upper Tolerance Limit (1090)'
          },
          { value: 'Default', label: 'Default' }
        ];
        const ltlDropdown = [
          {
            value: 'Use Lower Tolerance Limit (1090)',
            label: 'Use Lower Tolerance Limit (1090)'
          },
          { value: 'Default', label: 'Default' }
        ];
        switch (params.row.variable) {
          case 'Lower Tolerance Limit':
            return ltlDropdown;
          case 'Upper Tolerance Limit':
            return utlDropdown;
          default:
            return [];
        }
      }
    },
    {
      field: 'value',
      headerName: 'Value',
      flex: 1,
      editable: editable,
      sortable: false
    }
  ];

  const methodSecondaryDataColumns = (editable = true) => [
    {
      field: 'variable',
      headerName: 'Variable',
      editable: false,
      sortable: false,
      flex: 4
    },
    {
      field: 'value',
      headerName: 'Value',
      editable: editable,
      sortable: false,
      flex: 1,
      type: 'singleSelect',
      valueOptions: (params) => {
        const sqcDropdown = ['SQC', 'Accuracy', 'CRM'];
        const yesNo = ['No', 'Yes'];
        const tenNinetyMaterial = [
          'N/A',
          'ULSD',
          '500 ppm LM diesel fuel',
          'ECA Marine fuel',
          'Butane',
          'Gasoline'
        ];
        switch (params.row.variable) {
          case 'Sample Type':
            return sqcDropdown;
          case '1090 Material':
            return tenNinetyMaterial;
          default:
            return yesNo;
        }
      }
    }
  ];

  const [controlStrategyData, setControlStrategyData] = useState(
    generateControlStrategyData(currentControlStrategyConfigurations)
  );

  const bottomTPIData = [
    {
      id: randomId(),
      variable: 'Suppress TPI notifications',
      value: currentControlStrategyConfigurations.suppressTPI
    },
    {
      id: randomId(),
      variable: 'Suppress Normality notifications',
      value: currentControlStrategyConfigurations.suppressNormality
    }
  ];

  const methodSpecificData = [
    {
      id: randomId(),
      variable: 'Initial Assessment, samples (n)',
      value: currentChartGroupConfigurations.initialAssessment
    },
    {
      id: randomId(),
      variable: 'Ongoing assessment, samples (n)',
      value: currentChartGroupConfigurations.ongoingAssessment
    },
    {
      id: randomId(),
      variable: 'Ongoing assessment, days',
      value: currentChartGroupConfigurations.ongoingAssessmentDays
    }
  ];

  const limitData = [
    {
      id: randomId(),
      variable: 'Lower Tolerance Limit',
      approach: currentControlStrategyConfigurations.lowerToleranceLimitApproach,
      value: currentControlStrategyConfigurations.lowerToleranceLimit
    },
    {
      id: randomId(),
      variable: 'Upper Tolerance Limit',
      approach: currentControlStrategyConfigurations.upperToleranceLimitApproach,
      value: currentControlStrategyConfigurations.upperToleranceLimit
    }
  ];

  const parameterSecondaryData = [
    {
      id: randomId(),
      variable: 'Mean',
      value: currentControlStrategyConfigurations?.mean
    },
    {
      id: randomId(),
      variable: 'Standard Deviation',
      value: currentControlStrategyConfigurations.standardDeviation
    },
    {
      id: randomId(),
      variable: 'n',
      value: currentControlStrategyConfigurations.n
    },
    {
      id: randomId(),
      variable: 'Reproducibility',
      value: currentControlStrategyConfigurations.reproducibility
    },
    {
      id: randomId(),
      variable: 'Repeatability',
      value: currentControlStrategyConfigurations.repeatability
    },
    {
      id: randomId(),
      variable: 'EWMA Lambda',
      value: currentControlStrategyConfigurations.ewmaLambda
    },
    {
      id: randomId(),
      variable: 't alpha',
      value: currentControlStrategyConfigurations.tAlpha
    },
    {
      id: randomId(),
      variable: 'f alpha',
      value: currentControlStrategyConfigurations.fAlpha
    }
  ];

  const bottomTPIColumns = (editable = true) => [
    {
      field: 'variable',
      headerName: 'Variable',
      sortable: false,
      editable: false,
      flex: 4
    },
    {
      field: 'value',
      headerName: 'Value',
      editable: editable,
      sortable: false,
      flex: 1,
      type: 'singleSelect',
      valueOptions: () => ['Yes', 'No']
    }
  ];

  const controlStrategyConfigurationColumns = (editable = true) => [
    {
      field: 'variable',
      headerName: 'Variable',
      editable: false,
      sortable: false,
      flex: 4
    },
    {
      field: 'value',
      headerName: 'Value',
      sortable: false,
      editable: editable,
      flex: 1,
      type: 'singleSelect',
      valueOptions: () => ['Off', 'Warning', 'Out of Control']
    }
  ];

  function alphaNumericSorter(a, b) {
    return a.name.localeCompare(b.name, undefined, { numeric: true });
  }

  const renderOptionsDynamic = (dropdownOptions) => {
    const rows = [];
    dropdownOptions.sort(alphaNumericSorter);
    dropdownOptions.forEach((option) => {
      let description = `${option.name}`;
      if (option.count) {
        description = `${description} (${option.count})`;
      }
      rows.push(<option value={option.name}>{description}</option>);
    });
    return rows;
  };

  const renderOptionsDynamicParameter = (dropdownOptions) => {
    const rows = [];
    let combineOption;
    dropdownOptions.sort(alphaNumericSorter).sort((a, b) => a.order - b.order);
    dropdownOptions.forEach((option) => {
      let description = `${option.name}`;
      if (option.count) {
        description = `${description} (${option.count})`;
      }
      if (option.name !== defaultCombinedParameterName) {
        rows.push(<option value={option.name}>{description}</option>);
      } else {
        combineOption = <option value={option.name}>{description}</option>;
      }
    });
    if (combineOption) {
      rows.push(combineOption);
    }
    return rows;
  };

  const assignedSamplesTableColumns = (editable = true) => [
    {
      field: 'sample',
      headerName: 'Sample',
      type: 'singleSelect',
      flex: 1,
      editable: editable,
      sortable: false,
      getOptionValue: (value) => value.value,
      getOptionLabel: (value) => value.label,
      valueOptions: currentSamples
    }
  ];

  const methodSpecificColumns = (editable = true) => [
    {
      field: 'variable',
      headerName: 'Variable',
      editable: false,
      sortable: false,
      flex: 4
    },
    {
      field: 'value',
      headerName: 'Value',
      editable: editable,
      sortable: false,
      flex: 1
    }
  ];

  const handleAddSchedule = () => {
    setCurrentModalSchedule({
      description: '',
      frequency: 'None',
      time: currentModalAvailableTimes[0]?.time || '12:00 AM',
      startingDate: startDate,
      timezone: moment.tz.guess(),
      fullKey: currentFullKey,
      shortKey: currentShortKey,
      parameters: currentParameters
    });
    setScheduleModalType('Add');
    setCurrentModalAvailableTimes([...sampleScheduleTimes]);
    setShowScheduleModal(true);
  };

  const handlePrintClick = () => {
    setActiveTab('PrintOverview');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleBackToOverview = () => {
    setActiveTab('Overview');
  };

  const methodSecondaryData = [
    {
      id: randomId(),
      variable: 'Sample Type',
      value: currentChartGroupConfigurations.sampleType
    },
    {
      id: randomId(),
      variable: 'Display method version',
      value: currentChartGroupConfigurations.displayMethodVersion
    },
    {
      id: randomId(),
      variable: 'Auto-create sample re-check',
      value: currentChartGroupConfigurations.autoCreateSampleRecheck
    },
    {
      id: randomId(),
      variable: '1090 Material',
      value: currentControlStrategyConfigurations.tenNinetyMaterial
    }
  ];

  const cellEditProp = {
    mode: 'click',
    blurToSave: true,
    afterSaveCell: (_row, cellName, cellValue) => {
      updateStateConfigurations(cellValue, cellName);
    }
  };

  const handleDescriptionChange = (e) => {
    setCurrentModalSchedule({
      ...currentModalSchedule,
      description: e.target.value
    });
  };

  const handleFrequencyChange = (e) => {
    setCurrentModalSchedule({
      ...currentModalSchedule,
      frequency: e.target.value
    });
  };

  const handleTimeChange = (e) => {
    setCurrentModalSchedule({
      ...currentModalSchedule,
      time: e.target.value
    });
  };

  const handleStartDateChange = (date) => {
    const startingDateDisplayed = moment(date).format('MM/DD/YYYY') || '';
    setCurrentModalSchedule({
      ...currentModalSchedule,
      startingDate: date,
      startingDateDisplayed
    });
  };

  const handleTimezoneChange = (e) => {
    setCurrentModalSchedule({
      ...currentModalSchedule,
      timezone: e.target.value
    });
  };

  const cellEditPropSampleSchedule = {
    mode: 'click',
    blurToSave: true,
    afterSaveCell: () => {}
  };

  const handleSaveSchedule = () => {
    if (scheduleModalType === 'Add') {
      createSampleSchedule(currentModalSchedule);
    } else if (scheduleModalType === 'Edit') {
      updateSampleSchedule(currentModalSchedule);
    }
    setShowScheduleModal(false);
  };

  const cellEditPropLimitData = {
    mode: 'click',
    blurToSave: true,
    afterSaveCell: (_row, cellName, cellValue) => {
      updateStateConfigurations(cellValue, cellName);
    }
  };

  function newAssessment() {
    const assessment = {
      assessed: true,
      assessorComments: '',
      clusteringEvident: '',
      normalDistribution: '',
      outcome: '',
      createdBy: resultsDataTableData[0]?.personnel,
      n: currentSetupDataOne + currentSetupDataZero,
      type: 'Initial',
      locked: false,
      shortKey: currentShortKey,
      cyclicalPatterns: '',
      tpiAssessment: '',
      trendingDirectionEvident: ''
    };
    return assessment;
  }

  function newAssessmentPeriodic() {
    const assessment = {
      assessed: true,
      assessorComments: '',
      suspiciousResults: '',
      unusualPatterns: '',
      pool: false,
      outcome: '',
      createdBy: resultsDataTableData[0]?.personnel,
      n: currentSetupDataOne + currentSetupDataZero,
      type: 'Periodic',
      locked: false,
      shortKey: currentShortKey,
      fullKey: currentFullKey,
      tpiEvaluation: '',
      chiSquareTest: '',
      normalProbabilityPlot: '',
      andersonDarling: '',
      tTest: '',
      fTest: ''
    };
    return assessment;
  }

  function renderResultsTable(renderResults) {
    const rows = [];
    const readOnly = !checkCreate(permissionData, SCREEN?.DATA);
    rows.push(
      <div key={uuidv4()} className="results-table-class">
        <input
          key={uuidv4()}
          readOnly
          value="Date"
          className="results-entry"
          style={{ width: '25%' }}
        />
        <input
          key={uuidv4()}
          readOnly
          value="Parameter"
          className="results-entry"
          style={{ width: '20%' }}
        />
        <input
          key={uuidv4()}
          readOnly
          value="Personnel"
          className="results-entry"
          style={{ width: '20%' }}
        />
        <input
          key={uuidv4()}
          readOnly
          value="Result ID"
          className="results-entry"
          style={{ width: '20%' }}
        />
        <input
          key={uuidv4()}
          readOnly
          value="Setup Data"
          className="results-entry"
          style={{ width: '15%' }}
        />
      </div>
    );
    renderResults.forEach((row, index) => {
      rows.push(
        <div key={uuidv4()} className="results-table-class">
          <input
            style={{ width: '25%' }}
            readOnly={readOnly}
            onChange={(e) => handleResultsTableChange(e, index, 'dateTime')}
            defaultValue={row.dateTime}
            key={uuidv4()}
            type="text"
          />
          <Tooltip title={row.parameter} arrow>
            <input
              style={{ width: '20%' }}
              readOnly={readOnly}
              onChange={(e) => handleResultsTableChange(e, index, 'parameter')}
              defaultValue={row.parameter}
              key={uuidv4()}
              type="text"
            />
          </Tooltip>
          <input
            style={{ width: '20%' }}
            readOnly={readOnly}
            onChange={(e) => handleResultsTableChange(e, index, 'personnel')}
            defaultValue={row.personnel}
            key={uuidv4()}
            type="text"
          />
          <input
            readOnly={readOnly}
            onChange={(e) => handleResultsTableChange(e, index, 'sampleId')}
            defaultValue={row.sampleId}
            key={uuidv4()}
            type="text"
            style={{ width: '20%' }}
          />
          <input
            style={{ width: '15%' }}
            readOnly={readOnly}
            onChange={(e) => handleResultsTableChange(e, index, 'setupData')}
            defaultValue={row.setupData}
            key={uuidv4()}
            type="text"
          />
        </div>
      );
    });
    return rows;
  }

  function renderResultsEntry(resultsEntryData) {
    const rows = [];
    const readOnly = !checkCreate(permissionData, SCREEN?.DATA);
    rows.push(<input key={uuidv4()} readOnly value="Result" />);
    const resultsEntryToReturn = resultsEntryData.filter(
      (entry) => !archiveRegex.test(entry.parameter)
    );

    resultsEntryToReturn.forEach((entry, index) => {
      rows.push(
        <input
          readOnly={readOnly}
          onChange={(e) => handleResultsTableChange(e, index, 'result')}
          key={uuidv4()}
          defaultValue={entry.result}
          type="text"
        />
      );
    });
    return rows;
  }

  const addData = () => {
    setLoadingSaveData(true);
    if (!currentLocation || !currentMethod || !currentInstrument || !currentSampleName) {
      // eslint-disable-next-line no-alert
      alert('Need to select location, method, sample name, and instrument');
      setLoadingSaveData(false);
    } else {
      const resultsRows = resultsDataTableData.map((item) => ({ ...item }));
      setResultsDataTableData(resultsDataTableData.map((item) => ({ ...item, result: '' })));
      const createAt = moment().tz(tz || 'UTC');
      for (const dataPoint of resultsRows) {
        if (dataPoint.isEditedDatetime) {
          const inputDateTime = moment.tz(dataPoint.dateTime, 'MM/DD/YYYY HH:mm', tz || 'UTC');
          dataPoint.dateTime = inputDateTime.toISOString();
          dataPoint.date = inputDateTime.format('MM/DD/YYYY');
          const config = getConfiguration(window.location.hostname);
          dataPoint.time = inputDateTime.format(config.timeIn24HourFormat ? 'HH:mm' : 'h:mm A');
        } else {
          dataPoint.dateTime = createAt.toISOString();
          dataPoint.date = createAt.format('MM/DD/YYYY');
          const config = getConfiguration(window.location.hostname);
          dataPoint.time = createAt.format(config.timeIn24HourFormat ? 'HH:mm' : 'h:mm A');
        }
        if (!dataPoint.isEditedSampleId) {
          dataPoint.sampleId = `SQC${createAt.format('YYYYMMDD')}`;
        }
      }
      const addDataObject = {
        location: currentLocation,
        method: currentMethod,
        instrument: currentInstrument,
        rows: resultsRows
          .filter((row) => !!String(row.result))
          .map((row) => {
            return { ...row, sampleName: currentSampleName };
          })
      };

      addDataFunction(addDataObject)
        .then(({ data }) => {
          resultsDataTableData.forEach((item) => {
            item['result'] = '';
          });
          if (data.status === 200) setLoadingSaveData(false);
        })
        .catch(() => {
          setLoadingSaveData(false);
        });
    }
  }; // addData

  function renderOverviewCharts(activeTabSelected, isDataComplete) {
    const chartSectionToReturn = [];
    if (activeTabSelected === 'Overview' && isDataComplete === true) {
      chartSectionToReturn.push(
        <div className="charts-master-container">
          {Array.isArray(chartData) && chartData.length && (
            <div>
              <div className="overview-chart-header">
                <h4>
                  Summary Statistics: {currentParameter}, {unit}
                </h4>
              </div>
              <div className="statistics-table">
                <div>
                  <CustomTable
                    data={summaryStatisticsPreview}
                    header={summaryStatisticsColumns}
                    hideFooter={true}
                  />
                </div>
                <div>
                  <CustomTable
                    data={summaryStatisticsPreviewTwo}
                    header={summaryStatisticsColumns}
                    hideFooter={true}
                  />
                </div>
              </div>
              <div className="overview-chart-header">
                <h4>Charts</h4>
              </div>
              <div className="chart-row-container">
                <SQCLineChart
                  xAxis="updatedIndex"
                  syncId="chained"
                  yMin={chartScalars.cYMin}
                  yMax={chartScalars.cYMax}
                  data={chartData}
                  lines={chartLines}
                  isEverFlags={isEverFlags}
                />
                <SQCMovingRangeChart
                  xAxis="updatedIndex"
                  key={uuidv4()}
                  data={chartData}
                  syncId="chained2"
                  yMin={0}
                  yMax={chartScalars.mrYMax}
                  lines={[
                    {
                      yAxis: 'mrOmits',
                      name: 'Data',
                      color: '#32CD32'
                    },
                    {
                      yAxis: 'mean',
                      overrideValue: chartScalars.mrMean,
                      name: 'Average',
                      reference: true,
                      label: ``,
                      color: '#FFBF00'
                    },
                    {
                      yAxis: 'sd3p',
                      overrideValue: chartScalars.mrUCL,
                      label: ``,
                      color: '#FBCEB1',
                      reference: true
                    }
                  ]}
                />
              </div>
              <Row>
                <Col>
                  <>
                    {chartScalars.histogramData && (
                      <SQCHistogram data={chartScalars.histogramData} />
                    )}
                    {!chartScalars.histogramData && <div>No Histogram</div>}
                  </>
                </Col>
                <Col>
                  <SQCScatterPlot
                    xAxis="zScoreOmits"
                    key={uuidv4()}
                    data={chartData}
                    syncId="chained2"
                    xMin={chartScalars.qqXMin}
                    xMax={chartScalars.qqXMax}
                    yMin={chartScalars.qqYMin}
                    yMax={chartScalars.qqYMax}
                    collections={[
                      {
                        name: 'Lab Results',
                        color: '#32CD32',
                        data: chartData.map((cd) => {
                          return {
                            x: cd.zScoreOmits,
                            y: cd.resultOmits
                          };
                        })
                      },
                      {
                        name: 'Trend Line',
                        color: '#FFBF00',
                        data: chartScalars.trendData,
                        line: true
                      }
                    ]}
                  />
                </Col>
              </Row>
            </div>
          )}
        </div>
      );
    } else if (activeTabSelected === 'Overview' && isDataComplete === false) {
      chartSectionToReturn.push(
        <>
          <div className="compiling-issue-container">
            <div className="compiling-issue-header ">
              <h4>Summary Statistics and Charts</h4>
              <div>
                There were issues compiling chart data for that location, method, sample name,
                instrument and parameter. Try running the data to fix this.
              </div>
            </div>
          </div>
        </>
      );
    } else {
      chartSectionToReturn.push(<></>);
    }
    return chartSectionToReturn;
  }

  const handleLowerControlLimitChange = (e) => {
    setCurrentControlStrategyConfigurations({
      ...currentControlStrategyConfigurations,
      lowerControlLimit: e.target.value
    });
  };

  const handleUpperControlLimitChange = (e) => {
    setCurrentControlStrategyConfigurations({
      ...currentControlStrategyConfigurations,
      upperControlLimit: e.target.value
    });
  };

  const mapApproachToValue = (approach, limitType) => {
    let valueToReturn = '';
    switch (approach) {
      case 'Use Repeatability-based limit': {
        if (limitType === 'lowerControlLimit') {
          valueToReturn = renderSigDigits(
            Number(currentSummary.avg_sd) - (Number(currentSummary.repeat_sd) / 2.77) * 3
          );
        } else {
          valueToReturn = renderSigDigits(
            Number(currentSummary.avg_sd) + (Number(currentSummary.repeat_sd) / 2.77) * 3
          );
        }
        break;
      }
      case 'Default':
        valueToReturn = 'N/A';
        break;
      case 'Custom':
        valueToReturn = '';
        break;
      default:
        break;
    }
    return valueToReturn;
  };

  const handleUpperControlLimitSelect = (e) => {
    setCurrentControlStrategyConfigurations({
      ...currentControlStrategyConfigurations,
      upperControlLimitApproach: e.target.value,
      upperControlLimit: mapApproachToValue(e.target.value, 'upperControlLimit')
    });
  };

  const handleLowerControlLimitSelect = (e) => {
    setCurrentControlStrategyConfigurations({
      ...currentControlStrategyConfigurations,
      lowerControlLimitApproach: e.target.value,
      lowerControlLimit: mapApproachToValue(e.target.value, 'lowerControlLimit')
    });
  };

  const updateRowData = (rowData, isDelete = false) => {
    const currentRowsToUpdate = rowsToUpdate;
    const existingIndex = currentRowsToUpdate.findIndex(
      (rowToUpdate) => rowToUpdate.id === rowData.id
    );
    if (existingIndex !== -1) {
      if (isDelete) {
        currentRowsToUpdate[existingIndex] = {
          ...currentRowsToUpdate[existingIndex],
          delete: rowData.delete
        };
      } else {
        rowData.delete = currentRowsToUpdate[existingIndex].delete;
        currentRowsToUpdate[existingIndex] = rowData;
      }
    } else {
      currentRowsToUpdate.push(rowData);
    }
    setRowsToUpdate(currentRowsToUpdate);
  };

  const renderPrintOverviewCharts = () => {
    return (
      <div>
        {Array.isArray(chartData) && chartData.length && (
          <div>
            <div className="overview-chart-header">
              <h4>Charts</h4>
            </div>
            <div className="chart-row-container">
              <SQCLineChart
                xAxis="updatedIndex"
                syncId="chained"
                yMin={chartScalars.cYMin}
                yMax={chartScalars.cYMax}
                data={chartData}
                lines={chartLines}
              />
              <SQCMovingRangeChart
                xAxis="updatedIndex"
                key={uuidv4()}
                data={chartData}
                syncId="chained2"
                yMin={0}
                yMax={chartScalars.mrYMax}
                lines={[
                  {
                    yAxis: 'mrOmits',
                    name: 'Data',
                    color: '#32CD32'
                  },
                  {
                    yAxis: 'mean',
                    overrideValue: chartScalars.mrMean,
                    name: 'Average',
                    reference: true,
                    label: ``,
                    color: '#FFBF00'
                  },
                  {
                    yAxis: 'sd3p',
                    overrideValue: chartScalars.mrUCL,
                    label: ``,
                    color: '#FBCEB1',
                    reference: true
                  }
                ]}
              />
            </div>
            <Row>
              <Col>
                <>
                  {chartScalars.histogramData && <SQCHistogram data={chartScalars.histogramData} />}
                  {!chartScalars.histogramData && <div>No Histogram</div>}
                </>
              </Col>
              <Col>
                <SQCScatterPlot
                  xAxis="zScoreOmits"
                  key={uuidv4()}
                  data={chartData}
                  syncId="chained2"
                  xMin={chartScalars.qqXMin}
                  xMax={chartScalars.qqXMax}
                  yMin={chartScalars.qqYMin}
                  yMax={chartScalars.qqYMax}
                  collections={[
                    {
                      name: 'Lab Results',
                      color: '#32CD32',
                      data: chartData.map((cd) => {
                        return {
                          x: cd.zScoreOmits,
                          y: cd.resultOmits
                        };
                      })
                    },
                    {
                      name: 'Trend Line',
                      color: '#FFBF00',
                      data: chartScalars.trendData,
                      line: true
                    }
                  ]}
                />
              </Col>
            </Row>
          </div>
        )}
      </div>
    );
  };

  const exportToCSV = () => {
    if (!filteredChartHits || filteredChartHits.length === 0) return;

    const allKeys = defaultExportDataToCSVColumns.map((column) => column.field);
    const allHeaders = defaultExportDataToCSVColumns.map((column) => column.headerName);

    // Create CSV header row
    const csvHeader = allHeaders.join(',');

    // Create CSV data rows
    const csvRows = filteredChartHits.map((obj) => {
      return allKeys
        .map((key) => {
          const value = obj[key];
          // Handle special cases like dates, numbers, strings with commas
          if (value === null || value === undefined) return '';
          if (typeof value === 'string' && value.includes(',')) return `"${value}"`;
          if (value instanceof Date || key === 'dateTime')
            return moment(value)
              .tz(tz || 'UTC')
              .format(FORMAT_KEY_TIME);
          return value;
        })
        .join(',');
    });

    // Combine header and rows
    const csvString = [csvHeader, ...csvRows].join('\n');

    // Create and trigger download
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      const fileName = `${currentParameter}_Dataset_${moment().format(FORMAT_DATE_YYYYMMDD)}.csv`;
      link.setAttribute('href', url);
      link.setAttribute('download', fileName);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const renderResultsSection = useMemo(() => {
    const resultsSectionToReturn = [];
    if (currentControlStrategyConfigurations.archive === 'Yes') {
      resultsSectionToReturn.push(<></>);
    } else {
      resultsSectionToReturn.push(
        <div>
          <div className="overview-chart-header">
            <h4>Results</h4>
          </div>
          <div className="results-table-container">
            <div className="results-entry-container">
              <div className="render-results-table-container ">
                <div>{renderResultsTable(resultsDataTableData)}</div>
                <div className="render-results-entry-table-container">
                  {renderResultsEntry(resultsDataTableData)}
                </div>
              </div>
            </div>
          </div>
          <div className="edit-button">
            {renderButton(
              'Re-index Data',
              () => {
                setLoadingReIndexData(true);
                runReBuildIndexFunctions()
                  .then(() => {
                    setLoadingReIndexData(false);
                  })
                  .catch(() => {
                    setLoadingReIndexData(false);
                  });
              },
              SCREEN?.DATA,
              RULES.CREATE,
              true,
              loadingReIndexData
            )}
            {renderButton(
              'Run Data',
              () => runData(),
              SCREEN?.DATA,
              RULES.SAVE,
              true,
              loadingRunData
            )}
            {renderButton(
              'Save Data',
              () => addData(),
              SCREEN?.DATA,
              RULES.CREATE,
              true,
              loadingSaveData
            )}
          </div>
        </div>
      );
    }
    return resultsSectionToReturn;
  }, [
    currentControlStrategyConfigurations,
    resultsDataTableData,
    loadingRunData,
    loadingSaveData,
    loadingReIndexData
  ]);
  // Note: In the future, we want to render a single table and dynamically update columns. However, BootstrapTable is unable to do that so we have to render separate tables for now.
  const renderEditTable = () => {
    let columns;
    if (isNonUs) {
      columns = [...defaultColumns(dateTimeFormat, tz), ...defaultColumnsUsExtend];
    } else {
      columns = [...defaultColumns(dateTimeFormat, tz), ...defaultColumnsExtend];
    }

    if (isEverFlags) {
      columns.splice(5, 0, {
        field: 'everFlags',
        headerName: 'EverFlags',
        minWidth: 250,
        editable: false,
        renderCell: (params) => {
          const { value } = params;
          if (value && value.length) {
            return (
              <Tooltip
                title={
                  <>
                    {value.map((v) => (
                      <p style={{ margin: 0 }}>{`V${v.version}: ${v.flags.replace(
                        'W:',
                        'Warnings:'
                      )}`}</p>
                    ))}
                  </>
                }
              >
                <span>
                  V{value[value.length - 1].version}:{' '}
                  {value[value.length - 1].flags.replace('W:', 'Warnings:')}
                </span>
              </Tooltip>
            );
          }
          return <></>;
        }
      });
    }
    if (activeTab === 'PrintOverview') {
      return <PrintOverviewDataTable columns={columns} data={filteredChartHits} tz={tz} />;
    } else {
      return (
        <CustomTable
          header={columns}
          data={filteredChartHits}
          numberOfRows={20}
          sort={{ sorting: { sortModel: [{ field: 'dateTime', sort: 'desc' }] } }}
        />
      );
    }
  };

  const defaultColumnsEditExtend = (allowDelete) => {
    const list = [
      {
        field: 'omit1090',
        headerName: 'Omit 1090',
        minWidth: 100,
        editable: true,
        type: 'singleSelect',
        valueOptions: yesNoOptions
      },
      {
        field: 'notes',
        headerName: 'Notes',
        minWidth: 150,
        flex: 1,
        editable: true
      }
    ];
    if (allowDelete) {
      list.push({
        field: 'delete',
        headerName: 'Delete',
        textAlign: 'center',
        minWidth: 40,
        renderCell: ({ row }) => (
          <Checkbox
            disabled={!allowDelete}
            defaultChecked={row?.delete}
            onChange={(e) => {
              updateRowData({ ...row, delete: e.target.checked }, true);
            }}
          />
        )
      });
    }
    return list;
  };

  const defaultColumnsEditUsExtend = (allowDelete) => {
    const list = [
      {
        field: 'notes',
        headerName: 'Notes',
        minWidth: 150,
        flex: 1,
        editable: true
      }
    ];
    if (allowDelete) {
      list.push({
        field: 'delete',
        headerName: 'Delete',
        textAlign: 'center',
        minWidth: 40,
        renderCell: ({ row }) => (
          <Checkbox
            disabled={!allowDelete}
            defaultChecked={row?.delete}
            onChange={(e) => {
              updateRowData({ ...row, delete: e.target.checked }, true);
            }}
          />
        )
      });
    }
    return list;
  };

  // Note: In the future, we want to render a single table and dynamically update columns. However, BootstrapTable is unable to do that so we have to render separate tables for now.
  const renderModalEditTable = () => {
    let columns;
    const update = permissionData?.[SCREEN?.DATA]?.update || false;
    const allowDelete = permissionData?.[SCREEN?.DATA]?.delete || false;
    if (isNonUs) {
      columns = [
        ...defaultColumnsEdit(currentPersonnelDropdown, dateTimeFormat, tz),
        ...defaultColumnsEditUsExtend(allowDelete)
      ];
    } else {
      columns = [
        ...defaultColumnsEdit(currentPersonnelDropdown, dateTimeFormat, tz),
        ...defaultColumnsEditExtend(allowDelete)
      ];
    }

    if (update && update.type != UPDATE_TYPE.ALL) {
      columns.forEach((item) => {
        if (item) {
          if (
            update.type === UPDATE_TYPE.NONE ||
            !(update.fieldAllow && update.fieldAllow.includes(item.field))
          ) {
            item.editable = false;
          } else {
            item.editable = true;
          }
        }
      });
    }
    return (
      <CustomTable
        data={filteredChartHits}
        header={columns}
        setData={setFilteredChartHits}
        numberOfRows={20}
        updateRowData={updateRowData}
        actionPosition={'first'}
        action={['edit']}
        sort={{ sorting: { sortModel: [{ field: 'dateTime', sort: 'desc' }] } }}
        updateEditingModeFunction={updateEditingMode}
      />
    );
  };

  const [loadingResetEverFlags, setLoadingResetEverFlags] = useState(false);
  const handleResetEverFlags = async () => {
    try {
      setLoadingResetEverFlags(true);
      if (!currentLocation || !currentMethod || !currentInstrument || !currentSampleName) {
        alert('Need to select location, method, sample name, and instrument');
      } else {
        const everFlagsReset = filteredChartHits
          .map((item) => ({ flags: item.flags, id: item.id }))
          .filter((item) => item.flags);
        await resetEverFlags(currentFullKey, everFlagsReset);
      }
    } catch (error) {
      console.error('handle reset everFlags error:', error);
    } finally {
      setLoadingResetEverFlags(false);
    }
  };

  const [isDeleteAllData, setIsDeleteAllData] = useState(false);
  const [openCofirmDeleteAllData, setOpenCofirmDeleteAllData] = useState(false);
  const [isDeleteDisabled, setIsDeleteDisabled] = useState(true);
  const [confirmationInput, setConfirmationInput] = useState('');
  const [error, setError] = useState('');

  const handleConfirmationInputChange = (event) => {
    setConfirmationInput(event.target.value);
    if (event.target.value === 'delete') {
      setIsDeleteDisabled(false);
    } else {
      setIsDeleteDisabled(true);
    }
  };

  const handleConfirmDelete = async () => {
    if (confirmationInput.toLowerCase() !== 'delete') {
      setError("You must type 'delete' to confirm.");
      return;
    }

    if (!currentLocation || !currentMethod || !currentSampleName || !currentInstrument) {
      setError('Please select a location, method, sample name, instrument, and parameter.');
      return;
    }

    const resetSelection = () => {
      setCurrentLocation('');
      setCurrentMethod('');
      setCurrentSampleName('');
      setCurrentInstrument('');
      setCurrentParameter('');
    };

    try {
      setChartLoading(true);

      const response = await deleteAllDataFunction({ fullKey: currentFullKey });

      if (response?.data.success) {
        alert('All data deleted successfully! Proceeding to rebuild the index.');
        resetSelection();
        await runReBuildIndexFunctions();
        window.location.reload();
      } else {
        alert('All data deletion and re-indexing failed.');
      }
    } catch (error) {
      console.error('[handleConfirmDelete] Error:', error);
      alert('An error occurred while deleting data. Please try again.');
    } finally {
      setChartLoading(false);
      setConfirmationInput('');
      setError('');
    }
  };

  function renderBottomSection(section) {
    if (section === 'Overview') {
      return (
        <>
          <div className="chart-section-overall-container">
            <div className="results-chart-container">
              <div>
                <div>
                  {localSearchFinished && (
                    <div>
                      {renderResultsSection}
                      <div>
                        {renderEditTable()}
                        <div className="edit-button">
                          {isEverFlags &&
                            isAdmin(permissionData) &&
                            renderButton(
                              'Reset EverFlags',
                              handleResetEverFlags,
                              SCREEN?.DATA,
                              RULES.SAVE,
                              true,
                              loadingResetEverFlags
                            )}
                          {renderButton(
                            'Export to CSV',
                            () => exportToCSV(),
                            SCREEN?.DATA,
                            RULES.SAVE
                          )}
                          {renderButton(
                            'Edit Data',
                            () => setShowEditModal(true),
                            SCREEN?.DATA,
                            RULES.SAVE
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {!localSearchFinished && (
                    <div className="please-select-container">
                      Please select a location, method, sample name, instrument, and parameter
                    </div>
                  )}
                </div>
                <div />
                <div className="modal-90w">
                  <Modal
                    dialogClassName="modal-90w"
                    show={showEditModal}
                    onHide={() => setShowEditModal(false)}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Edit Table</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{renderModalEditTable(featureFlags)}</Modal.Body>
                    <Modal.Footer>
                      <PrimaryButton
                        text="Save"
                        clicked={() => saveEditModal()}
                        disabled={hasActiveEdits}
                      />
                      <PrimaryButton text="Cancel" clicked={() => setShowEditModal(false)} />
                    </Modal.Footer>
                  </Modal>
                </div>
              </div>
            </div>
            {renderOverviewCharts(activeTab, chartDataComplete)}
          </div>
          {localSearchFinished ? (
            <div className="print-button-container">
              <Button onClick={handlePrintClick}>Print</Button>
            </div>
          ) : (
            <></>
          )}
        </>
      );
    }
    if (section === 'PrintOverview') {
      return (
        <>
          <div>
            <div>
              <div>
                <div>
                  <div>
                    <div className="header">
                      <h2>Overview</h2>
                    </div>
                    <div className="underline-header">
                      <hr />
                    </div>
                    <div className="chart-status-section">
                      {currentControlStrategyConfigurations.controlStatus === 'No' ? (
                        <>
                          <div>
                            <h3>Chart Stage: Active</h3>
                          </div>
                        </>
                      ) : (
                        <>
                          <div>
                            <h3>Chart Stage: {chartStage}</h3>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="overview-chart-header">
                      <h4>
                        Summary Statistics: {currentParameter}, {unit}
                      </h4>
                    </div>
                    <div className="statistics-table">
                      <div>
                        <CustomTable
                          header={summaryStatisticsColumns}
                          data={summaryStatisticsPreview}
                          numberOfRows={pageSizeDefault}
                          hideFooter={true}
                        />
                      </div>
                      <div>
                        <CustomTable
                          header={summaryStatisticsColumns}
                          data={summaryStatisticsPreviewTwo}
                          numberOfRows={pageSizeDefault}
                          hideFooter={true}
                        />
                      </div>
                    </div>
                    <div className="overview-chart-header">
                      <h4>Results</h4>
                    </div>
                    <div>{renderEditTable()}</div>
                  </div>
                </div>
                <div />
              </div>
            </div>
            {renderPrintOverviewCharts()}
          </div>
          <div className="print-button-container">
            <Button onClick={handleBackToOverview}>Back</Button>
          </div>
        </>
      );
    }
    if (section === 'Comparison') {
      if (localSearchFinished) {
        return (
          <div>
            <Row className="instruments-chart-container">
              <Col lg={4}>
                <h3>Analyst Comparison</h3>
              </Col>
            </Row>
            <SQCAnalystChart
              xAxis="updatedIndex"
              analysts={currentPersonnelNames}
              analystsDots={currentPersonnelDots}
              syncId="chained"
              key={uuidv4()}
              yMin={chartScalars.cYMin}
              yMax={chartScalars.cYMax}
              data={chartData}
              lines={[
                {
                  yAxis: 'resultOmits',
                  name: 'Data',
                  color: '#32CD32'
                },
                {
                  yAxis: 'mean',
                  name: 'Average',
                  reference: chartData.length > 20,
                  label: ``,
                  color: '#FFBF00'
                },
                {
                  yAxis: 'sd3n',
                  name: 'LCL',
                  label: `LCL: ${renderChartLabelValue(chartData, 'sd3n')}`,
                  overrideValue: Number(renderChartLabelValue(chartData, 'sd3n')),
                  color: '#FBCEB1',
                  reference: true
                },
                {
                  yAxis: 'sd3p',
                  name: 'UCL',
                  label: ``,
                  color: '#FBCEB1',
                  reference: true,
                  overrideValue: Number(renderChartLabelValue(chartData, 'sd3p'))
                }
              ]}
            />
            <Row className="instruments-chart-container">
              <Col lg={4}>
                <h3>Instrument Comparison</h3>
              </Col>
            </Row>
            <Row className="instrument-dropdowns">
              <Form.Group as={Col} controlId="method">
                <Form.Label>Method</Form.Label>
                <Form.Select
                  value={currentMethodComparison}
                  onChange={handleMethodChangeComparison}
                >
                  <option value="">Select method</option>
                  {renderOptionsDynamic(currentMethods)}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} controlId="sampleName">
                <Form.Label>Sample Name</Form.Label>
                <Form.Select
                  value={currentSampleNameComparison}
                  onChange={handleSampleNameChangeComparison}
                >
                  <option value="">Select sample name</option>
                  {renderOptionsDynamic(currentSampleNames)}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} controlId="parameter">
                <Form.Label>Parameter</Form.Label>
                <Form.Select
                  value={currentParameterComparison}
                  onChange={handleParameterChangeComparison}
                >
                  <option value="">Select parameter</option>
                  {renderOptionsDynamic(currentParametersDynamic)}
                </Form.Select>
              </Form.Group>
            </Row>
            <div>
              <SQCInstrumentsChart
                xAxis="dataId"
                instrumentsArray={currentInstrumentNames}
                instrumentsDots={currentInstrumentDots}
                syncId="chained"
                lineType="linear"
                key={uuidv4()}
                yMin={chartScalars.cYMin}
                yMax={chartScalars.cYMax}
                data={currentInstrumentComparisonData}
                lines={[
                  {
                    yAxis: 'resultOmits',
                    name: 'Data',
                    color: '#32CD32'
                  }
                ]}
              />
            </div>
          </div>
        );
      }
      return (
        <div className="please-select-container">
          Please select a location, method, sample name, instrument, and parameter
        </div>
      );
    }
    if (section === 'Configuration') {
      const readOnly =
        checkReadOnly(permissionData, SCREEN?.CONFIG) || radioArchiveValue === 'Archive';
      return (
        <div>
          <div className="configuration-table-container">
            <div className="left-configuration-tables">
              <div>
                <h3>Control Strategy configurations</h3>
              </div>
              <div>
                <CustomTable
                  data={configurationData}
                  header={configurationTableColumns(!readOnly)}
                  hideFooter={true}
                  action={readOnly ? ['none'] : ['edit']}
                  actionPosition={'latest'}
                  updateRowData={(data) => {
                    updateStateConfigurations(data, data.value);
                  }}
                />
              </div>
              <div>
                <CustomTable
                  data={limitData}
                  hideFooter={true}
                  action={readOnly ? ['none'] : ['edit']}
                  header={limitDataColumns(!readOnly)}
                  actionPosition={'latest'}
                  updateRowData={(data) => {
                    updateStateConfigurations(data, data.approach);
                  }}
                />
              </div>
              <div className="control-limit-container">
                <div className="results-table-class">
                  <input readOnly value="Variable" className="control-limit-entry" />
                  <input readOnly value="Approach" className="control-limit-entry" />
                  <input readOnly value="Value" className="control-limit-entry-value" />
                </div>
                <div className="control-limit-entry-container">
                  <input readOnly value="Lower Control Limit" className="control-limit-entry" />
                  <select
                    disabled={readOnly}
                    value={currentControlStrategyConfigurations.lowerControlLimitApproach}
                    onChange={handleLowerControlLimitSelect}
                    className="control-limit-entry-select"
                  >
                    <option value="Default">Default</option>
                    <option value="Use Repeatability-based limit">
                      Use Repeatability-based limit
                    </option>
                    <option value="Custom">Custom</option>
                  </select>
                  <input
                    readOnly={readOnly}
                    value={currentControlStrategyConfigurations.lowerControlLimit}
                    onChange={handleLowerControlLimitChange}
                    className="control-limit-entry-value"
                  />
                </div>
                <div className="control-limit-entry-container">
                  <input readOnly value="Upper Control Limit" className="control-limit-entry" />
                  <select
                    disabled={readOnly}
                    value={currentControlStrategyConfigurations.upperControlLimitApproach}
                    onChange={handleUpperControlLimitSelect}
                    className="control-limit-entry-select"
                  >
                    <option value="Default">Default</option>
                    <option value="Use Repeatability-based limit">
                      Use Repeatability-based limit
                    </option>
                    <option value="Custom">Custom</option>
                  </select>
                  <input
                    readOnly={readOnly}
                    value={currentControlStrategyConfigurations.upperControlLimit}
                    onChange={handleUpperControlLimitChange}
                    className="control-limit-entry-value"
                  />
                </div>
              </div>
              <div>
                <CustomTable
                  data={parameterSecondaryData}
                  header={parameterSecondaryDataColumns(!readOnly)}
                  hideFooter={true}
                  action={readOnly ? ['none'] : ['edit']}
                  numberOfRows={10}
                  actionPosition={'latest'}
                  updateRowData={(data) => {
                    updateStateConfigurations(data, data.value);
                  }}
                />
              </div>
              <div>
                <CustomTable
                  data={controlStrategyData}
                  header={controlStrategyConfigurationColumns(!readOnly)}
                  hideFooter={true}
                  action={readOnly ? ['none'] : ['edit']}
                  numberOfRows={20}
                  actionPosition={'latest'}
                  updateRowData={(data) => {
                    updateStateConfigurations(data, data.value);
                  }}
                />
              </div>
              <div>
                <CustomTable
                  data={bottomTPIData}
                  header={bottomTPIColumns(!readOnly)}
                  hideFooter={true}
                  action={readOnly ? ['none'] : ['edit']}
                  numberOfRows={20}
                  actionPosition={'latest'}
                  updateRowData={(data) => {
                    updateStateConfigurations(data, data.value);
                  }}
                />
              </div>
            </div>
            <div className="right-configuration-tables">
              <div>
                <h3>Chart Group configurations</h3>
              </div>
              <div>
                <CustomTable
                  data={methodSpecificData}
                  header={methodSpecificColumns(!readOnly)}
                  hideFooter={true}
                  action={readOnly ? ['none'] : ['edit']}
                  numberOfRows={20}
                  actionPosition={'latest'}
                  updateRowData={(data) => {
                    updateStateConfigurations(data, data.value);
                  }}
                />
              </div>
              <div>
                <CustomTable
                  data={methodSecondaryData}
                  header={methodSecondaryDataColumns(!readOnly)}
                  hideHeader={true}
                  hideFooter={true}
                  action={readOnly ? ['none'] : ['edit']}
                  numberOfRows={20}
                  actionPosition={'latest'}
                  updateRowData={(data) => {
                    updateStateConfigurations(data, data.value);
                  }}
                />
              </div>
              <div style={{ marginTop: '-16px', marginBottom: '16px' }}>
                <div className="ten-ninety-date-container">
                  <input readOnly value="1090 Start Date" className="control-limit-entry" />
                  <Form.Control
                    disabled={readOnly}
                    style={{ borderRadius: '0px' }}
                    value={tenNinetyStartDate}
                    onChange={(e) => handleTenNinetyDateChange('start', e.target.value)}
                    type="date"
                  />
                  <input readOnly value="1090 End Date" className="control-limit-entry" />
                  <Form.Control
                    disabled={readOnly}
                    style={{ borderRadius: '0px' }}
                    value={tenNinetyEndDate}
                    onChange={(e) => handleTenNinetyDateChange('end', e.target.value)}
                    type="date"
                  />
                </div>
              </div>
              <div>
                <h3 className="control-strategy">Sample Schedule</h3>
              </div>
              <div>
                <CustomTable
                  data={currentSampleSchedules}
                  header={sampleScheduleColumnsNew(readOnly)}
                  hideFooter={true}
                  numberOfRows={20}
                />
              </div>
              {!readOnly ? (
                <div className="add-schedule-container">
                  <Button onClick={handleAddSchedule}>Add Schedule</Button>
                </div>
              ) : (
                <></>
              )}

              <div className="modal-90w">
                <Modal
                  dialogClassName="modal-90w"
                  show={showScheduleModal}
                  onHide={() => setShowScheduleModal(false)}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>{scheduleModalType} Schedule</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="manage-data-modal">
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                          onChange={handleDescriptionChange}
                          value={currentModalSchedule.description}
                        />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Frequency</Form.Label>
                        <Form.Select
                          onChange={handleFrequencyChange}
                          value={currentModalSchedule.frequency}
                        >
                          <option value="None">None</option>
                          <option value="Daily">Daily</option>
                          <option value="Weekly">Weekly</option>
                          <option value="Monthly">Monthly</option>
                        </Form.Select>
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Time</Form.Label>
                        <Form.Select onChange={handleTimeChange} value={currentModalSchedule.time}>
                          {renderTimesOptions()}
                        </Form.Select>
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Starting Date</Form.Label>
                        <DatePicker
                          selected={currentModalSchedule.startingDate}
                          onChange={(date) => handleStartDateChange(date)}
                        />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Timezone</Form.Label>
                        <Form.Select
                          onChange={handleTimezoneChange}
                          value={currentModalSchedule.timezone}
                        >
                          <option value={moment.tz.guess()}>{moment.tz.guess()}</option>
                          {renderTimezoneOptions()}
                        </Form.Select>
                      </Form.Group>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button onClick={() => setShowScheduleModal(false)}>Cancel</Button>
                    <Button onClick={handleSaveSchedule}>Save</Button>
                  </Modal.Footer>
                </Modal>
              </div>
              <div>
                <h3 className="control-strategy">Assigned Samples</h3>
              </div>
              <div>
                <CustomTable
                  data={assignedSamplesData}
                  header={assignedSamplesTableColumns(!readOnly)}
                  hideFooter={true}
                  action={readOnly ? ['none'] : ['edit']}
                  numberOfRows={20}
                  actionPosition={'latest'}
                  updateRowData={(data) => {
                    updateStateConfigurations(data, data.sample);
                  }}
                />
              </div>
              <div>
                <h3 className="control-strategy">Archive</h3>
              </div>
              <div>
                <CustomTable
                  data={archiveData}
                  header={archiveColumns(!readOnly)}
                  hideFooter={true}
                  hideHeader={true}
                  action={readOnly ? ['none'] : ['edit']}
                  numberOfRows={20}
                  actionPosition={'latest'}
                  updateRowData={(data) => {
                    updateStateConfigurations(data, data.value);
                  }}
                />
                <div>
                  {currentControlStrategyConfigurations?.archive === 'Yes' &&
                    renderButtonWithPermissions(
                      'Restore Archive',
                      async () => {
                        setChartLoading(true);

                        let tmp = currentControlStrategyConfigurations.fullKey.split('|');
                        const request = {
                          location: tmp[0],
                          method: tmp[1],
                          sampleName: tmp[2],
                          instrument: tmp[3],
                          parameter: tmp[4]
                        };
                        const response = await runUnArchiveFunctions(request);
                        const { data } = response || {};
                        if (data.success && response.data.fullKey) {
                          let key = response.data.fullKey.split('|');
                          const runDataObject = {
                            location: key[0],
                            method: key[1],
                            instrument: key[2],
                            sampleName: key[3],
                            parameter: key[4]
                          };
                          await runDataFunction(runDataObject);
                        }
                        setChartLoading(false);
                        window.location.reload();
                      },
                      SCREEN?.CONFIG,
                      RULES.SAVE,
                      permissionData,
                      true
                    )}
                </div>
              </div>
              <div>
                <h3 className="control-strategy">Control Status</h3>
              </div>
              <div>
                <CustomTable
                  data={controlStatusData}
                  header={controlStatusColumns(!readOnly)}
                  hideFooter={true}
                  hideHeader={true}
                  action={readOnly ? ['none'] : ['edit']}
                  numberOfRows={20}
                  actionPosition={'latest'}
                  updateRowData={(data) => {
                    updateStateConfigurations(data, data.value);
                  }}
                />
              </div>
              <div>
                <h3 className="control-strategy">Delete All Data</h3>
              </div>
              {isDeleteAllData ? (
                <div>
                  <CustomTable
                    data={[
                      {
                        id: 'delete',
                        variable: 'Delete All Data',
                        value: openCofirmDeleteAllData ? 'Yes' : 'No'
                      }
                    ]}
                    header={deleteAllDataColumns(!readOnly)}
                    hideFooter={true}
                    hideHeader={true}
                    action={readOnly ? ['none'] : ['edit']}
                    numberOfRows={20}
                    actionPosition={'latest'}
                    updateRowData={(data) => {
                      if (data.value === 'Yes') {
                        setOpenCofirmDeleteAllData(true);
                      } else {
                        setOpenCofirmDeleteAllData(false);
                      }
                      return;
                    }}
                  />
                </div>
              ) : (
                <></>
              )}
              {openCofirmDeleteAllData && isDeleteAllData ? (
                <>
                  <p style={{ color: 'red' }}>
                    Type <strong>'delete'</strong> below to confirm the deletion of all data.
                  </p>
                  <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                    <TextField
                      fullWidth
                      placeholder="Type 'delete' to confirm"
                      value={confirmationInput}
                      onChange={handleConfirmationInputChange}
                      error={!!error}
                      helperText={error}
                      size="small"
                    />
                    <Button
                      onClick={handleConfirmDelete}
                      disabled={isDeleteDisabled || chartLoading}
                    >
                      Confirm
                    </Button>
                  </div>
                  <br />
                </>
              ) : (
                <></>
              )}
              {!readOnly && isDeleteAllData === false ? (
                <>
                  <div className="add-schedule-container">
                    <Button onClick={() => setIsDeleteAllData(true)}>Delete All Data</Button>
                  </div>
                </>
              ) : null}
              {!readOnly && isDeleteAllData === true ? (
                <>
                  <div className="add-schedule-container">
                    <Button onClick={() => setIsDeleteAllData(false)}>Close Delete All Data</Button>
                  </div>
                </>
              ) : null}
            </div>
          </div>
          <div className="button-container">
            {/* <PrimaryButton text="Save" clicked={saveConfigurations} /> */}
            {renderButton('Save', () => saveConfigurations(), SCREEN?.CONFIG, RULES.SAVE)}
          </div>
        </div>
      );
    }
    if (section === 'DataEntry') {
      return (
        <DataEntrySection
          locations={allLocations}
          methods={allMethods}
          samples={allSampleNames}
          instruments={allInstruments}
          data={dataEntrySectionData}
          location={currentLocation}
          method={currentMethod}
          sampleName={currentSampleName}
          instrument={currentInstrument}
          parameter={currentParameter}
          recheckConfig={currentChartGroupConfigurations.autoCreateSampleRecheck}
        />
      );
    }
    if (section === 'Assessments') {
      return (
        <AssessmentsSection
          currentFullKey={currentFullKey}
          summaryStatisticsAssessmentData={summaryStatisticsAssessmentData}
          summaryStatisticsAssessmentColumns={summaryStatisticsAssessmentColumns}
          currentAssessments={currentAssessments}
          filteredChartHits={filteredChartHits}
          chartData={chartData}
          chartScalars={chartScalars}
          currentInstrument={currentInstrument}
          currentMethod={currentMethod}
          currentShortKey={currentShortKey}
          unit={unit}
        />
      );
    }
    if (section === 'Reports') {
      return (
        <ReportsSection
          filteredChartHits={filteredChartHits}
          chartData={chartData}
          chartScalars={chartScalars}
          currentInstrument={currentInstrument}
          currentMethod={currentMethod}
          currentShortKey={currentShortKey}
          permissionData={permissionData}
          radioArchiveValue={radioArchiveValue}
          unit={unit}
        />
      );
    }
    if (section === 'ReportIssue') {
      {
        return <ReportIssue />;
      }
    }
    return <></>;
  }

  const openCurrentAssessment = () => {
    if (currentAssessments.length > 0) {
      const mostRecentAssessment = currentAssessments[currentAssessments.length - 1];
      if (mostRecentAssessment.outcome === 'Pass') {
        setModalAssessment(mostRecentAssessment);
        if (mostRecentAssessment.type === 'Initial') {
          setShowModal(true);
        } else {
          setShowPeriodicModal(true);
        }
      } else {
        const newModalAssessment = newAssessment();
        setModalAssessment(newModalAssessment);
      }
    } else {
      const newModalAssessment = newAssessment();
      setModalAssessment(newModalAssessment);
    }
    if (chartButtonText === 'Perform Periodic Assessment') {
      const newPeriodicAssessment = newAssessmentPeriodic();
      setModalAssessment(newPeriodicAssessment);
      setShowPeriodicModal(true);
      setShowModal(false);
    } else if (chartButtonText === 'Perform Initial Assessment') {
      setShowModal(true);
      setShowPeriodicModal(false);
    }
  };

  function renderChartButton(chartStageSelected) {
    if (chartStageSelected !== 'In Development') {
      return (
        <div>
          {/* <PrimaryButton
            clicked={() => openCurrentAssessment()}
            text={chartButtonText}
          ></PrimaryButton> */}
          {isAdmin(permissionData) &&
            renderButton(chartButtonText, () => openCurrentAssessment(), SCREEN?.DATA, RULES.SAVE)}
        </div>
      );
    }
    return <></>;
  }

  function renderArchiveAlert(archive) {
    const alertToReturn = [];
    if (archive === 'Yes') {
      alertToReturn.push(
        <div className="archive-alert">
          <Alert key="warning" dismissible variant="info">
            This data set has been archived and is read-only.
          </Alert>
        </div>
      );
    } else {
      alertToReturn.push(<></>);
    }
    return alertToReturn;
  }

  function renderChartStatusFinal(activeTabSelected) {
    const fullKeyComplete =
      currentLocation &&
      currentMethod &&
      currentSampleName &&
      currentInstrument &&
      currentParameter;
    if (activeTabSelected === 'Overview' && fullKeyComplete) {
      return (
        <>
          <div className="header">
            <h2>Overview</h2>
          </div>
          <div className="underline-header">
            <hr />
          </div>
          <div key={uuidv4()} className="chart-status-section">
            {currentControlStrategyConfigurations.controlStatus === 'No' ? (
              <>
                <div>
                  <h3>Chart Stage: Active</h3>
                </div>
              </>
            ) : (
              <>
                <div>
                  <h3>Chart Stage: {chartStage}</h3>
                </div>
                {renderChartButton(chartStage)}
              </>
            )}
          </div>
          {renderArchiveAlert(currentControlStrategyConfigurations.archive)}
        </>
      );
    }
    return <></>;
  }

  useEffect(() => {
    if (Array.isArray(methods) && methods.length > 0) {
      const methodsDropdown = [];
      const parametersDropdown = [];
      methods.forEach((method) => {
        const methodToReturn = {
          id: method.id,
          value: `${method.method}`,
          label: `${method.method}`
        };
        const parameterToReturn = {
          value: method.parameter,
          label: method.parameter
        };
        if (!parametersDropdown.find((parameter) => parameter.value === method.parameter)) {
          parametersDropdown.push(parameterToReturn);
        }
        if (!methodsDropdown.find((dropdownItem) => dropdownItem.value === `${method.method}`)) {
          methodsDropdown.push(methodToReturn);
        }
      });
      methodsDropdown.sort(textSort);
      parametersDropdown.sort(textSort);
      setCurrentParametersDropdown(parametersDropdown);
      setCurrentMethodsDropdown(methodsDropdown);
    }
  }, [methods]);

  useEffect(() => {
    if (Array.isArray(chartHits)) {
      for (const chart of chartHits) {
        if (!chart.notes) {
          chart.notes = '';
        }
      }
      setFilteredChartHits(
        chartHits
          .map((cd, index) => {
            const wFlags = wFlagColumns.filter((f) => !!cd[f]).map((f) => cd[f]);
            const oocFlags = oocFlagColumns.filter((f) => !!cd[f]).map((f) => cd[f]);
            const row = {
              index,
              flags: `${wFlags.length > 0 ? `Warnings: ${wFlags.join(', ')}` : ''}${
                wFlags.length > 0 && oocFlags.length > 0 ? '; ' : ''
              }${oocFlags.length > 0 ? `OOC: ${oocFlags.join(', ')}` : ''}`
            };

            for (const elt of Object.keys(cd)) {
              if (['date'].includes(elt)) {
                row[elt] = cd[elt];
              } else if (['objectID'].includes(elt)) {
                row[elt] = cd[elt];
              } else if (
                String(cd[elt]).match(/[A-Z]/i) ||
                [
                  'instrument',
                  'parameter',
                  'location',
                  'sampleName',
                  'method',
                  'sampleId'
                ].includes(elt)
              ) {
                row[elt] = cd[elt];
              } else if (!Number.isNaN(parseFloat(cd[elt]))) {
                row[elt] = Number(cd[elt]); // 3 decimal places max
              } else if (elt !== 'flags') {
                row[elt] = cd[elt];
              }
            }
            if (row.dateTime) {
              const timeZone = tz || 'UTC';
              const theDate = new Date(row.dateTime);
              row.date = formatInTimeZone(theDate, timeZone, 'M/d/yyyy');
              row.time = formatInTimeZone(theDate, timeZone, 'h:mm aaa');
            }
            if (row.result) {
              row.result = renderSigDigits(row.result);
            }
            if (row.resultOmits) {
              row.resultOmits = renderSigDigits(row.resultOmits);
            }
            return row;
          })
          .sort((a, b) => new Date(a.dateTime).getTime() - new Date(b.dateTime).getTime())
      );
    }
  }, [chartHits, currentChartGroupConfigurations, currentControlStrategyConfigurations]);

  useEffect(() => {
    if (
      currentLocation &&
      currentMethod &&
      currentSampleName &&
      currentInstrument &&
      currentParameter &&
      Array.isArray(filteredChartHits)
    ) {
      const completeChartData = filteredChartHits.some((chartHit) =>
        Object.hasOwn(chartHit, 'resultOmits')
      );
      setChartDataComplete(completeChartData);
      if (completeChartData) {
        const newChartData = filteredChartHits.filter(
          (cd) => !!cd.resultOmits || parseInt(cd.resultOmits, 10) === 0
        );

        // for y range on top 2 charts
        const rs = newChartData.map((d) => d.resultOmits);
        const rMin = Math.min(...rs);
        const rMax = Math.max(...rs);
        const topYMin =
          rMin < newChartData[newChartData.length - 1]?.sd3n
            ? rMin
            : newChartData[newChartData.length - 1]?.sd3n;
        const topYMax =
          rMax > newChartData[newChartData.length - 1]?.sd3p
            ? rMax
            : newChartData[newChartData.length - 1]?.sd3p;
        const topYInterval = topYMax - topYMin;

        // for mr chart
        const mrMean = arrayAverage(newChartData.map((d) => d.mrOmits));
        // const mrMean = Number(currentSummary.mravg_sd);

        // cover for case zScoreOmits/resultOmits is a number but store as string
        const dateTrend = newChartData.map((item) => {
          return { zScoreOmits: Number(item.zScoreOmits), resultOmits: Number(item.resultOmits) };
        });
        const trend = createTrend(dateTrend, 'zScoreOmits', 'resultOmits');
        const zscores = newChartData.map((d) => d.zScoreOmits);
        const newResults = newChartData.map((d) => d.resultOmits);
        const mrs = newChartData
          .filter((d) => !Number.isNaN(parseFloat(d.mrOmits)))
          .map((d) => parseFloat(d.mrOmits));
        const mrMaxVal = Math.max(...mrs);
        const mrUCL = mrMean * 3.27;
        const mrMax = mrUCL > mrMaxVal ? mrUCL : mrMaxVal;
        const xMin = Math.min(...zscores);
        const xMax = Math.max(...zscores);
        const yMin = Math.min(...newResults);
        const yMax = Math.max(...newResults);

        setChartScalars({
          mrMean,
          mrUCL,
          mrYMax: mrMax * 1.05,
          trendData: [
            { y: trend.calcY(Math.min(xMin)), x: xMin },
            { y: trend.calcY(xMax), x: xMax }
          ],
          qqXMin: xMin,
          qqXMax: xMax,
          qqYMin: yMin,
          qqYMax: yMax,
          cYMin: topYMin - 0.05 * topYInterval,
          cYMax: topYMax + 0.05 * topYInterval,
          histogramData: calculateHistogram(newChartData, yMin, yMax)
        });
        for (const data of newChartData) {
          data.formattedDate = new Date(data.date);
          if (!data.notes) {
            data.notes = '';
          }
        }
        const sortedAsc = newChartData.sort(
          (objA, objB) => Date.parse(objA.date) - Date.parse(objB.date)
        );
        let personnelNames = [];
        sortedAsc.forEach((resultIn, index) => {
          const result = resultIn;
          result.updatedIndex = index;
          result.userDefinedUTL = currentControlStrategyConfigurations.upperToleranceLimit;
          result.userDefinedLTL = currentControlStrategyConfigurations.lowerToleranceLimit;
          result.userDefinedUCL = currentControlStrategyConfigurations.upperControlLimit;
          result.userDefinedLCL = currentControlStrategyConfigurations.lowerControlLimit;
          personnelNames.push(result.personnel);
        });
        personnelNames = uniq(personnelNames);
        const analystsDots = [];
        const updatedPersonnelNames = [];
        personnelNames.forEach((name) => {
          const randomColor = randomDarkColor();
          const objectToAdd = {
            analyst: name,
            color: randomColor
          };
          analystsDots.push(objectToAdd);
          const objectToReturn = {
            name,
            checked: true,
            color: randomColor
          };
          updatedPersonnelNames.push(objectToReturn);
        });
        setCurrentPersonnelNames(updatedPersonnelNames);
        setCurrentPersonnelDots(analystsDots);
        const setupDataOne = [...sortedAsc].filter((dataPoint) => dataPoint.setupData === 1);
        setCurrentSetupDataOne(setupDataOne.length);
        const setupDataZero = [...sortedAsc].filter((dataPoint) => dataPoint.setupData === 0);
        setCurrentSetupDataZero(setupDataZero.length);
        setChartData(sortedAsc);
      }
    }
  }, [currentLocation, currentMethod, currentSampleName, currentInstrument, filteredChartHits]);

  const handleSelect = (e) => {
    setActiveTab(e);
  };

  const handleArchiveToggleChange = (e, fromUrl) => {
    setRadioArchiveValue(e.currentTarget.value);
    if (e.currentTarget.value === 'Archive') {
      setCurrentIndexes([...currentArchiveIndexes]);
      setCurrentLocation('');
      setCurrentMethod('');
      setCurrentSampleName('');
      setCurrentInstrument('');
      setCurrentParameter('');
      setIsSelectCombinedZScore(false);
      setCombinedZScoreData([]);
    } else {
      setCurrentIndexes([...currentActiveIndexes]);
      setCurrentLocation('');
      setCurrentMethod('');
      setCurrentSampleName('');
      setCurrentInstrument('');
      setCurrentParameter('');
      setIsSelectCombinedZScore(false);
      setCombinedZScoreData([]);
    }
  };

  useEffect(() => {
    if (currentLocation && currentMethod && currentSampleName && currentInstrument) {
      generateValueForParametersDynamic(currentParametersDynamic);
      if (!loading) {
        loadCombineZScoreParameter();
      }
      setIsSelectCombinedZScore(
        (isAdmin(permissionData) || isContributor(permissionData)) &&
          currentParameter === defaultCombinedParameterName
      );
    }
  }, [
    currentLocation,
    currentMethod,
    currentSampleName,
    currentInstrument,
    currentParameter,
    currentParameters
  ]);

  const generateValueForParametersDynamic = (currentParameters) => {
    const existCombineZScore =
      currentParameters.find((item) => item.name === defaultCombinedParameterName) !== undefined;
    if (!existCombineZScore && radioArchiveValue === 'Active') {
      const updatedParameters = currentParameters;
      if (updatedParameters.length > 1) {
        updatedParameters.push({ name: defaultCombinedParameterName });
      }
      setCurrentParametersDynamic(updatedParameters);
    }
  };

  const loadCombineZScoreParameter = async () => {
    const shortKey = `${currentLocation}|${currentMethod}|${currentSampleName}|${currentInstrument}`;
    const configCombine = await readCombineZScoreParameter(shortKey);
    if (configCombine) {
      setConfigCombineZScore(configCombine);
      const parameterData = configCombine.parameters.map((item) => {
        return {
          parameter: item
        };
      });
      setCombinedZScoreData(parameterData);
    } else {
      setConfigCombineZScore(null);
      setCombinedZScoreData([]);
    }
  };

  const addCombineZScoreData = async (rows, isRefresh = false, parameterRequest) => {
    let i = 0;
    const filterBySetupData = rows
      .filter((item) => item.result !== null)
      .map((item) => {
        if (item.setupData === 1) {
          i = i + 1;
        }
        if (i <= 26) {
          return item;
        } else {
          return {
            ...item,
            setupData: 0
          };
        }
      });

    if (isRefresh) {
      // check update
      const updateList = [];
      const addList = [];
      filterBySetupData.forEach((item) => {
        const updateData = filteredChartHits.find((value) => {
          const A = moment(value.dateTime)
            .tz(tz || 'UTC')
            .format(FORMAT_KEY_TIME);
          const B = moment(item.dateTime)
            .tz(tz || 'UTC')
            .format(FORMAT_KEY_TIME);
          return A == B;
        });
        if (updateData) {
          updateList.push({ ...updateData, result: item.result, dateTime: item.dateTime });
        } else {
          addList.push(item);
        }
      });

      const addDataRequest = {
        location: currentLocation,
        method: currentMethod,
        instrument: currentInstrument,
        rows: addList
      };

      if (updateList.length > 0) {
        const updateDataRequest = {
          location: currentLocation,
          method: currentMethod,
          instrument: currentInstrument,
          rows: updateList
        };
        await editDataFunction(updateDataRequest);
      }

      if (addList.length > 0) {
        await addDataFunction(addDataRequest);
        await updateCombineConfig(parameterRequest);
      } else if (updateList.length > 0) {
        runData();
      }
      setLoading(false);
    } else {
      const addDataRequest = {
        location: currentLocation,
        method: currentMethod,
        instrument: currentInstrument,
        rows: filterBySetupData
      };

      addDataFunction(addDataRequest)
        .then(async () => {
          await updateCombineConfig(parameterRequest);
          setLoading(false);
          window.location.reload();
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const startRunCombineZScore = (isRefresh = false) => {
    setLoading(true);
    const parameterRequest = combinedZScoreData.map((item) => item.parameter);
    const request = {
      location: currentLocation,
      method: currentMethod,
      instrument: currentInstrument,
      sampleName: currentSampleName,
      parameter: parameterRequest,
      isRefresh
    };
    runCombineZScore(request)
      .then(async ({ data }) => {
        if (data && data.listMissingParameter.length > 0) {
          setLoading(false);
          let message = '';
          if (data.listMissingParameter && data.listMissingParameter.length > 0) {
            const parameterMessages = data.listMissingParameter.map((item) => (
              <p key={`parameter-${item.sampleId}`}>
                <span
                  style={{
                    fontWeight: 'bold',
                    color: '#007bff'
                  }}
                >
                  [{item.time}]
                </span>{' '}
                <span
                  style={{
                    fontWeight: 'bold',
                    color: '#28a745'
                  }}
                >
                  [{item.sampleId}]
                </span>
                : {item.missingParameter?.join(', ')}
              </p>
            ));
            message = (
              <div>
                <div
                  style={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    backgroundColor: '#f8d7da',
                    color: '#721c24',
                    padding: '10px',
                    borderRadius: '5px'
                  }}
                >
                  ----- Missing parameter ({parameterMessages.length}) -----
                </div>
                <div
                  style={{
                    maxHeight: '500px',
                    overflowY: 'auto',
                    border: '1px solid #ccc',
                    marginTop: '10px',
                    padding: '10px',
                    borderRadius: '5px'
                  }}
                >
                  {parameterMessages}
                </div>
              </div>
            );
          } else {
            message = data.message;
          }

          setErrorData({
            title: 'Missing result value',
            message,
            textButton: 'Cancel',
            rows: data.rows,
            isRefresh: isRefresh,
            parameterRequest,
            skip: 'Skip'
          });
          setShowError(true);
        } else {
          const { rows } = data || [];
          addCombineZScoreData(rows, isRefresh, parameterRequest);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };

  const updateCombineConfig = async (parameterRequest) => {
    if (configCombineZScore) {
      // update current config
      const updateRequest = {
        ...configCombineZScore,
        parameters: parameterRequest
      };
      delete updateRequest.id;
      await updateCombineZScoreParameter(configCombineZScore.id, updateRequest);
    } else {
      // create new config
      const shortKey = `${currentLocation}|${currentMethod}|${currentSampleName}|${currentInstrument}`;
      const combineConfigData = {
        shortKey: shortKey,
        parameters: parameterRequest
      };
      await createCombineZScoreParameter(combineConfigData);
    }
  };

  const renderArchiveToggle = () => {
    const radios = [
      { name: 'Active', value: 'Active' },
      { name: 'Archive', value: 'Archive' }
    ];
    const buttonToReturn = [];
    const matchingUser = currentPersonnel.find((p) => p.email === auth.user.email);
    if (matchingUser) {
      if (matchingUser.permission === 'Administrator') {
        buttonToReturn.push(
          <div className="archive-toggle-container">
            <ButtonGroup variant="outlined">
              {radios.map((radio) => (
                <MuiButton
                  variant={radioArchiveValue === radio.value ? 'contained' : 'outlined'}
                  onClick={handleArchiveToggleChange}
                  value={radio.value}
                  sx={{
                    textTransform: 'none',
                    backgroundColor: radioArchiveValue === radio.value ? 'rgb(0, 0, 128)' : '',
                    color: radioArchiveValue === radio.value ? '' : 'rgb(0, 0, 128)',
                    borderColor: 'rgb(0, 0, 128)'
                  }}
                >
                  {radio.value}
                </MuiButton>
              ))}
            </ButtonGroup>
          </div>
        );
      } else {
        buttonToReturn.push(<></>);
      }
    }
    return buttonToReturn;
  };

  const buildOrderParameters = (currentParametersDynamic) => {
    if (orderOfParameters && orderOfParameters[0] && orderOfParameters[0][currentShortKey]) {
      const paramsOrdered = orderOfParameters[0][currentShortKey];
      return currentParametersDynamic.map((param) => {
        return { ...param, order: paramsOrdered[param.name] || 0 };
      });
    }
    return currentParametersDynamic;
  };

  let dropDownBlock;
  if (instrumentBeforeSampleName) {
    dropDownBlock = (
      <>
        <Form.Group as={Col} controlId="instrument">
          <Form.Label>Instrument</Form.Label>
          <Form.Select
            value={currentInstrument || 'Select instrument'}
            onChange={handleInstrumentChange}
            aria-label="Default select example"
          >
            <option value="">Select instrument</option>
            {renderOptionsDynamic(currentInstruments)}
          </Form.Select>
        </Form.Group>
        <Form.Group as={Col} controlId="sampleName">
          <Form.Label>Sample Name</Form.Label>
          <Form.Select
            value={currentSampleName || 'Select sample name'}
            onChange={handleSampleNameChange}
            aria-label="Default select example"
          >
            <option value="">Select sample name</option>
            {renderOptionsDynamic(currentSampleNames)}
          </Form.Select>
        </Form.Group>
      </>
    );
  } else {
    dropDownBlock = (
      <>
        <Form.Group as={Col} controlId="sampleName">
          <Form.Label>Sample Name</Form.Label>
          <Form.Select
            value={currentSampleName || 'Select sample name'}
            onChange={handleSampleNameChange}
            aria-label="Default select example"
          >
            <option value="">Select sample name</option>
            {renderOptionsDynamic(currentSampleNames)}
          </Form.Select>
        </Form.Group>
        <Form.Group as={Col} controlId="instrument">
          <Form.Label>Instrument</Form.Label>
          <Form.Select
            value={currentInstrument || 'Select instrument'}
            onChange={handleInstrumentChange}
            aria-label="Default select example"
          >
            <option value="">Select instrument</option>
            {renderOptionsDynamic(currentInstruments)}
          </Form.Select>
        </Form.Group>
      </>
    );
  }

  return (
    <>
      <ChartContext.Provider
        value={{
          currentChartGroupConfigurations,
          currentControlStrategyConfigurations,
          filteredShortKeyData,
          filteredChartHits,
          currentLocation,
          currentMethod,
          currentSampleName,
          currentInstrument,
          currentParameter,
          currentShortKey,
          currentFullKey,
          currentSummary,
          resultsDataTableData,
          chartData,
          permissionData,
          personnel,
          currentPersonnel,
          summaries
        }}
      >
        <Row className="mb-3 global-dropdowns">
          <Form.Group as={Col} controlId="location">
            <Form.Label>Location</Form.Label>
            <Form.Select
              value={currentLocation}
              onChange={handleLocationChange}
              aria-label="Default select example"
            >
              <option value="">Select location</option>
              {renderOptionsDynamic(allLocations)}
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} controlId="method">
            <Form.Label>Method</Form.Label>
            <Form.Select
              value={currentMethod || 'Select method'}
              onChange={handleMethodChange}
              aria-label="Default select example"
            >
              <option value="">Select method</option>
              {renderOptionsDynamic(currentMethods)}
            </Form.Select>
          </Form.Group>
          {dropDownBlock}
          <Form.Group as={Col} controlId="parameter">
            <Form.Label>Parameter</Form.Label>
            <Form.Select
              value={currentParameter || 'Select parameter'}
              onChange={handleParameterChange}
              aria-label="Default select example"
            >
              <option value="">Select parameter</option>
              {renderOptionsDynamicParameter(buildOrderParameters(currentParametersDynamic))}
            </Form.Select>
          </Form.Group>
          {isSelectCombinedZScore && (
            <Form.Group as={Col} controlId="select-combined" md={3} style={{ paddingTop: '8px' }}>
              <Form.Label></Form.Label>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row'
                }}
              >
                {isAdmin(permissionData) && (
                  <div style={{ marginRight: '16px' }}>
                    {renderButtonWithPermissions(
                      'Select Parameter',
                      () => setShowCombinedZScoreModal(true),
                      SCREEN?.DATA,
                      RULES.CREATE,
                      permissionData,
                      true
                    )}
                  </div>
                )}
                {renderLoadingButton({
                  buttonText: 'Refresh Z-Score',
                  hasLoading: true,
                  isLoading: loading,
                  onClick: () => startRunCombineZScore(true)
                })}
              </div>
            </Form.Group>
          )}
        </Row>
        {renderArchiveToggle()}
        <Tabs
          defaultActiveKey="Overview"
          id="uncontrolled-tab-example"
          className="tri-tabs"
          onSelect={handleSelect}
        >
          <Tab eventKey="Overview" title="Overview" />
          {(isAdmin(permissionData) ||
            checkHasPermission(permissionData?.[SCREEN?.COMPARISON], RULES.VIEW)) && (
            <Tab eventKey="Comparison" title="Comparison" />
          )}
          <Tab eventKey="Configuration" title="Configuration" />
          <Tab eventKey="Assessments" title="Assessments" />
          <Tab eventKey="Reports" title="Reports" />
          <Tab eventKey="DataEntry" title="Data Entry" />
          {!activeItems.instrument && <Tab eventKey="ReportIssue" title="Report Issue" />}
        </Tabs>
        <Section bg="white" textColor="dark" size="lg" bgImage="" bgImageOpacity={1}>
          <div className="chart-section-container">
            {router.query.paid && auth.user.planIsActive && (
              <Alert
                variant="success"
                className="text-center mx-auto mb-5"
                style={{ maxWidth: '400px' }}
              >
                You are now subscribed to the {auth.user.planId} plan
                <span className="ml-2" role="img" aria-label="party">
                  🥳
                </span>
              </Alert>
            )}
            {renderChartStatusFinal(activeTab)}
            <ChartAssessment
              showModal={showModal}
              setShowModal={setShowModal}
              currentLogo={currentLogo}
              resultsDataTableData={resultsDataTableData}
              currentParameter={currentParameter}
              unit={unit}
              summaryStatisticsAssessmentData={summaryStatisticsAssessmentData}
              summaryStatisticsAssessmentColumns={summaryStatisticsAssessmentColumns}
              modalAssessment={modalAssessment}
              handleAssessmentChange={handleAssessmentChange}
              saveChartAssessment={saveChartAssessment}
              currentMethod={currentMethod}
              currentInstrument={currentInstrument}
            />
            <Modal size="xl" show={showPeriodicModal} onHide={() => setShowPeriodicModal(false)}>
              <Modal.Header closeButton>
                <Modal.Title>Chart Assessment</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <img alt="logo" src={currentLogo} height="70" />
                  </Col>
                  <Col>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                      <div>Page</div>
                      <Form.Select
                        style={{ width: '70px', marginLeft: '10px' }}
                        value={periodicPage}
                        onChange={selectPeriodicPage}
                        aria-label="Default select example"
                      >
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                      </Form.Select>
                    </div>
                  </Col>
                </Row>
                <Row className="report-header">
                  <h4>Periodic Assessment Report</h4>
                </Row>
                <Row className="ia-table">
                  <Col>Method:</Col>
                  <Col>{currentMethod}</Col>
                  <Col>Date:</Col>
                  <Col> {resultsDataTableData.length > 0 ? resultsDataTableData[0].date : ''}</Col>
                </Row>
                <Row className="ia-table">
                  <Col>Instrument:</Col>
                  <Col>{currentInstrument}</Col>
                  <Col>Assessment by:</Col>
                  <Col> {modalAssessment?.createdBy}</Col>
                </Row>
                <Row className="ia-table">
                  <Col>Sample Name:</Col>
                  <Col>{currentSampleName}</Col>
                  <Col>Pre-treat:</Col>
                  <Col>{currentControlStrategyConfigurations.pretreatData}</Col>
                </Row>
                <Row className="ia-table">
                  <Col>Units:</Col>
                  <Col>{unit}</Col>
                  <Col />
                  <Col />
                </Row>
                <div className="periodic-buttons-container">
                  <ToggleButton
                    key="radioPooling-pass"
                    id="radioPooling-pass"
                    type="radio"
                    variant="outline-primary"
                    name="radio"
                    value
                    style={
                      modalAssessment.pool === 'true'
                        ? { backgroundColor: 'navy', color: 'white' }
                        : {}
                    }
                    checked={modalAssessment.pool === 'true'}
                    onChange={(e) => handleAssessmentChange(e, 'pool')}
                  >
                    Pool
                  </ToggleButton>
                  <ToggleButton
                    key="radioPooling-fail"
                    id="radioPooling-fail"
                    type="radio"
                    variant="outline-primary"
                    name="radio"
                    value={false}
                    style={
                      modalAssessment.pool === 'false'
                        ? { backgroundColor: 'navy', color: 'white' }
                        : {}
                    }
                    checked={modalAssessment.pool === 'false'}
                    onChange={(e) => handleAssessmentChange(e, 'pool')}
                  >
                    Do Not Pool
                  </ToggleButton>
                  <Button
                    onClick={archiveDataPoints}
                    variant={modalAssessment.archive === 'Yes' ? 'primary' : 'outline-primary'}
                  >
                    Archive / Restart
                  </Button>
                  <Button onClick={unArchiveDataPoints}>unArchive</Button>
                </div>
                {renderPeriodicPage(periodicPage)}
              </Modal.Body>
              <Modal.Footer>
                <PrimaryButton text="Cancel" clicked={() => setShowPeriodicModal(false)} />
                <PrimaryButton text="Save" clicked={() => saveChartAssessment()} />
              </Modal.Footer>
            </Modal>

            <Modal
              size="xl"
              show={showCombinedZScoreModal}
              onHide={() => setShowCombinedZScoreModal(false)}
            >
              <Modal.Header closeButton>
                <Modal.Title>Combined Z-score Parameters</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group as={Col}>
                  <Form.Label>Available Parameters {currentParameter}</Form.Label>
                  <Form.Select
                    value={currentParameter}
                    onChange={handleCombinedZScoreParameterChange}
                    aria-label="Default select example"
                  >
                    <option value="">Select parameter</option>
                    {renderOptionsDynamic(
                      currentParametersDynamic.filter((p) => p.name != defaultCombinedParameterName)
                    )}
                  </Form.Select>
                </Form.Group>

                <Form.Group as={Col} className="mt-3">
                  <Form.Label>Combined Parameters</Form.Label>
                  <CustomTable
                    data={combinedZScoreData}
                    header={[
                      {
                        field: 'parameter',
                        headerName: 'Parameter',
                        editable: false,
                        flex: 1,
                        sortable: false
                      }
                    ]}
                    hideFooter={true}
                    action={['delete']}
                    actionPosition={'latest'}
                    deleteRowData={handleDeleteParameter}
                  />
                </Form.Group>
                <ModalConfirm
                  show={show}
                  setShow={setShow}
                  onAccept={startRunCombineZScore}
                  primary={'Yes'}
                />
              </Modal.Body>
              <Modal.Footer>
                <PrimaryButton text="Cancel" clicked={() => setShowCombinedZScoreModal(false)} />
                {renderButtonWithPermissions(
                  'Run',
                  configCombineZScore ? () => setShow(true) : () => startRunCombineZScore(false),
                  SCREEN?.DATA,
                  RULES.CREATE,
                  permissionData,
                  true,
                  loading
                )}
              </Modal.Footer>
            </Modal>
            <ModalError
              show={showError}
              setShow={setShowError}
              title={errorData.title}
              skip={errorData.skip || ''}
              messages={[errorData.message]}
              textButton={errorData.textButton}
              runSkip={() => {
                setLoading(true);
                addCombineZScoreData(
                  errorData.rows,
                  errorData.isRefresh,
                  errorData.parameterRequest
                );
              }}
            />
            <Row>{renderBottomSection(activeTab)}</Row>
          </div>
        </Section>
        {chartLoading && <Loading />}
      </ChartContext.Provider>
    </>
  );
}

export default requireAuth(ChartPage);
