export const getActiveItems = (hostname) => {
  // note: in a totally unintuitive fashion, all of the below are variables passed into a "hide" prop
  // so "false" means that it's turned on, and "true" means it's turned off
  if (hostname.match(/bapco./)) {
    return {
      dashboard: false,
      qCDataCharts: false,
      masterData: false,
      notificationsManager: false,
      spcDataCharts: true,
      spcMasterData: true,
      iLCP: false,
      instrument: false,
      productComp: true,
      settings: false,
      fuelComp: true
    };
  }
  if (hostname.match(/bluetide./)) {
    return {
      dashboard: false,
      qCDataCharts: false,
      masterData: false,
      notificationsManager: false,
      spcDataCharts: false,
      spcMasterData: false,
      iLCP: true,
      instrument: true,
      productComp: true,
      settings: false,
      fuelComp: true
    };
  }
  if (hostname.match(/buckeye./)) {
    return {
      dashboard: false,
      qCDataCharts: false,
      masterData: false,
      notificationsManager: false,
      spcDataCharts: true,
      spcMasterData: true,
      iLCP: true,
      instrument: true,
      productComp: true,
      settings: false,
      fuelComp: false
    };
  }
  if (hostname.match(/ctl./)) {
    return {
      dashboard: false,
      qCDataCharts: false,
      masterData: false,
      notificationsManager: false,
      spcDataCharts: true,
      spcMasterData: true,
      iLCP: true,
      instrument: true,
      productComp: true,
      settings: false,
      fuelComp: false
    };
  }
  if (hostname.match(/cvr./)) {
    return {
      dashboard: false,
      qCDataCharts: false,
      masterData: false,
      notificationsManager: false,
      spcDataCharts: true,
      spcMasterData: true,
      iLCP: false,
      instrument: true,
      productComp: true,
      settings: false,
      fuelComp: false
    };
  }
  if (hostname.match(/demo./)) {
    return {
      dashboard: false,
      qCDataCharts: false,
      masterData: false,
      notificationsManager: false,
      spcDataCharts: true,
      spcMasterData: true,
      iLCP: false,
      instrument: false,
      productComp: true,
      settings: false,
      fuelComp: true
    };
  }
  if (hostname.match(/eurofins./)) {
    return {
      dashboard: false,
      qCDataCharts: false,
      masterData: false,
      notificationsManager: false,
      spcDataCharts: false,
      spcMasterData: false,
      iLCP: true,
      instrument: true,
      productComp: true,
      settings: false,
      fuelComp: true
    };
  }
  if (hostname.match(/ggpetro./)) {
    return {
      dashboard: false,
      qCDataCharts: false,
      masterData: false,
      notificationsManager: false,
      spcDataCharts: true,
      spcMasterData: true,
      iLCP: false,
      instrument: true,
      productComp: false,
      settings: false,
      fuelComp: false
    };
  }
  if (hostname.match(/hep./) || hostname.match(/hfsm./)) {
    return {
      dashboard: false,
      qCDataCharts: false,
      masterData: false,
      notificationsManager: false,
      spcDataCharts: true,
      spcMasterData: true,
      iLCP: true,
      instrument: true,
      productComp: false,
      settings: false,
      fuelComp: false
    };
  }
  if (hostname.match(/mmp./)) {
    return {
      dashboard: false,
      qCDataCharts: false,
      masterData: false,
      notificationsManager: false,
      spcDataCharts: true,
      spcMasterData: true,
      iLCP: false,
      instrument: true,
      productComp: false,
      settings: false,
      fuelComp: false
    };
  }

  if (hostname.match(/arteco./)) {
    return {
      dashboard: false,
      qCDataCharts: false,
      masterData: false,
      notificationsManager: true,
      spcDataCharts: true,
      spcMasterData: true,
      iLCP: true,
      instrument: true,
      productComp: true,
      settings: false,
      fuelComp: true
    };
  }

  if (hostname.match(/uat./)) {
    return {
      dashboard: false,
      qCDataCharts: false,
      masterData: false,
      notificationsManager: false,
      spcDataCharts: false,
      spcMasterData: false,
      iLCP: false,
      instrument: false,
      productComp: false,
      settings: false,
      fuelComp: false
    };
  }

  if (hostname.match(/bplubricants./)) {
    return {
      dashboard: false,
      qCDataCharts: false,
      masterData: false,
      notificationsManager: false,
      spcDataCharts: true,
      spcMasterData: true,
      instrument: false,
      iLCP: true,
      productComp: false,
      settings: false,
      fuelComp: true
    };
  }

  if (hostname.match(/colpipe./)) {
    return {
      dashboard: false,
      qCDataCharts: false,
      masterData: false,
      notificationsManager: false,
      spcDataCharts: true,
      spcMasterData: true,
      instrument: false,
      iLCP: false,
      productComp: false,
      settings: false,
      fuelComp: false
    };
  }

  if (hostname.match(/(ser|ies|propet|lupton)./)) {
    return {
      dashboard: false,
      qCDataCharts: false,
      masterData: false,
      notificationsManager: false,
      spcDataCharts: true,
      spcMasterData: true,
      iLCP: false,
      instrument: true,
      productComp: true,
      settings: false,
      fuelComp: true
    };
  }

  return {
    dashboard: false,
    qCDataCharts: false,
    spcDataCharts: false,
    spcMasterData: false,
    masterData: false,
    notificationsManager: false,
    iLCP: false,
    instrument: false,
    productComp: false,
    settings: false,
    fuelComp: hostname.match(/netlify/)
  };
};

export const getConfiguration = (hostname) => {
  if (hostname.match(/buckeye./)) {
    return {
      apiKey: 'AIzaSyA43dVFfez9ZC9sTFU34B7uJm8lAvPWvaY',
      authDomain: 'fuelsqcv2-buc.firebaseapp.com',
      projectId: 'fuelsqcv2-buc'
    };
  }
  if (hostname.match(/colpipe./)) {
    return {
      apiKey: 'AIzaSyBCUyZhWwk-dt71rUm8Jgclrj3eYN5Bq3w',
      authDomain: 'fuelsqcv2-colonial.firebaseapp.com',
      projectId: 'fuelsqcv2-colonial',
      storageBucket: 'fuelsqcv2-colonial.firebasestorage.app'
    };
  }
  if (hostname.match(/ctl./)) {
    return {
      apiKey: 'AIzaSyA6KMvsgDRoR4pwLL9x4YTbtHcQgkysFHw',
      authDomain: 'fuelsqcv2-ctl.firebaseapp.com',
      projectId: 'fuelsqcv2-ctl',
      storageBucket: 'fuelsqcv2-ctl.appspot.com'
    };
  }
  if (hostname.match(/cvr./)) {
    return {
      apiKey: 'AIzaSyAM71xW-n8blf6kGfsyUT-f29U2iX0huNM',
      authDomain: 'fuelsqcv2-cvr.firebaseapp.com',
      projectId: 'fuelsqcv2-cvr',
      logo: '/logo_cvr.svg'
    };
  }
  if (hostname.match(/demo./)) {
    return {
      apiKey: 'AIzaSyB69UE5Y1L7es8WxgtQfKGVVkrBA9Qiuic',
      authDomain: 'fuelsqcv2-demo.firebaseapp.com',
      projectId: 'fuelsqcv2-demo',
      storageBucket: 'fuelsqcv2-demo.appspot.com'
    };
  }
  if (hostname.match(/ggpetro./)) {
    return {
      apiKey: 'AIzaSyA9ltHOhmofIQm9VxWy67dqWZNeEuLcCY0',
      authDomain: 'fuelsqcv2-ggpetro.firebaseapp.com',
      projectId: 'fuelsqcv2-ggpetro'
    };
  }
  if (hostname.match(/hep./) || hostname.match(/hfsm./)) {
    return {
      apiKey: 'AIzaSyBiPQwBIMwAUbfIXE1fvMy_ppPCEHDDz2A',
      authDomain: 'fuelsqcv2-hep.firebaseapp.com',
      projectId: 'fuelsqcv2-hep'
    };
  }
  if (hostname.match(/hunt./)) {
    return {
      apiKey: 'AIzaSyAZjO4mH4b99J0K1Nn-yTpGMILia83hyW4',
      authDomain: 'fuelsqcv2-hunt.firebaseapp.com',
      projectId: 'fuelsqcv2-hunt',
      storageBucket: 'fuelsqcv2-hunt.appspot.com'
    };
  }
  if (hostname.match(/mmp./)) {
    return {
      apiKey: 'AIzaSyCCfZkKy6ZKKoRrodrKusQk6dCAVCVm6Jc',
      authDomain: 'fuelsqcv2-mmp.firebaseapp.com',
      projectId: 'fuelsqcv2-mmp',
      storageBucket: 'fuelsqcv2-mmp.appspot.com',
      useStorageForIndex: true
    };
  }
  if (hostname.match(/uat./)) {
    return {
      apiKey: 'AIzaSyCdMw_4STiD4G2jOp_4SN35fxo3qXfJmqU',
      authDomain: 'fuelsqcv2-uat.firebaseapp.com',
      projectId: 'fuelsqcv2-uat',
      storageBucket: 'fuelsqcv2-uat.firebasestorage.app'
    };
  }
  if (hostname.match(/bplubricants./)) {
    return {
      apiKey: 'AIzaSyCChLgWO0eMhOuk9w-h4BdDnGZclbPCg8M',
      authDomain: 'fuelsqcv2-bpl.firebaseapp.com',
      projectId: 'fuelsqcv2-bpl',
      storageBucket: 'fuelsqcv2-bpl.firebasestorage.app'
    };
  }
  if (hostname.match(/arteco./)) {
    return {
      apiKey: 'AIzaSyDv_UXQSC1-dggkpUYrk6E5BixVpqiXPak',
      authDomain: 'fuelsqcv2-arteco.firebaseapp.com',
      projectId: 'fuelsqcv2-arteco',
      storageBucket: 'fuelsqcv2-arteco.appspot.com'
    };
  }
  if (hostname.match(/bapco./)) {
    return {
      apiKey: 'AIzaSyDxQ79xU2a5FkykL8oIkPOpeSCK0y7KmhA',
      authDomain: 'fuelsqcv2-bapco.firebaseapp.com',
      projectId: 'fuelsqcv2-bapco',
      storageBucket: 'fuelsqcv2-bapco.firebasestorage.app'
    };
  }
  if (hostname.match(/bluetide./)) {
    return {
      apiKey: 'AIzaSyD9v5x-ckxyTTH08ZWdK670AfhifXtTcN4',
      authDomain: 'fuelsqcv2-bluetide.firebaseapp.com',
      projectId: 'fuelsqcv2-bluetide',
      storageBucket: 'fuelsqcv2-bluetide.firebasestorage.app',
      timeIn24HourFormat: true
    };
  }
  if (hostname.match(/ser./)) {
    return {
      apiKey: 'AIzaSyCZEb9UmobWQtMpFfsbO8VkO5vOUydkvqs',
      authDomain: 'fuelsqcv2-ser.firebaseapp.com',
      projectId: 'fuelsqcv2-ser'
    };
  }
  if (hostname.match(/eurofins./)) {
    return {
      apiKey: 'AIzaSyCGEgJZbM-NoHWepX_jlNpelvHQ3mj7kbE',
      authDomain: 'fuelsqcv2-eurofins.firebaseapp.com',
      projectId: 'fuelsqcv2-eurofins',
      storageBucket: 'fuelsqcv2-eurofins.firebasestorage.app',
      timeIn24HourFormat: true
    };
  }
  if (hostname.match(/ies./)) {
    return {
      apiKey: 'AIzaSyCC__ybYQ_-9HZWLV9I0eMDyBSNJaTHxMg',
      authDomain: 'fuelsqcv2-ies.firebaseapp.com',
      projectId: 'fuelsqcv2-ies'
    };
  }
  if (hostname.match(/lupton./)) {
    return {
      apiKey: 'AIzaSyDLYNiAF2prm6SsWdcDtuw06oVhB4JaIto',
      authDomain: 'fuelsqcv2-lupton.firebaseapp.com',
      projectId: 'fuelsqcv2-lupton',
      storageBucket: 'fuelsqcv2-lupton.appspot.com'
    };
  }
  if (hostname.match(/propet./)) {
    return {
      apiKey: 'AIzaSyB9CbfOwdv-p9BoDchga4cjahl01Mnveyo',
      authDomain: 'fuelsqcv2-propet.firebaseapp.com',
      projectId: 'fuelsqcv2-propet',
      storageBucket: 'fuelsqcv2-propet.appspot.com'
    };
  }

  return {
    apiKey: 'AIzaSyBPLKs0dJ2KVXfPncfxPo1_-xZtxKfsDNM',
    authDomain: 'fuelsqcv2-dev.firebaseapp.com',
    projectId: 'fuelsqcv2-dev',
    logo: '/ASTM-Insight-SQC.png',
    logoAncillary: '/SRP-Insight-SQC.png',
    storageBucket: 'fuelsqcv2-dev.appspot.com',
    useStorageForIndex: false,
    useStorageForSPCIndex: false,
    timeIn24HourFormat: true
  };
};
