import React, { useState } from 'react';
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableSortLabel
} from '@mui/material';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

const alphaNumericSorter = (a, b, field, isAscending) => {
  const valueA = a[field] ?? '';
  const valueB = b[field] ?? '';

  if (field === 'dateTime') {
    const dateA = valueA ? moment(valueA).valueOf() : 0;
    const dateB = valueB ? moment(valueB).valueOf() : 0;
    return isAscending ? dateA - dateB : dateB - dateA;
  }

  return isAscending
    ? valueA.toString().localeCompare(valueB.toString(), undefined, { numeric: true })
    : valueB.toString().localeCompare(valueA.toString(), undefined, { numeric: true });
};

const PrintOverviewDataTable = ({ columns, data, tz }) => {
  const [sortField, setSortField] = useState('dateTime');
  const [sortOrder, setSortOrder] = useState('desc');

  const handleSort = (field) => {
    setSortOrder((prevOrder) => (sortField === field && prevOrder === 'asc' ? 'desc' : 'asc'));
    setSortField(field);
  };

  const sortedData = [...data].sort((a, b) =>
    alphaNumericSorter(a, b, sortField, sortOrder === 'asc')
  );

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((col) => (
                <TableCell key={col.field}>
                  <TableSortLabel
                    active={sortField === col.field}
                    direction={sortField === col.field ? sortOrder : 'asc'}
                    onClick={() => handleSort(col.field)}
                  >
                    {col.headerName}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData.map((row, index) => (
              <TableRow key={row.id || `${row.dateTime}_${index}`}>
                {columns.map((col) => (
                  <TableCell
                    key={`${col.field}_${index}`}
                    sx={col.field === 'dateTime' ? { whiteSpace: 'nowrap' } : {}}
                  >
                    {col.field === 'dateTime' && row[col.field]
                      ? moment.tz(row[col.field], tz || 'UTC').format('MM/DD/YYYY HH:mm')
                      : row[col.field] !== undefined && row[col.field] !== null
                      ? col.field === 'everFlags' && Array.isArray(row[col.field])
                        ? row[col.field].map((v) => (
                            <p style={{ margin: 0 }}>{`V${v.version}: ${v.flags.replace(
                              'W:',
                              'Warnings:'
                            )}`}</p>
                          ))
                        : row[col.field]
                      : '-'}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <br />
    </>
  );
};

PrintOverviewDataTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      headerName: PropTypes.string.isRequired
    })
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  tz: PropTypes.string
};

export default PrintOverviewDataTable;
